import React, { useCallback, useEffect, useState } from 'react'
import { Card, Table, Input, Button, Modal, Form, Row, Col, message } from 'antd';
import { PlusCircleOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import { Link } from "react-router-dom";
import utils from 'utils'
import axios from 'axios';

import { useMyContext } from 'Context/MyContextProvider';
import TextArea from 'antd/es/input/TextArea';
import debounce from 'lodash/debounce';
const ErrorCode = () => {
    const { api, authToken } = useMyContext();

    const [groupListData, setGroupData] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])

    const [code, setCode] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)

    const ErrorData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}error-codes`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const data = (response.data.codes).reverse();
            setGroupData(data);
            setFilteredUsers(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        ErrorData()
    }, [])

    const resetCodes = () => setGroupData(filteredUsers)

    const tableColumns = [
        {
            title: 'Error Code',
            dataIndex: 'code',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'code')
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'description')
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '1rem' }}>
                    <Link to={'#'} className='d-flex h4' onClick={() => HandleEdit(item)}>
                        <EditOutlined />
                    </Link>
                </Flex>
            )
        }
    ];
    const [editState, setEditState] = useState();
    const [editData, setEditData] = useState();
    const HandleEdit = (data) => {
        setEditData(data.id)
        setCode(data.code)
        setDescription(data.description)
        setEditState(true)
        setOpen(true)
    }

    //model
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const addErrorCode = async () => {
        try {
            const response = await axios.post(`${api}error-codes`, {
                code: code,
                description: description
            }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                message.success(response.data.message)
                ErrorData();  // Refresh the error code data
                handleCancel();  // Close the modal
            }
        } catch (error) {
            console.log(error);
        } finally {
            setConfirmLoading(false);  // Reset the loading state
        }
    };

    const updateErrorCode = async () => {
        try {
            const response = await axios.put(`${api}error-codes/${editData}`, {
                code: code,
                description: description
            }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                message.success('Error Code Updated Successfully')
                ErrorData();
                handleCancel();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setConfirmLoading(false);  // Reset the loading state
        }
    };
    const handleOk = async () => {
        const data = { code, description };

        try {
            if (editState) {
                // Call the update function if in edit mode
                await updateErrorCode(data);
            } else {
                // Call the add function if in add mode
                await addErrorCode(data);
            }
            // Close modal after successful operation
            setOpen(false);
        } catch (error) {
            console.error('Operation failed:', error);
        }
    };

    const handleCancel = () => {
        setCode("");
        setDescription("");
        setEditData("");
        setEditState(false);
        setOpen(false);
    };
    const [searchText, setSearchText] = useState('');

    const handleSearch = (value) => {
        setSearchText(value);
        if (value) {
            const searchLower = value.toLowerCase();
            const filtered = groupListData.filter((item) =>
                tableColumns.some((column) =>
                    item[column.dataIndex]?.toString().toLowerCase().includes(searchLower)
                )
            );
            setGroupData(filtered);
        } else {
            resetCodes()
        }
    };

    useEffect(() => {
        handleSearch(searchText)
    }, [searchText]);
    return (
        <Card>
            <Modal
                width={800}
                title={editState ? "Edit Error Code" : "Create New Error Code"}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Row>
                    <Col span={24}>
                        <Form layout="vertical">
                            <Form.Item label="Error Code" className=" my-3">
                                <Input
                                    type="text"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item label="Error Description" className=" my-3">
                                <TextArea
                                    rows={4}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>

            <div className="container-fluid mb-2">
                <Flex justifyContent="space-between" alignItems="center">
                    <h2>Meta Error Codes</h2>
                </Flex>
                <div className="container-fluid">
                    <Row justify="end" align="middle">
                        <Col xs={24} sm={24} md={12} lg={16}>
                            <Row gutter={[16, 16]} justify="end" align="middle">
                                <Col xs={24} sm={12} md={10} lg={6}>
                                    <Input
                                        suffix={<SearchOutlined />}
                                        allowClear
                                        className="p-2 rounded"
                                        size="large"
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4}>
                                    <Link>
                                        <Button onClick={() => setOpen(true)} type="primary" icon={<PlusCircleOutlined />} block>New Error</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={groupListData}
                    rowKey='id'
                />
            </div>
        </Card>
    )
}

export default ErrorCode
