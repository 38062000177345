import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useMyContext } from 'Context/MyContextProvider';
import Maintenance from 'views/app-views/whatsapp/Maintenance/Maintenance';


const ProtectedRoute = () => {
	const { maintenance , userRole} = useMyContext();
	const { token } = useSelector(state => state.auth)
	const location = useLocation();
	const allowedRoutes = ['/chatbot-map', '/manage-request','/default-response'];
	const isAllowedRoute = (path) => {
        const dynamicRoutePattern = /^\/manage-request\/(add|edit)\/\d+$|^\/users\/manage\/\d+\/setting$/;
        return allowedRoutes.includes(path) || dynamicRoutePattern.test(path);
    };
	if (!token) {
		return <Navigate to={`login`} replace />;
	}

	if (maintenance && !isAllowedRoute(location.pathname) && userRole !== 'Admin') {
		return <Maintenance />;
	}
	return <Outlet />
}

export default ProtectedRoute