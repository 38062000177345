import React, { useRef, useState, useEffect } from 'react';
import { Avatar, Divider, Input, Form, Button, Tooltip, Spin, Image } from 'antd';
import { SendOutlined, ReloadOutlined, FilePdfOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Flex from 'components/shared-components/Flex';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import bg from '../../../../assets/image/web-chat.png';
import moment from 'moment';
import UploadDrawer from './UploadDrawer';
import { toLower, transform } from 'lodash';
import { Text } from 'react-konva';


const ChatContentHeader = ({ name, number, onRefresh }) => (
    <div className="chat-content-header">
        <span className="d-flex flex-column">
            <h4 className="mb-0">{name}</h4>
            <p style={{ fontSize: '0.8rem' }} className="mb-0">{number}</p>
        </span>
        <Flex>
            {/* <EllipsisDropdown menu={menu} /> */}
            <Tooltip title="Refresh Chat">
                <ReloadOutlined onClick={onRefresh} />
            </Tooltip>
        </Flex>
    </div>
);

const ChatContentBody = ({ messages, loading }) => {
    const chatBodyRef = useRef();
    const styles = {
        chatBackground: {
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        chatBubble: {
            borderRadius: '8px',
            padding: '10px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            position: 'relative',
        },
        chatBubbleBefore: {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '-5px',
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '10px 10px 10px 0',
            borderColor: 'transparent white transparent transparent',
            background: '#ffff',
            backgroundColor: '#ffff',
            clipPath: 'polygon(0 0, 0 100%, 100% 100%)',
            transform: 'rotate(180deg)',
        },
        myChatBubbleAfter: {
            content: '""',
            position: 'absolute',
            top: '0',
            right: '-8px',
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '0 0 15px 15px',
            borderColor: 'transparent transparent #d9fdd3 transparent',
            transform: 'rotate(180deg)'
        },
        oppositeBubble: {
            backgroundColor: '#ffff',
            color: '#000',
            marginLeft: '20px',
        },
        ownBubble: {
            // backgroundColor: '#22af4b',
            backgroundColor: '#D9FDD3',
            // color: '#fff',
            marginRight: '20px',
            alignSelf: 'flex-end',
        },
        chatTime: {
            textAlign: 'right',
            fontSize: '11px',
            color: 'gray',
        },
        dateHeader: {
            textAlign: 'center',
            margin: '10px 0',
            fontSize: '12px',
            color: '#888',
            fontWeight: 'bold',
        },
    };

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollToBottom();
        }
    }, [messages]);

    const formatDate = (dateString) => {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const messageDate = moment(dateString).startOf('day');

        if (messageDate.isSame(today, 'day')) {
            return 'Today';
        } else if (messageDate.isSame(yesterday, 'day')) {
            return 'Yesterday';
        } else {
            return messageDate.format('MMMM D, YYYY');
        }
    };

    const groupMessagesByDate = (messages) => {
        const groupedMessages = {};
        messages.forEach((message) => {
            const date = formatDate(message.time || message.created_at);
            if (!groupedMessages[date]) {
                groupedMessages[date] = [];
            }
            groupedMessages[date].push(message);
        });
        return groupedMessages;
    };

    const groupedMessages = groupMessagesByDate(messages);

    return (
        <div className="chat-content-body" style={styles.chatBackground}>
            {loading ? (
                <div className="text-center">
                    <Spin size='medium' />
                </div>
            ) : (
                <Scrollbars ref={chatBodyRef} autoHide>
                    {Object.keys(groupedMessages).map((date, index) => (
                        <React.Fragment key={index}>
                            {date !== 'Invalid date' && <Divider style={styles.dateHeader}>{date}</Divider>}
                            {groupedMessages[date].map((elm, i) => {

                                const isLastLoopSameFrom = index > 0 && messages[index - 1].from === elm.from;
                                return (
                                    <div
                                        key={`msg-${elm.id}-${i}`}
                                        className={`msg ${elm.from === 'opposite' ? 'msg-recipient' : elm.from === 'me' ? 'msg-sent' : ''}`}
                                    >
                                        {elm.avatar && (
                                            <div className="mr-2">
                                                <Avatar src={elm.avatar} />
                                            </div>
                                        )}
                                        {elm.msgType === 'text' && (
                                            <div style={{ ...styles.chatBubble, ...(elm.from === 'opposite' ? styles.oppositeBubble : styles.ownBubble) }}>
                                                <div
                                                    style={{
                                                        ...(elm.from === 'opposite' ? styles.chatBubbleBefore : styles.myChatBubbleAfter)
                                                    }}
                                                />
                                                {/* {!isLastLoopSameFrom && (
                                                )} */}
                                                {elm.type === 'image' ? (
                                                    <Image
                                                        src={elm.text || '/img/others/dummy-header.jpg'}
                                                        alt="message"
                                                        style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '8px' }} // Fixed size and cover
                                                    />
                                                ) :
                                                    elm.type === 'video' ? (
                                                        <div className='text-center'>
                                                            <PlayCircleOutlined style={{ fontSize: '5rem' }} /> <br />
                                                            <strong>Video</strong> <br />
                                                            <Text code className="h6">Preview Unavailable</Text>
                                                        </div>
                                                    ) :
                                                        elm.type === 'document' ? (
                                                            <div className='text-center'>
                                                                <FilePdfOutlined style={{ fontSize: '5rem' }} /> <br />
                                                                <strong>Document</strong> <br />
                                                                <Text code className="h6">Preview Unavailable</Text>
                                                            </div>
                                                            // <Image
                                                            //     src={'https://smsforyou.biz/sample_assets/pdfBackground.png'}
                                                            //     alt="message"
                                                            // />
                                                        ) : elm.type === 'text' ? (
                                                            <span>{elm.text}</span>
                                                        ) : null} {/* Render nothing if it's neither text nor image */}

                                                {/* className={`${elm.from === 'me' && 'text-white'}`} */}
                                                <div style={styles.chatTime} className={`${elm.type !== 'text' && 'mt-2'}`}>
                                                    {moment(elm.time || elm.created_at).format('HH:mm')}
                                                </div>
                                            </div>
                                        )}

                                        {/* {elm.text && (
                                        <div style={{ ...styles.chatBubble, ...(elm.from === 'opposite' ? styles.oppositeBubble : styles.ownBubble) }}>
                                            <span>{elm.text}</span>
                                            <div style={styles.chatTime} className={`${elm.from === 'me' && 'text-white'}`}>{moment(elm.time || elm.created_at).format('HH:mm')}</div>
                                        </div>
                                    )} */}
                                    </div>
                                )
                            })}
                        </React.Fragment>
                    ))}
                </Scrollbars>
            )}
        </div>
    );
};

const ChatContentFooter = ({ onSend, disable, form, setDisable, setMedia, handleValuesChange }) => {
    const GetMedia = (id, name, link) => {
        setMedia({ id, name, link })
    }

    return (
        <div className="chat-content-footer">
            <Form form={form} name="msgInput" onValuesChange={handleValuesChange} onFinish={onSend} className="w-100">
                <Form.Item name="newMsg" className="mb-0">
                    <Input
                        autoComplete="off"
                        placeholder="Type a message..."
                        suffix={
                            <div className="d-flex align-items-center">
                                <Link href="#" className="text-dark font-size-lg mr-3" disabled={!disable}>
                                    <UploadDrawer GetMedia={GetMedia} setDisable={setDisable} disabled={!disable} />
                                </Link>
                                <Button shape="circle" type="primary" size="small" htmlType="submit" disabled={disable}>
                                    <SendOutlined />
                                </Button>
                            </div>
                        }
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

const Conversation = () => {
    const { api, UserData, authToken, messagesApi, waToken } = useMyContext();
    const params = useParams();
    const [info, setInfo] = useState({});
    const [msgList, setMsgList] = useState([]);
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [lastReportID, setLastReportID] = useState();
    const [media, setMedia] = useState();

    const { id } = params;

    const [form] = Form.useForm();

    const getChats = async (number) => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}message/${number}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const chats = response.data;
            const lastItem = chats?.msg?.filter(item => item.from === 'opposite').pop();
            setLastReportID(lastItem && lastItem.id);
            setInfo(chats);
            setMsgList(chats?.msg);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        getChats(getUserId());
    }, [id]);

    const getUserId = () => {
        return parseInt(id);
    };
    const getMediaType = (fileName) => {
        const mediaTypes = {
            image: ['jpg', 'jpeg', 'png'],
            video: ['mp4', '3gp'],
            document: ['pdf'],
        };

        for (const [type, extensions] of Object.entries(mediaTypes)) {
            if (extensions?.some(ext => fileName?.endsWith(ext))) {
                return toLower(type); // Return the media type
            }
        }
        return null; // Return null if not a media file
    };
    const onSend = async (values) => {
        try {
            setDisable(true);
            const payload = {
                messaging_product: "whatsapp",
                to: info?.number,
            };
            const mediaType = getMediaType(media?.name);
            if (mediaType) {
                payload.type = mediaType;
                payload[mediaType] = { id: media.id };
            } else {
                payload.type = 'text';
                payload.text = {
                    preview_url: false,
                    body: values.newMsg,
                };
            }
            //console.log(payload); return
            const res = await axios.post(messagesApi, payload, {
                headers: { Authorization: `Bearer ${waToken}` },
            });
            form.resetFields();
            if (res.data.messages[0].message_status === 'accepted' || res.data.messages[0]?.id) {
                const response = await axios.post(`${api}new-chat/${UserData.id}`, {
                    type: !disable ? 'text' : getMediaType(media?.name),
                    user_id: UserData?.id,
                    report_id: lastReportID,
                    message: !disable ? values.newMsg : media?.link,
                }, {
                    headers: {
                        Authorization: "Bearer " + authToken,
                    },
                });
                if (response.data.status) {
                    setMedia()
                    getChats(id)
                    // const newMsgData = {
                    //     type : !disable ? 'text' : getMediaType(media?.name),
                    //     avatar: "",
                    //     from: "me",
                    //     msgType: "text",
                    //     text: values.newMsg,
                    //     time: moment().format('YYYY-MM-DD HH:mm:ss'),
                    // };
                    // setMsgList([...msgList, newMsgData]);
                    form.resetFields();
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDisable(true);
        }
    };

    const handleValuesChange = (changedValues) => {
        if (changedValues.newMsg && changedValues.newMsg.trim().length > 0) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    };
    useEffect(() => {
        if (media?.id) {
            onSend()
        }
    }, [media]);
    return (
        <div className="chat-content">
            <ChatContentHeader name={info?.name} number={info.number} onRefresh={() => getChats(id)} />
            <ChatContentBody messages={msgList} loading={loading} />
            <ChatContentFooter
                form={form}
                setDisable={setDisable}
                onSend={onSend}
                disable={disable}
                setMedia={setMedia}
                handleValuesChange={handleValuesChange}
            />
        </div>
    );
};

export default Conversation;
