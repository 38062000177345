import { Alert, Button, Card, Col, DatePicker, Form, Input, message, Modal, Progress, Radio, Result, Row, Select, Switch, Tabs } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useMyContext } from 'Context/MyContextProvider';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import Flex from 'components/shared-components/Flex';
import "react-transliterate/dist/index.css";
import MediModel from '../Campaign/MediModel';
import Groups from '../Campaign/Groups';
import CarouselPreview from './carouselPreview';
import { toLower } from 'lodash';
const CarouselCampaign = () => {

    const { UserData, api, authToken, messagesApi, waToken, templates, Permisson, navigate, userBalance, calculateRequredBalance } = useMyContext()


    useEffect(() => {
        if (!Permisson?.includes('View Campaign')) {
            navigate('404')
        }
    }, [Permisson]);

    const [templateList, setTemplateList] = useState([]);
    const [componentSize, setComponentSize] = useState('small');
    const [number, setNumber] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const [numberError, setNumberError] = useState(false);
    const [requestType, setRequestType] = useState('');
    const [dynamicValues, setDynamicValues] = useState([]);
    const [dynamicButtonUrl, setDynamicUrlButton] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [newMesaage, setNewMessage] = useState('');
    const [name, setName] = useState('');
    const [templatePreview, setTemplatePreview] = useState([])
    const [mediaLink, setMediaLink] = useState('')
    const [mediaName, setMediaName] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const [mediaType, setMediaType] = useState('Media')
    const [template, setTemplate] = useState({ body: null });
    const [templateKey, setTemplateKey] = useState(0);
    const [templateInputs, setTemplateInputs] = useState([]);
    const [templateUrlInputs, setTemplateUrlInputs] = useState([]);
    const [urlButtons, setUrlButtons] = useState([]);
    const [numbers, SetNumbers] = useState([]);

    const [singleLineNumber, setSingleLineNumber] = useState([])
    const [userStatus, setUerStatus] = useState(false)
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [templateCategory, setTemplateCategory] = useState();
    const [blockNumbers, setBlockNumbers] = useState([]);
    const [templateLanguage, setTemplateLanguage] = useState('');
    const [openMediaModel, setOpenMediaModel] = useState(false);
    const [openScheduleModel, setOpenSchedule] = useState(false);
    const [loading, setLoading] = useState(false);

    const [initialTemplates, setInitialTemplates] = useState([]);
    const [mainBody, setMainBody] = useState(null);

    const [cardInputs, setCardInputs] = useState([]);
    const [mainBodyInputs, setMainBodyInputs] = useState([]);

    const [cardData, setCardData] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [cards, setCards] = useState();

    const GetBlockNumber = async () => {
        try {
            const response = await axios.get(`${api}get-block-numbers/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const serverBlocked = response.data.serverBlocked
            const userBlocked = response.data.serverBlocked

            const formatNumber = (number) => {
                const digits = number.toString();
                if (digits.length === 12) {
                    return digits;
                } else if (digits.length === 10) {
                    return '91' + digits;
                } else {
                    // Handle other cases if needed
                    return null; // Or throw an error
                }
            };
            // Extract and format numbers from serverBlocked
            const serverNumbers = serverBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);

            // Extract and format numbers from userBlocked
            const userNumbers = userBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);


            // Combine both arrays into one
            const total = [...serverNumbers, ...userNumbers];
            setBlockNumbers([...new Set(total)]);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        GetBlockNumber()
    }, [])


    useEffect(() => {
        if (templates.length > 0) {
            const carouselTemplates = templates?.filter(template =>
                template.components?.some(comp => comp.type === 'CAROUSEL')
            );
            setInitialTemplates(carouselTemplates)
            setTemplateList(carouselTemplates)
        }
    }, [templates])

    useEffect(() => {
        if (templateCategory) {
            const filteredList = initialTemplates.filter(item => item.category === templateCategory);
            setTemplateList(filteredList);
        } else {
            setTemplateList(initialTemplates);
        }
    }, [templateCategory, initialTemplates]);

    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };




    useEffect(() => {
        // console.log(number)
        if (number) {
            setNumberError(false)
        }
    }, [number])



    const HandleTemplate = (value) => {
        setMediaLink('');
        setTemplateName(value);
        setTemplateKey((prevKey) => prevKey + 1);

        // Find the selected template
        const template = templates?.find((item) => item.name === value);

        // Set template-related state
        setTemplateLanguage(template?.language);
        setTemplatePreview(template);

        // Extract main body and dynamic values
        const { templateCards, mainBodyTemp } = extractTemplateSections(template);
        setMainBody(mainBodyTemp);
        setCards(templateCards)

        const mainBodyDynamicValue = mainBodyTemp?.text?.match(/{{\d+}}/g);
        if (mainBodyDynamicValue) {
            const inputs = generateInputsForDynamicValues(mainBodyDynamicValue, 'mainBody');
            setMainBodyInputs(inputs);
        } else {
            setMainBodyInputs([]);
        }

        // Handle templateCards
        if (templateCards && templateCards.length > 0) {
            const cardData = templateCards.map((card, index) => {
                const cardBodyDynamicValue = card.body?.text?.match(/{{\d+}}/g);
                // Return the card with dynamic inputs and media-related fields
                return {
                    ...card,
                    inputs: cardBodyDynamicValue
                        ? generateInputsForDynamicValues(cardBodyDynamicValue, 'card', index)
                        : [],
                    mediaType: 'Media', // Initialize mediaType
                    mediaLink: '', // Initialize mediaLink
                    mediaName: '', // Initialize mediaName
                    useExternalUrl: false, // Initialize useExternalUrl
                };
            });
            setCardData(cardData); // Set cardData with media-related fields
        } else {
            setCardData([]);
        }
    };




    const extractTemplateSections = (template) => {
        if (template) {
            let mainBodyTemp = null;
            let templateCards = [];

            // Process components to extract main body and carousel cards
            template.components.forEach(section => {
                if (section.type === "BODY") {
                    mainBodyTemp = section;
                } else if (section.type === "CAROUSEL") {
                    templateCards = section.cards.map(card => {
                        let cardHeader = null;
                        let cardBody = null;
                        let cardButtons = null;

                        // Extract header, body, and buttons for each card
                        card.components.forEach(cardSection => {
                            switch (cardSection.type) {
                                case "HEADER":
                                    cardHeader = cardSection;
                                    break;
                                case "BODY":
                                    cardBody = cardSection;
                                    break;
                                case "BUTTONS":
                                    cardButtons = cardSection;
                                    break;
                                default:
                                    break;
                            }
                        });

                        return { header: cardHeader, body: cardBody, buttons: cardButtons };
                    });
                }
            });
            return { mainBodyTemp, templateCards }
            // setMainBody(mainBodyTemp);
            // setCarouselCards(cards);
        }
    }

    const generateInputsForDynamicValues = (dynamicValues, type, cardIndex) => {
        return dynamicValues.map((placeholder, index) => (
            <Input
                key={index}
                type="text"
                placeholder={`Enter value for ${placeholder}`}
                onChange={(e) => handleInputChange(index, e.target.value, type, cardIndex)}
            />
        ));
    }

    const generateUrlInputs = (urlButtons) => {
        return urlButtons.map((item, index) => {
            // console.log(item)
            const isDisabled = !item.hasOwnProperty('example');
            return (
                <div key={index}>
                    {!isDisabled ?
                        <>
                            <Link>{item.url}</Link>
                            <Input
                                type="text"
                                // value={isDisabled ? item.url : ''}
                                placeholder={`Your "${item.text}" Button Value`}
                                onChange={(e) => handleUrlButton(index, e.target.value)}
                                disabled={isDisabled}
                            />
                        </>
                        :
                        <Input
                            type="text"
                            value={item.url}
                            onChange={(e) => handleUrlButton(index, e.target.value)}
                            disabled={isDisabled}
                        />
                    }
                </div>
            );
        });
    }

    const handleInputChange = (index, value, type, cardIndex) => {
        if (type === 'mainBody') {
            setDynamicValues(prevValues => {
                const updatedValues = [...prevValues];
                updatedValues[index] = value;
                return updatedValues;
            });
        } else if (type === 'card') {
            setCardData(prevCards => {
                const updatedCards = [...prevCards];
                updatedCards[cardIndex].inputs[index] = value;
                return updatedCards;
            });
        }
    };


    const handleUrlButton = (index, value) => {
        setDynamicUrlButton(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index] = value;
            return updatedValues;
        });
    };

    const HandleDynamicValues = (templatePreview) => {
        if (dynamicValues.length > 0) {
            const text = templatePreview?.components[1]?.text;
            let updatedText = text;
            dynamicValues.forEach((item, index) => {
                updatedText = updatedText?.replace(`{{${index + 1}}}`, item);
            });
            setNewMessage(updatedText); // Log the updated text array
        }
    }

    useEffect(() => {
        HandleDynamicValues(templatePreview)
    }, [templatePreview, dynamicValues])





    useEffect(() => {
        if (requestType === 'template') {
            setCustomMessage('')
        } else {
            setTemplatePreview([])
            setTemplateInputs([]);
            setDynamicValues([]);
            setTemplateUrlInputs([]);
            setDynamicUrlButton([]);
            setTemplate({
                body: null,
            });

        }
    }, [requestType])



    const GetGroupNumber = (value) => {
        const uniqueNumbersSet = [...new Set(value)];
        SetNumbers(uniqueNumbersSet)
    }


    useEffect(() => {
        if (numbers?.length > 0) {
            const numbersWithNewlines = numbers?.join('\n');
            setSingleLineNumber(numbersWithNewlines)
        }
        // console.log(numbers)
    }, [numbers])





    const showModal = async () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };


    // for media model 

    const showMediaModel = (index) => {
        setCurrentCardIndex(index);  // Set the current card index
        setOpenMediaModel(true);
    };
    const handleCancelMediaModel = () => {
        setOpenMediaModel(false);
    };

    // for media model 
    const CreditCalculation = async (numberCount) => {
        try {
            const MP = UserData?.pricing_model?.marketing_price;
            return numberCount * MP >= userBalance
        } catch (error) {
            console.error(error);
        }
    }

    const showScheduleModel = async () => {
        const filteredNumbers = filterNumbers(numbers, blockNumbers);
        let CreditNotAvailable = await CreditCalculation(filteredNumbers?.length)
        if (CreditNotAvailable) {
            showModal()
        } else {
            setOpenSchedule(true);
        }
    };
    const handleCancelScheduleModel = () => {
        setOpenSchedule(false);
    };






    const successFunction = (apiResult) => {
        if (apiResult) {
            // message.success('Campaign Created Successfully');
            // navigate('/campaign-history');
        }
    };


    const buttonStyle = {
        background: 'linear-gradient(116deg,  #40e495, #30dd8a, #2bb673)',
        color: 'white',
        border: 'none'
    }

    const HandleUploadImage = (path, name) => {
        if (path) {
            // Update the specific card data
            setCardData((prevData) =>
                prevData.map((card, index) =>
                    index === currentCardIndex
                        ? { ...card, mediaLink: path, mediaName: name }
                        : card
                )
            );
            setOpenMediaModel(false);
        }
    };
    const handleMediaType = (e, index) => {
        const mediaType = e.target.checked ? 'Url' : 'Media';

        setCardData((prevData) =>
            prevData.map((card, cardIndex) =>
                cardIndex === index ? { ...card, mediaType } : card
            )
        );
    };
    const HandleScheduleTime = (value) => {
        const { $d } = value;
        const year = $d.getFullYear();
        const month = $d.getMonth() + 1;
        const day = $d.getDate();
        const hours = $d.getHours();
        const minutes = $d.getMinutes();
        // const seconds = $d.getSeconds();
        setScheduleDate(`${year}-${month}-${day}`);
        setScheduleTime(`${hours}:${minutes}:00`);
    }


    const filterNumbers = (numbers, blockNumbers) => {
        const uniqueNumbersSet = [...new Set(numbers)];
        // const numbersArray = number.split(',').map(number => number.trim());
        const numbersArray = number.split(',').map(item => item.trim()).flatMap(item => item.split('\n')).map(number => number.trim());
        // console.log(numbersArray);return
        const combinedNumbers = [...uniqueNumbersSet, ...numbersArray];
        // console.log(combinedNumbers)
        const finalFilteredNumbers = combinedNumbers.filter(number => {
            const num = parseInt(number);
            return !isNaN(num) && (num.toString().length === 10 || num.toString().length === 12);
        }).map(number => {
            const num = parseInt(number);
            return num.toString().length === 10 ? '91' + num : number;
        });
        const filteredNumbers = finalFilteredNumbers.filter(finalNumber => !blockNumbers.includes(finalNumber));
        return filteredNumbers;
    };
    // final submit 

    const HandleSchedule = async () => {
        setLoading(true)
        const filteredNumbers = filterNumbers(numbers, blockNumbers);
        // return
        const campaignResponse = await axios.post(`${api}schedule-campaign/${UserData?.id}`, {
            name,
            user_id: UserData.id,
            numbers: filteredNumbers,
            campaign_type: requestType,
            custom_text: customMessage,
            template_name: templateName,
            header_type: template.header?.format,
            header_media_url: mediaLink,
            body_values: dynamicValues,
            button_type: template?.buttons?.buttons[0].type,
            button_value: dynamicButtonUrl,
            schedule_date: scheduleDate,
            schedule_time: scheduleTime,
        }, {
            headers: {
                Authorization: "Bearer " + authToken,
            }
        });
        // console.log(campaignResponse)
        if (campaignResponse.data.status) {
            navigate('/campaign')
        }
        setLoading(false)
    }





    // send message code start from here 
    const generateContent = (type, id) => {
        switch (type) {
            case 'IMAGE':
                return { image: { id: id } };
            case 'VIDEO':
                return { video: { id: id } };
            case 'DOCUMENT':
                return { document: { id: id } };
            default:
                return {};
        }
    };
    const [isSending, setIsSending] = useState(true);
    const [progress, setProgress] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const [rateLimit, setRateLimit] = useState();
    // Function to generate the payload for each message
    const generatePayload = (item, templateName, templateLanguage, dynamicValues) => {
        //console.log('Generating payload for:', item, templateName, templateLanguage, dynamicValues);
        // const bodySection = dynamicValues.length > 0 ? {
        //     type: "body",
        //     parameters: dynamicValues.map(value => ({
        //         type: "text",
        //         text: value.replace(/{{|}}/g, '')  // Remove {{ and }} from dynamic variables if needed
        //     })),
        // } : undefined;
        // console.log('Generating payload for:', item, templateName, templateLanguage, dynamicValues);
        return {
            messaging_product: "whatsapp",
            to: item,
            type: "template",
            template: {
                name: templateName,
                language: { code: templateLanguage },
                components: [
                    //  ...(bodySection ? [bodySection] : []), // Include body section only if it exists
                    {
                        type: "CAROUSEL",
                        cards: cardData.map((card, cardIndex) => ({
                            card_index: cardIndex,
                            components: [
                                ...(card.header ? [
                                    {
                                        type: "HEADER",
                                        parameters: [
                                            {
                                                type: (() => {
                                                    switch (card.header?.format) {
                                                        case "IMAGE":
                                                            return "IMAGE";
                                                        case "VIDEO":
                                                            return "VIDEO";
                                                        case "DOCUMENT":
                                                            return "DOCUMENT";
                                                        default:
                                                            return "TEXT";
                                                    }
                                                })(),
                                                ...generateContent(card.header?.format, card.mediaLink),
                                            },
                                        ],
                                    },
                                ] : []),
                                ...(card.buttons && card.buttons.length > 0 ? card.buttons.map((button, buttonIndex) => ({
                                    type: "BUTTON",
                                    sub_type: button.type === "QUICK_REPLY" ? "QUICK_REPLY" : "URL",
                                    index: buttonIndex,
                                    ...(button.type === 'URL' && dynamicButtonUrl?.length ? {
                                        parameters: dynamicButtonUrl.map(url => ({ type: "text", text: url }))
                                    } : {}),
                                    ...(button.type === 'QUICK_REPLY' ? {
                                        parameters: [{ type: "payload", payload: "btntwo" }]
                                    } : {}),
                                })) : []),
                            ],
                        })),
                    }
                ]
            },
        }
    };


    // Function to handle the rate-limited message sending
    const sendMessageWithRateLimit = async (messagePayloads, messagesApi, waToken, campaignData, api, authToken) => {
        // const customRateLimit = rateLimit > 0 ? rateLimit : 20; // messages per second
        const customRateLimit = 20; // messages per second
        const interval = 1000 / customRateLimit; // milliseconds
        const reportData = [];
        const totalMessages = messagePayloads.length;
        let sentMessages = 0;
        const sendBatch = async (batch) => {
            await Promise.all(batch.map(async (payload) => {
                const mobileNumber = payload.to; // Store the mobile number for reporting

                try {
                    const res = await axios.post(messagesApi, payload, {
                        headers: { Authorization: `Bearer ${waToken}` },
                    });

                    if (res.data.messages[0].message_status === 'accepted' || res.data.messages[0]?.id) {
                        // Collect the report data
                        reportData.push({
                            campaign_id: campaignData.id,
                            message_id: res.data.messages[0].id,
                            mobile_number: mobileNumber,
                        });
                    }
                } catch (error) {
                    console.error('Error sending message:', error);
                    // Optionally, handle individual error reporting here
                }
                sentMessages++;
                const currentProgress = (sentMessages / totalMessages) * 100;
                setProgress(currentProgress);
            }));
        };

        const processMessages = async () => {
            for (let i = 0; i < messagePayloads.length; i += customRateLimit) {
                const batch = messagePayloads.slice(i, i + customRateLimit);
                await sendBatch(batch);

                // Wait for the next interval before sending the next batch
                await new Promise(resolve => setTimeout(resolve, interval));
            }

            // After all messages are processed, store the campaign reports
            setIsSending(false); // Update sending status
            await storeCampaignReports(reportData, campaignData, api, authToken);
        };

        setIsSending(true); // Start sending process
        processMessages();
    };



    const storeCampaignReports = async (reportData, campaignData, api, authToken) => {
        try {
            const response = await axios.post(`${api}create-campaign-report`, {
                campaign_id: campaignData.id,
                reports: reportData,
            }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });

            if (response.data.status) {
                console.log('Campaign reports stored successfully:', response.data.message);
            } else {
                console.error('Failed to store campaign reports:', response.data.message);
            }
        } catch (error) {
            console.error('Error storing campaign reports:', error);
        }
    };



    const [showProcessingModal, setShowProcessingModal] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isSending) {
                e.preventDefault();
                e.returnValue = ''; // For most browsers
                return ''; // For older browsers
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isSending]);

    useEffect(() => {
        const handlePopstate = (e) => {
            if (isSending) {
                e.preventDefault();
                setShowProcessingModal(true);
            }
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [isSending]);

    const groupsRef = useRef(null);
    const [groupsKey, setGroupsKey] = useState(0);

    const resetCampaign = () => {
        setProgress()
        setIsModalVisible(false)
        setSingleLineNumber([])
        setNumber('')
        setGroupsKey(prevKey => prevKey + 1);
    }


    const conicColors = {
        '0%': '#16702F',
        // '50%': '#ffe58f',
        '100%': '#34B55A',
    };


    // useEffect(() => {
    //     console.log(cardData)
    // }, [cardData]);
    const handleMediaLinkChange = (value, index) => {
        // console.log(value,index)
        const newMediaLink = value;
        setCardData((prevData) =>
            prevData.map((card, i) =>
                i === index ? { ...card, mediaLink: newMediaLink } : card
            )
        );
    };

    const [showAlert, setShowAlert] = useState(false);
    const [isLoop, setIsLoop] = useState(true);

    useEffect(() => {
        if (showAlert) {
            message.error('Please provide the required media link and media type.');
        }
    }, [showAlert]);
    const handleSwitchChange = (checked) => {
        setIsLoop(checked)
    }


    // Main handler function
    // const HandleSubmit = async () => {
    //     const missingCardIndices = cardData
    //         .map((card, index) => card.mediaType === 'Media' && !card.mediaLink ? index + 1 : null)
    //         .filter(index => index !== null);

    //     if (missingCardIndices.length > 0) {
    //         // Create a message listing all problematic card numbers
    //         const cardNumbers = missingCardIndices.join(', ');
    //         message.error(`Cards ${cardNumbers} are missing media files. Please select and upload files for these cards.`);
    //         return; // Prevent form submission if there are errors
    //     }
    //     else if (templateName === '') {
    //         setError('You have to select a template first');
    //         return;
    //     }
    //     const filteredNumbers = filterNumbers(numbers, blockNumbers);
    //     if (name && filteredNumbers.length > 0 && (templateName || customMessage)) {
    //         try {
    //             setLoading(true);
    //             setIsModalVisible(true);
    //             // console.log('Generating payload for:', templateName, templateLanguage, cardData);
    //             const UserResponse = await axios.get(`${api}chek-user/${UserData.id}`, {
    //                 headers: { 'Authorization': `Bearer ${authToken}` },
    //             });
    //             setUerStatus(UserResponse.data.status);

    //             if (UserResponse.data.status === false) {
    //                 showModal();
    //                 return false;
    //             }

    //             // Create campaign
    //             const campaignData = await axios.post(`${api}create-campaign`, {
    //                 name,
    //                 user_id: UserData.id,
    //                 templateName: templateName ? templateName : `Custom Message : ${customMessage}`,
    //             }, {
    //                 headers: { Authorization: `Bearer ${authToken}` },
    //             }).then(response => response.data.campaign);

    //             // Generate message payloads
    //             const messagePayloads = filteredNumbers.map(item => generatePayload(
    //                 item, templateName, templateLanguage, dynamicValues
    //             ));
    //             // console.log(messagePayloads)
    //             // return
    //             // Send messages with rate limiting and reporting
    //             await sendMessageWithRateLimit(messagePayloads, messagesApi, waToken, campaignData, api, authToken);

    //             successFunction(true);
    //         } catch (error) {
    //             setError(error.message);
    //         } finally {
    //             setLoading(false);
    //         }
    //     } else {
    //         setError('Invalid input data');
    //     }
    // };

    const isJSON = (str) => {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      };
      const getErrorMessage = () => {
        if (typeof error === 'string') {
          return error;
        } else if (isJSON(JSON.stringify(error))) {
          return JSON.stringify(error);
        }
        return 'An unexpected error occurred';
      };
    
    // Utility function to check for missing media files
    const getMissingCardIndices = (cardData) => {
        return cardData
            .map((card, index) => card.mediaType === 'Media' && !card.mediaLink ? index + 1 : null)
            .filter(index => index !== null);
    };

    // Utility function to create campaign
    const createCampaign = async (userId, name, templateName, customMessage, authToken) => {
        return await axios.post(`${api}create-campaign`, {
            name,
            user_id: userId,
            templateName: templateName || `Custom Message: ${customMessage}`,
        }, {
            headers: { Authorization: `Bearer ${authToken}` },
        }).then(response => response.data.campaign);
    };

    // Utility function to check user status
    const checkUserStatus = async (userId, authToken) => {
        const response = await axios.get(`${api}chek-user/${userId}`, {
            headers: { 'Authorization': `Bearer ${authToken}` },
        });
        return response.data.status;
    };

      // User status and balance validation function
  const validateUserAndBalance = async (numbersLength) => {
    const isEnoughBalance = calculateRequredBalance(numbersLength, toLower(templateCategory));
    if (isEnoughBalance === false) {
      showModal()
      message.error('Not enough balance in your account');
      // setError('Not enough balance in your account');
      // scrollToTop();
      return false;
    }
    return true;
  };
    // Main handler function
    const HandleSubmit = async () => {
        try {
            // Check for missing media files in cards
            const missingCardIndexes = getMissingCardIndices(cardData);
            if (missingCardIndexes.length > 0) {
                const cardNumbers = missingCardIndexes.join(', ');
                message.error(`Cards ${cardNumbers} are missing media files. Please select and upload files for these cards.`);
                return;
            }

            // Check if template is selected
            if (templateName === '') {
                setError('You have to select a template first');
                return;
            }

            // Filter valid numbers
            const filteredNumbers = filterNumbers(numbers, blockNumbers);
            if (!name || filteredNumbers.length === 0 || (!templateName && !customMessage)) {
                setError('Invalid input data');
                return;
            }

            // Validate user balance
            const isEnoughBalance = validateUserAndBalance(filteredNumbers.length, templateCategory);
            if (!isEnoughBalance) {
                setOpen(true);
                return;
            }
            if (filteredNumbers.length > 5000) {
                setError('You can only process up to 5000 numbers.');
                return;
            }
            
            // Check user status
            const userStatus = await checkUserStatus(UserData.id, authToken);
            setUerStatus(userStatus);
            if (userStatus === false) {
                showModal();
                return;
            }
            
            setLoading(true);
            // Create campaign
            const campaignData = await createCampaign(UserData.id, name, templateName, customMessage, authToken);

            // Generate message payloads
            const messagePayloads = filteredNumbers.map(item =>
                generatePayload(item, templateName, templateLanguage, dynamicValues)
            );

            // Send messages with rate limiting and reporting
            await sendMessageWithRateLimit(messagePayloads, messagesApi, waToken, campaignData, api, authToken);

            // Success handling
            successFunction(true);

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            {/* for belance alert  */}
            <Modal
                title="Insufficient Credits"
                open={open}
                onCancel={handleCancel}
                footer={null}
            >
                <Result
                    title="Insufficient credits to run this campaign"
                    extra={
                        <Link to={`/users/manage/${UserData.id}/billing`}>
                            <Button type="primary" key="console">
                                Add Credits
                            </Button>
                        </Link>
                    }
                />
            </Modal>

            {/* schedule model  */}
            <Modal
                title="Schedule Campaign"
                open={openScheduleModel}
                onCancel={handleCancelScheduleModel}
                footer={null}
            >
                <Result
                    status="success"
                    title="Select date and Time"
                    extra={
                        <>
                            <DatePicker
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                onChange={(value) => HandleScheduleTime(value)} />
                            <Link to={``}>
                                <Button type="primary" key="console" onClick={() => HandleSchedule()}>
                                    Schedule Campaign
                                </Button>
                            </Link>
                        </>
                    }
                />
            </Modal>
            {/* model for media file  */}
            <Modal
                title="Select Media"
                open={openMediaModel}
                onCancel={handleCancelMediaModel}
                footer={null}
                width={1200}
            >
                <MediModel HandleUploadImage={HandleUploadImage} />
            </Modal>

            <Modal
                title={
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        {progress === 100 ? "Campaign Execution Completed" : "Campaign Execution Under Process"}
                    </div>
                }
                open={isModalVisible}
                footer={null}
                closable={false}
            // onCancel={() => setIsModalVisible(false)}
            // closable={progress === 100}
            >
                <div className="d-flex justify-content-center align-items-center flex-column mt-3">
                    <Progress type="circle" percent={Math.round(progress)} strokeColor={conicColors} />
                    {progress === 100 && (
                        <div className="d-flex justify-content-center mt-3">
                            <Button className="mr-2" onClick={() => resetCampaign()}>New Campaign</Button>
                            <Button onClick={() => navigate('/campaign-history')}>View Report</Button>
                        </div>
                    )}
                </div>
            </Modal>

            <Row gutter={12}>
                {Permisson?.includes('View Campaign') &&
                    <>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <Card title="Carousel Campaign">
                                {/* Error Alert */}
                                {error && (
                                    <Col span={24}>
                                        <motion.div
                                            initial={{ opacity: 0, marginBottom: 0 }}
                                            animate={{ opacity: error ? 1 : 0, marginBottom: error ? 20 : 0 }}
                                        >
                                            <Alert type="error" showIcon message={getErrorMessage()} />
                                        </motion.div>
                                    </Col>
                                )}

                                {/* Form */}
                                <Form
                                    name="basicInformation"
                                    layout="vertical"
                                    initialValues={{ size: componentSize }}
                                    onValuesChange={onFormLayoutChange}
                                    size={componentSize}
                                >
                                    <Row gutter={[16, 16]}>
                                        {/* Campaign Name */}
                                        <Col span={24}>
                                            <Form.Item
                                                label="Campaign Name"
                                                name="Campaign-Name"
                                                className='m-0'
                                                rules={[{ required: true, message: 'Please enter campaign name!' }]}
                                            >
                                                <Input type="text" onChange={(e) => setName(e.target.value)} />
                                            </Form.Item>
                                        </Col>

                                        {/* Mobile Numbers */}
                                        <Col span={24}>
                                            {numbers?.length > 0 && (
                                                <Form.Item label="Mobile Numbers from Groups">
                                                    <Input.TextArea
                                                        rows={numbers?.length >= 10 ? 10 : 5}
                                                        value={singleLineNumber}
                                                        className="cursor-not-allowed"
                                                        readOnly
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>

                                        {/* Additional Mobile Numbers */}
                                        <Col span={24}>
                                            <Form.Item
                                                className='m-0'
                                                label={`${numbers?.length > 0 ? 'Additional ' : ''}Mobile Numbers`}
                                            >
                                                <Input.TextArea
                                                    rows={5}
                                                    value={number}
                                                    onChange={(e) => setNumber(e.target.value)}
                                                />
                                                <span className="float-right">
                                                    Max Limit 5000 | Total numbers:{' '}
                                                    {numbers.length +
                                                        number
                                                            .split(',')
                                                            .map((item) => item.trim())
                                                            .flatMap((item) => item.split('\n').map((subItem) => subItem.trim()))
                                                            .filter((item) => item !== '' && (item.length === 10 || item.length === 12)).length}
                                                </span>
                                            </Form.Item>
                                        </Col>

                                        {/* Template Category */}
                                        <Col span={24}>
                                            <Form.Item label="Template Category" name="Template_category">
                                                <Radio.Group
                                                    onChange={(e) => setTemplateCategory(e.target.value)}
                                                    value={templateCategory}
                                                >
                                                    <Radio value="MARKETING">Marketing</Radio>
                                                    <Radio value="UTILITY">Utility</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>

                                        {/* Templates Selection */}
                                        {templateCategory && (
                                            <Col span={24}>
                                                <Form.Item label="Templates" name="Templates">
                                                    <Select
                                                        size="default"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => HandleTemplate(value)}
                                                        showSearch
                                                    >
                                                        <Select.Option value="choose...">Select</Select.Option>
                                                        {templateList?.length > 0 ? (
                                                            templateList.map((item, index) => (
                                                                <Select.Option value={item.name} key={index}>
                                                                    {item.name}
                                                                </Select.Option>
                                                            ))
                                                        ) : (
                                                            <Select.Option value="">
                                                                You Don't Have Any Approved Templates
                                                            </Select.Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        )}

                                        {/* Dynamic Values */}
                                        {mainBodyInputs.length > 0 && (
                                            <Col span={24}>
                                                <Form.Item label={`${mainBodyInputs.length} dynamic values detected`}>
                                                    {mainBodyInputs.map((input, index) => (
                                                        <div key={index} className="mb-2">
                                                            {input}
                                                        </div>
                                                    ))}
                                                </Form.Item>
                                            </Col>
                                        )}

                                        {/* Carousel Cards */}
                                        {cardData.length > 0 && (
                                            <Col span={24}>
                                                <Card title={`Total Cards : ${cardData?.length}`} style={{ width: '100%' }}>
                                                    <Tabs
                                                        defaultActiveKey="0"
                                                        type="card"
                                                        items={
                                                            cardData.map((card, cardIndex) => ({
                                                                key: String(cardIndex),
                                                                label: (
                                                                    `Card ${cardIndex + 1}`
                                                                    // <span style={{ color: tabColors[cardIndex] || 'defaultColor' }}>
                                                                    // </span>
                                                                ),
                                                                children: (
                                                                    <Card bordered={false} style={{ height: '100%' }}>
                                                                        <p>Card {cardIndex + 1} Body  : {card?.body?.text}</p>
                                                                        {card.mediaType === 'Media' ? (
                                                                            <Form.Item
                                                                                label={`Select Media File For Card ${cardIndex + 1}`}
                                                                                name={`media-link-${cardIndex}`}
                                                                            >
                                                                                {!card.mediaLink ? (
                                                                                    <Button
                                                                                        style={buttonStyle}
                                                                                        onClick={() => showMediaModel(cardIndex)}
                                                                                    >
                                                                                        <CloudUploadOutlined /> Choose File
                                                                                    </Button>
                                                                                ) : (
                                                                                    <>
                                                                                        <Input
                                                                                            type="text"
                                                                                            disabled
                                                                                            value={card.mediaLink}
                                                                                            className="mb-3"
                                                                                        />
                                                                                        <Button
                                                                                            style={buttonStyle}
                                                                                            onClick={() => showMediaModel(cardIndex)}
                                                                                        >
                                                                                            <CloudUploadOutlined /> Choose Another File
                                                                                        </Button>
                                                                                    </>
                                                                                )}
                                                                            </Form.Item>
                                                                        ) : (
                                                                            <Form.Item
                                                                                label="Your media file link"
                                                                                name={`media-link-${cardIndex}`}
                                                                                rules={[
                                                                                    { required: true, message: 'Please enter a media link!' }
                                                                                ]}
                                                                            >
                                                                                <Input
                                                                                    type="text"
                                                                                    onChange={(e) =>
                                                                                        handleMediaLinkChange(e.target.value, cardIndex)
                                                                                    }
                                                                                    className="mb-3"
                                                                                />
                                                                            </Form.Item>
                                                                        )}
                                                                    </Card>
                                                                ),
                                                            }))}
                                                    />
                                                </Card>
                                            </Col>
                                        )}


                                        {/* Submit Buttons */}
                                        <Col span={24}>
                                            <Flex alignItems="center" gap={16}>
                                                {Permisson?.includes('Create Campaign') && (
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        onClick={(e) => HandleSubmit(e)}
                                                        loading={loading}
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                                {/* <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={(e) => showScheduleModel(e)}
                                                    loading={loading}
                                                >
                                                    Schedule Campaign
                                                </Button> */}
                                            </Flex>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                        {/* mobile preview  */}
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Card
                                title={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>Campaign Preview</span>
                                        <Flex alignItems='center'>
                                            <span className='h5 m-0 mr-2 p-0'>Auto Scroll</span>
                                            <Switch checked={isLoop} onChange={handleSwitchChange} />
                                        </Flex>
                                    </div>
                                }
                            >
                                <CarouselPreview
                                    mainBody={mainBody}
                                    mediaLink={mediaLink}
                                    requestType={'template'}
                                    cards={cards}
                                    isLoop={isLoop}
                                    thumbnail={thumbnail}
                                    newMesaage={newMesaage}
                                    urlButtons={urlButtons}
                                    customMessage={customMessage}
                                />
                            </Card>
                        </Col>
                    </>
                }
                {
                    Permisson?.includes('View Group') &&
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <Groups key={groupsKey} ref={groupsRef} GetGroupNumber={GetGroupNumber} />
                    </Col>
                }
            </Row >
        </>
    )
}

export default CarouselCampaign
