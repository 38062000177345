import React, { useCallback, useEffect, useState } from 'react'
import { Card, Table, Tag, Tooltip, Button, Row, Select, Form, Col, DatePicker, Input, message } from 'antd';
import { EyeOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pusher from 'pusher-js';
import { useMyContext } from 'Context/MyContextProvider';
import utils from 'utils'
import { debounce } from 'lodash';

const { RangePicker } = DatePicker;
const { Option } = Select
const Reports = () => {
    const { api, authToken, UserData, Permisson, navigate, DownloadExcelFile, encryptData, UserList, userRole } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Incoming Reports')) {
            navigate('404')
        }
    }, [Permisson]);
    const [loading, setLoading] = useState(false);

    const [reports, setReports] = useState([]);
    const [users, setUsers] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [userId, setUserId] = useState(UserData?.id);



    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            sorter: {
                compare: (a, b) => a.Name.length - b.Name.length,
            },
        },
        {
            title: 'Message',
            dataIndex: 'Message',
            render: Message => {
                const containsNumbers = Message.match(/\d+/g);
                if (containsNumbers && Permisson?.includes('Encrypt content')) {
                    let encryptedText = Message;
                    containsNumbers.forEach(numericValue => {
                        encryptedText = encryptedText.replace(numericValue, encryptData(numericValue));
                    });
                    return encryptedText;
                }
                else { return Message }
            },
            sorter: {
                compare: (a, b) => a.Message.length - b.Message.length,
            },
        },
        {
            title: 'From',
            dataIndex: 'From',
            render: From => {
                const data = Permisson?.includes('Encrypt Number') ? encryptData(From) : From;
                return (
                    <span>
                        {data}
                    </span>
                );
            },
            sorter: {
                compare: (a, b) => a.From.length - b.From.length,
            },
        },
        {
            title: 'To',
            dataIndex: 'To',
            //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
            sorter: {
                compare: (a, b) => a.To.length - b.To.length,
            },
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            render: Date => {
                const date = dayjs.unix(Date);
                const formattedDate = date.format('MM/DD/YYYY');
                const formattedTime = date.format('hh:mm:ss A');
                return (
                    <span>
                        {formattedDate} | {formattedTime}
                    </span>
                );
            },
            sorter: {
                compare: (a, b) => a.timestamp.length - b.timestamp.length,
            },
        },
    ];

    const fetchReportList = useCallback(async (id) => {
        if (!id) return;
        setLoading(true);
        const params = {};
        if (startDate) {
            params.startDate = dayjs(startDate, 'DD/MM/YY').format('DD/MM/YY');
        }
        if (endDate) {
            params.endDate = dayjs(endDate, 'DD/MM/YY').format('DD/MM/YY');
        }
        if (searchValue) {
            params.search = searchValue.toLowerCase();
        }

        try {
            const response = await axios.get(`${api}reports/${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                },
                params
            });
            const data = response.data.reverse();
            setReports(data);
        } catch (error) {
            // console.error('Error fetching reports:', error);
            message.error('Failed to fetch reports');
        } finally {
            setLoading(false);
        }
    }, [api, authToken, startDate, endDate, searchValue]);

    const debouncedFetchReports = useCallback( 
        debounce((id) => fetchReportList(id), 300),
        [fetchReportList]
    );

    useEffect(() => {
        if (userId) {
            debouncedFetchReports(userId);
        }
    }, [userId, startDate, endDate, searchValue, debouncedFetchReports]);

    useEffect(() => {
        setUsers(UserList);
    }, [UserList]);

    const HandleUserChange = (value) => {
        setUserId(value);
    };

    const HandleDateRangeChange = (dates) => {
        if (dates && dates.length > 0) {
            const sortedDates = dates.slice().sort((a, b) => new Date(a) - new Date(b));
            const formattedDates = sortedDates.map(date => dayjs(date).format('DD/MM/YY'));
            setStartDate(formattedDates[0]);
            setEndDate(formattedDates[formattedDates.length - 1]);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const onSearch = (e) => {
        if (e.key === 'Enter') {
            debouncedFetchReports(userId);
        }
    };

    return (
        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="py-4">
                    <h2>Incoming Reports</h2>
                </Flex>
            </div>

            <div className='container-fluid'>
                <Flex justifyContent="space-between">
                    <Flex className="mb-1" mobileFlex={false}>
                        <div className="mr-md-3 mb-3">
                            <Form.Item label="">
                                <Input placeholder="Search" prefix={<SearchOutlined />}
                                    onChange={e => setSearchValue(e.currentTarget.value.toLowerCase())}
                                    onKeyDown={onSearch}
                                />
                            </Form.Item>
                        </div>
                        <div className="mr-md-3 mb-3">
                            <Form.Item label="Date Range">
                                <RangePicker
                                    allowClear
                                    // value={startDate && endDate ? [startDate, endDate] : undefined}
                                    onChange={(dates) => HandleDateRangeChange(dates)}
                                />
                            </Form.Item>
                        </div>
                        {(userRole === 'Admin' || userRole === 'Reseller') &&
                            <div className="mr-md-3 mb-3">
                                <Form.Item label="Username">
                                    <Select
                                        showSearch
                                        // size={'default'}
                                        defaultValue={{ key: "choose..." }}
                                        style={{ minWidth: 180 }}
                                        onChange={(value) => HandleUserChange(value)}
                                    >
                                        {
                                            users?.length > 0 ?
                                                users.map((item, index) => (
                                                    <Option value={item.id} key={index}>{item.company_name}</Option>
                                                ))
                                                :
                                                <Option >No Users Found</Option>
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        }
                    </Flex>
                    <div className="mb-3 text-right">
                        <Button icon={<CloudDownloadOutlined />} onClick={() => DownloadExcelFile(reports, 'incoming-report.xlsx')}>Export Incoming Report</Button>
                    </div>
                </Flex>
            </div>
            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={reports}
                    rowKey="id" />
            </div>
        </Card >
    );
};

export default Reports
