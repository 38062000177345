import React, { useEffect, useState } from 'react'
import { Card, Table, Row, Select, Form, Col, DatePicker, Button, Tooltip } from 'antd';
// import { } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { CloudDownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useMyContext } from 'Context/MyContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';


const { RangePicker } = DatePicker;
const { Option } = Select
const LiveStatus = () => {
    const { api, authToken, DownloadExcelFile, Permisson, navigate } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Live Status')) {
            navigate('404')
        }
    }, [Permisson]);
    const [loading, setLoading] = useState(false);

    const [componentSize, setComponentSize] = useState('medium');
    const [analyticsData, setAnaLyticsData] = useState([]);
    const [users, setUsers] = useState([]);
    const [userAnaLytics, setUserAnaLytics] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [liveStatus, setLiveStatus] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const onFormLayoutChange = ({ size }) => {
        // console.log(size)
        setComponentSize(size);
    };


    //analytics
    const GetSettingData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}live-status`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data;
            setLiveStatus((data?.LiveStatus).reverse());
            setLoading(false)
        } catch (error) {
            // console.log(error);
        }
    }
    useEffect(() => {
        setLoading(true);
        GetSettingData();
    }, [])


    const tableColumns = [
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            sorter: {
                compare: (a, b) => a.company_name.length - b.company_name.length,
            },
        },
        {
            title: 'Available Credits',
            dataIndex: 'balance',
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },
        {
            title: 'Campaign Conversation',
            dataIndex: 'total_campaignreports_count',
            sorter: {
                compare: (a, b) => a.total_campaignreports_count.length - b.total_campaignreports_count.length,
            },
        },
        {
            title: 'Incoming Conversation',
            dataIndex: 'total_incomingreports_count',
            sorter: {
                compare: (a, b) => a.total_incomingreports_count.length - b.total_incomingreports_count.length,
            },
        },
        {
            title: 'Outgoing Conversation',
            dataIndex: 'total_outreports_count',
            sorter: {
                compare: (a, b) => a.total_outreports_count.length - b.total_outreports_count.length,
            },
        },

        {
            title: 'Marketing',
            dataIndex: 'outreports_category_marketing_count',
            sorter: {
                compare: (a, b) => a.outreports_category_marketing_count.length - b.outreports_category_marketing_count.length,
            },
        },

        {
            title: 'Utility',
            dataIndex: 'outreports_category_utility_count',
            //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
            sorter: {
                compare: (a, b) => a.outreports_category_utility_count.length - b.outreports_category_utility_count.length,
            },
        },
        {
            title: 'Service',
            dataIndex: 'outreports_category_service_count',
            sorter: {
                compare: (a, b) => a.outreports_category_service_count.length - b.outreports_category_service_count.length,
            },
        },
        // {
        //     title: 'Cost',
        //     dataIndex: 'total_cost',
        //     render: (_, record) => {
        //         if (record?.total_cost) {
        //             return `₹${record?.total_cost.toFixed(2)}`;
        //         }
        //     },
        //     sorter: {
        //         compare: (a, b) => a.total_cost.length - b.total_cost.length,
        //     },
        // },
        // {
        //     title: 'Date',
        //     dataIndex: 'start',
        //     render: date => <span>{dayjs.unix(date).format('DD/MM/YYYY')} </span>,
        //     sorter: (a, b) => a.start - b.start,
        // },
    ];


    return (

        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="py-4">

                    <h2>Live Status</h2>
                </Flex>
            </div>
            <div className='container-fluid'>
                <Row>
                    <Col span={24}>
                    <Flex justifyContent="end">
                        <div className="mb-3 text-right">
                            <Button icon={<CloudDownloadOutlined />} onClick={() => DownloadExcelFile(liveStatus, 'live-status.xlsx')}>Export Live Status</Button>
                        </div>
                    </Flex>
                    </Col>
                </Row>
            </div>
            <div className="table-responsive">
                <Table
                    columns={tableColumns}
                    expandable={{
                        expandedRowRender: (item) => (
                            <>
                                <Flex justifyContent="center">
                                    <Tooltip title="Sent messages count">
                                        <Flex alignItems="center" className='mr-5'>
                                            <FontAwesomeIcon icon={faCheck} />
                                            <p className="m-0 pl-2">{item?.sentCount}</p>
                                        </Flex>
                                    </Tooltip>
                                    <Tooltip title="Delivered messages count">
                                        <Flex alignItems="center" className='mr-5'>
                                            <FontAwesomeIcon icon={faCheckDouble} />
                                            <p className="m-0 pl-2">{item?.deliveredCount}</p>
                                        </Flex>
                                    </Tooltip>
                                    <Tooltip title="Read messages count">
                                        <Flex alignItems="center" className='mr-5'>
                                            <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#22af4b' }} />
                                            <p className="m-0 pl-2">{item?.readCount}</p>
                                        </Flex>
                                    </Tooltip>
                                    <Tooltip title="Failed messages count">
                                        <Flex alignItems="center" className='mr-5'>
                                            <FontAwesomeIcon icon={faCircleExclamation} className='text-danger' />
                                            <p className="m-0 pl-2">{item?.failCount}</p>
                                        </Flex>
                                    </Tooltip>
                                </Flex>
                            </>
                        ),
                    }}
                    loading={loading}
                    dataSource={filterData?.length > 0 ? filterData : liveStatus}
                    rowKey="id"
                />
            </div>
        </Card >
    );
};

export default LiveStatus
