import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, message, Form, Input, Checkbox, Tooltip } from 'antd';

import axios from "axios";
import Flex from "components/shared-components/Flex";
import NumberFormat from "react-number-format";
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import moment from "moment";

import BillingPricingModel from "./billin_pricing_model";
import { useMyContext } from "Context/MyContextProvider";


export const DefaultDashboard = ({ id }) => {


  const { UserCredits, api, authToken, themeData, Permisson } = useMyContext();


  const prefix = null;
  const [initialAmount, setInitialAmount] = useState('00:00');
  const [amount, setAmount] = useState('00:00')
  const [newCredit, setNewCredit] = useState()
  const [remarks, setRemarks] = useState()
  const [history, setHistory] = useState([])
  const [loading, setLoading] = useState(false)
  const [deduction, setDeduction] = useState(false)
  const inputRef = useRef(null);
  const [inputVal, settInputVal] = useState('');

  const [form] = Form.useForm();


  const UpdateBalance = async (e) => {
    if (newCredit) {
      await axios.post(`${api}add-balance`, {
        amount, user_id: id, newCredit, deduction, remarks
      }, {
        headers: {
          'Authorization': 'Bearer ' + authToken,
        }
      })
        .then((response) => {
          if (response.data.status) {
            fetchHistory();
            UserCredits();
            message.success(response.data.message)
            inputRef.current.value = '';
            form.resetFields();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }


  const fetchHistory = async () => {
    setLoading(true)
    await axios.get(`${api}balance-history/${id}`, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      }
    })
      .then((response) => {
        let data = response.data
        const filteredHistory = data?.history;
        setHistory(filteredHistory);
        setAmount(data?.latest_balance || 0);
        setInitialAmount(data?.latest_balance || 0)
      })
      .catch((error) => {
        // console.log(error);
      }).finally(() => setLoading(false));
  }
  useEffect(() => {
    fetchHistory();
  }, [])


  const handleAmount = (value) => {
    setNewCredit(value);
    settInputVal(value)
    if (value && !isNaN(value)) {
      if (deduction) {
        if (value > initialAmount) {
          setAmount('00.00')
        } else {
          setAmount(Math.abs(parseFloat(value) - parseFloat(initialAmount)));
        }
      } else {
        setAmount(parseFloat(value) + parseFloat(initialAmount));
      }

    } else {
      setAmount(initialAmount);
    }
  }



  useEffect(() => {
    if (amount) {
      const parts = amount.toString().split('.');
      const decimalDigits = parts.length > 1 ? parts[1].slice(0, 2) : '';
      const formattedAmount = parts[0] + (decimalDigits ? '.' + decimalDigits : '');
      setAmount(formattedAmount);
    }
  }, [amount])

  useEffect(() => {
    handleAmount(newCredit);
  }, [deduction])

  return (
    <>

      <Row gutter={16} >
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={16} className="bg-red overflow-hidden">
            {Permisson?.includes('View Pricing Model') &&
              <BillingPricingModel id={id} api={api} authToken={authToken} />
            }
            {Permisson?.includes('View Available Credit') &&
              <Col xs={24} sm={24} md={24} lg={24} xl={8} >
                <div className='border-0 rounded p-3'
                  style={{
                    background: themeData.currentTheme === 'dark' ? '#1b2531' : '#fafafb',
                    boxShadow: 'rgba(27, 37, 49, 0.1) 0px 2px 10px' // Note: Corrected alpha value
                  }}
                >
                  <Flex justifyContent='space-between'>
                    <h4 className="mb-0">Available Credit</h4>
                    {Permisson?.includes('Deduct Credit') &&
                      <Checkbox onChange={(e) => setDeduction(e.target.checked)}>Deduction</Checkbox>
                    }
                  </Flex>

                  <div className={`${prefix ? 'd-flex' : ''} ${'Available Credit' ? 'mt-3' : ''}`}>
                    {prefix ? <div className="mr-2">{prefix}</div> : null}
                    <div>
                      <div className="d-flex align-items-center">
                        <h1 className="mb-0 font-weight-bold">{!loading ? '₹' + amount : 'fetching...'}</h1>
                      </div>
                      {Permisson?.includes('Add Credit') &&
                        <div className="balance-form mt-5">
                          <Row >
                            <Col xs={24} sm={24} md={24} lg={24}>
                              <Form
                                form={form}
                                name="credit"
                                layout="vertical"
                                onFinish={(values) => UpdateBalance(values)}
                              >
                                <Form.Item
                                  label={`${deduction ? 'Deduct' : 'Add'} Credit`}
                                  name="Amount"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter your amount!'
                                    }
                                  ]}
                                  initialValue={inputVal}
                                >
                                  {/* <input type="text" value={'12'}></input> */}
                                  <Input size="middle" controls='disabled' name="amount"
                                    onKeyDown={(event) => {
                                      const { key, target } = event;
                                      if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight') {
                                        return;
                                      }
                                      if (!(/^\d$/.test(event.key) || event.key === '.')) {
                                        event.preventDefault();
                                      } else if (target.value.includes('.')) {
                                        const decimalIndex = target.value.indexOf('.');
                                        const decimalPart = target.value.substring(decimalIndex + 1);
                                        if (decimalPart.length >= 2) {
                                          event.preventDefault();
                                        }
                                      } else {
                                        return
                                      }
                                    }}
                                    onChange={(e) => handleAmount(parseFloat(e.target.value))} />
                                </Form.Item>

                                <Form.Item name="remarks" label="Remarks">
                                  <Input autoComplete="off" onChange={e => setRemarks(e.target.value)} />
                                </Form.Item>

                                <Flex justifyContent="end">
                                  <Button type="primary" htmlType="submit">
                                    Submit
                                  </Button>
                                </Flex>
                              </Form>
                            </Col>
                          </Row>
                        </div>
                      }
                    </div>
                  </div>

                </div>
              </Col>
            }
            {Permisson?.includes('View Payment History') &&
              <Col xs={24} sm={24} md={24} lg={24} xl={8} >
                <div className='border-0 rounded p-3'
                  style={{
                    background: themeData.currentTheme === 'dark' ? '#1b2531' : '#fafafb',
                    boxShadow: 'rgba(27, 37, 49, 0.1) 0px 2px 10px' // Note: Corrected alpha value
                  }}
                >
                  <h4 className="mb-0">Payment history</h4>
                  <div className="mt-4" style={{ height: '400px', overflowY: 'auto' }}>
                    {history.length > 0 ?
                      [...history]?.reverse().map((item, index, history) => {
                        if (index === history.length - 1) {
                          return (
                            <div key={index}>
                              <Tooltip title={'Remarks : '+ item?.remarks} >
                                <Flex className="w-100 border rounded p-2 mb-2" justifyContent="space-between" alignItems="center">
                                  <Flex flexDirection='column'>
                                    <h5>₹{item?.total_credits}</h5>
                                    <span className="text-light">{moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                  </Flex>
                                  <Flex>
                                    <div className="mr-3 text-right">
                                      <span className="text-muted">Credits</span>
                                      <div className="mb-0 h5 font-weight-bold">
                                        <NumberFormat
                                          prefix={'₹'}
                                          value={item.new_credit}
                                          thousandSeparator={true}
                                          displayType="text" />
                                        <ArrowUpOutlined className="text-success" />
                                      </div>
                                    </div>
                                  </Flex>
                                </Flex>
                              </Tooltip>
                            </div>
                          );
                        }
                        const nextItem = history[index + 1];
                        return (
                          <Tooltip title={'Remarks :'+item?.remarks} key={index}>
                          <Flex className="w-100 border rounded p-2 mb-2" justifyContent="space-between" alignItems="center">
                            <Flex flexDirection='column'>
                              <h5>₹{item?.total_credits}</h5>
                              <span className="text-light">{moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')}</span>
                            </Flex>
                            <Flex>
                              <div className="mr-3 text-right">
                                <span className="text-muted">Credits</span>
                                <div className="mb-0 h5 font-weight-bold">
                                  <NumberFormat
                                    prefix={'₹'}
                                    value={item.new_credit}
                                    thousandSeparator={true}
                                    displayType="text" />
                                  {item?.total_credits < nextItem?.total_credits ? (
                                    <ArrowDownOutlined className="text-danger" />
                                  ) : (
                                    <ArrowUpOutlined className="text-success" />
                                  )}
                                </div>
                              </div>
                            </Flex>
                          </Flex>
                          </Tooltip>
                        );
                      })
                      :
                      <span style={{ fontSize: '12px' }}>There is no payment history for you</span>
                    }
                  </div>

                </div>
              </Col>
            }
          </Row>
        </Col>
      </Row>


    </>
  )
}


export default DefaultDashboard;
