import { useMyContext } from 'Context/MyContextProvider';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/slices/authSlice';

const Logout = () => {
    const { navigate, setTemplates } = useMyContext();
    const dispatch = useDispatch();

    const handleSignOut = async () => {
        await dispatch(logout())
        setTemplates([])
        navigate('/login')
    }
    useEffect(() => {
        localStorage.removeItem('AUTH_TOKEN');
        handleSignOut()
    }, [navigate]);

    return null;
};

export default Logout;
