import { Alert, Anchor, Card, Table, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
const { Paragraph } = Typography;
const ApiDocument = () => {

    const data = [
        { key: 'type', type: 'Mandatory', descprition: 'C (For Custom Message) / T (For Template Based Message)' },
        { key: 'tname', type: 'Mandatory', descprition: 'Template Name' },
        { key: 'message', type: 'Optional', descprition: 'For custom message content' },
        { key: 'media_url', type: 'optional', descprition: 'Template Header Media File Link' },
        { key: 'file_name', type: 'optional', descprition: 'Media File Name' },
        { key: 'values', type: 'optional', descprition: 'Template Body Dynamic Value. (Comma seperated values for multiple variables)' },
        { key: 'button_value', type: 'optional', descprition: 'Template Buttons Dynamic Value. (Comma seperated values for multiple button values)' }
    ]





    const tableColumns = [
        {
            title: 'Key',
            dataIndex: 'key',
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Description',
            dataIndex: 'descprition',
        },
    ];

    return (
        <>
            <Card>
                <div className='header mb-4'>
                    <h2>Getting Started</h2>
                </div>
                <Anchor>
                    <p className='ml-2'>
                        Take control over rendering and features with our <strong>WhatsApp</strong> API.
                    </p>
                </Anchor>
                <div className='header my-4'>
                    <h2>Accessing the API</h2>
                </div>
                <div className='body border rounded shadow-sm p-3'>
                    <p className='p-0 m-0'>
                        To access our <strong>WhatsApp</strong> message API, you'll need an <strong>API key</strong>. If you're unsure how to generate one, <Link to='/api-key'>click here</Link> to generate a new API key.
                    </p>
                </div>

                {/* Message API */}
                <div className='header my-4'>
                    <h2>Send Custom WhatsApp Message</h2>
                </div>
                <div className='body rounded mb-3'>
                    <p className='p-0 m-0'>Provide correct values for the parameters to receive the desired outcome or response from the API</p>
                    <Alert
                        message=""
                        description={
                            <>
                                <Paragraph className='m-0' copyable>https://waba.smsforyou.biz/api/send-messages?apikey={
                                    <strong>{'{YourApiKey}'}</strong>}
                                    &to=91<strong>{'{XXXXXXXXXX}'}</strong>
                                    &type=<strong>{'{messageType}'}</strong>
                                    &tname=<strong>{'{MessageTemplateName}'}</strong>
                                    &media_url=<strong>{'{MessageMediaUrl}'}</strong>
                                    &values=<strong>{'{MessageValues}'}</strong>
                                    &button_value=<strong>{'{MessageButtonValue}'}</strong></Paragraph>
                            </>
                        }
                        type="info"
                    />
                </div>

                <div className="table-responsive">
                    <Table
                        columns={tableColumns}
                        dataSource={data}
                        pagination={false}
                    />
                </div>
                <div className='body rounded my-3'>
                    <Alert
                        message=""
                        type='warning'
                        description={
                            <>
                                <p className='fw-bold h4'>Example Api Request</p>
                                <p className='fw-bold h5'><ul>
                                    <li><strong>Template Message</strong></li>
                                    <ul>
                                        <li>
                                            <p className='example-api p-0 m-0'>
                                                <Paragraph className='m-0'>https://waba.smsforyou.biz/api/send-messages?apikey={
                                                    <strong>{'AbCdef456H'}</strong>}
                                                    &to=91<strong>{'9234123456'}</strong>
                                                    &type=<strong>{'T'}</strong>
                                                    &tname=<strong>{'Welcome Template'}</strong>
                                                    &media_url=<strong>{'https://yourmedaifilelink'}</strong>
                                                    &file_name=<strong>{'custom.pdf'}</strong>
                                                    &value=<strong>{'V1,V2'}</strong>
                                                    &button_value=<strong>{'B1,B2'}</strong>
                                                </Paragraph>
                                            </p>
                                        </li>
                                    </ul>
                                    <li><strong>Custom Message</strong></li>
                                    <ul>
                                        <li>
                                            <p className='example-api p-0 m-0'>
                                                <Paragraph className='m-0'>https://waba.smsforyou.biz/api/send-messages?apikey={
                                                    <strong>{'AbCdef456H'}</strong>}
                                                    &to=91<strong>{'9234123456'}</strong>
                                                    &type=<strong>{'C'}</strong>
                                                    &message=<strong>{'Hello'}</strong>
                                                </Paragraph>
                                            </p>
                                        </li>
                                    </ul>

                                </ul>
                                </p>

                            </>
                        } />
                </div>
                {/* response */}
                <div className='body rounded my-3'>
                    <p className='p-0 m-0'>Success Response of Custom Message API</p>
                    <Paragraph className='m-0'>
                        <pre>
                            {JSON.stringify({
                                status: true,
                                message_id : 'Message id',
                                message: "Message submitted successfully"
                            }, null, 2)}
                        </pre>
                    </Paragraph>


                </div>
                {/* error  */}
                <div className='body rounded my-3'>
                    <p className='p-0 m-0'>Error Codes</p>
                    <Alert
                        message=""
                        description={<Paragraph className='m-0'>
                            <ul className='m-0'>
                                <li>SF0 : Invalid API key</li>
                                <li>SF1 : Insufficient Credits</li>
                                <li>SF2 : Invalid Mobile Number</li>
                                <li>SF4 : Template Error</li>
                                {/* <li>SF4 : Invalid API key</li> */}
                            </ul>
                        </Paragraph>}
                        type="error"
                    />

                </div>


                {/* Media API */}
                <div className='header my-4'>
                    <h2>Sending Media Messages or Attaching Media Files</h2>
                </div>
                <div className='body rounded'>
                    <p className='p-0 m-0'>Provide correct values for the parameters to receive the desired outcome or response from the API</p>
                    <Alert
                        message=""
                        description={<Paragraph className='m-0' copyable>https://waba.smsforyou.biz/api/send-media-messages?apikey=<strong>YourApiKey</strong>
                        &to=<strong>91XXXXXXXX</strong>
                        &media_url=<strong>MediaUrl</strong>.</Paragraph>}
                        type="info"
                    />

                </div>
                <div className='body rounded my-3'>
                    <p className='p-0 m-0'>Success Response of Media Message API</p>
                    <Paragraph className='m-0'>
                        <pre>
                            {JSON.stringify({
                                status: true,
                                message_id: 'Message id',
                                message: "Message submitted successfully"
                            }, null, 2)}
                        </pre>
                    </Paragraph>


                </div>
                {/* Templates API */}
                <div className='header my-4'>
                    <h2>Templates Listing API</h2>
                </div>
                <div className='body rounded'>
                    <p className='p-0 m-0'>Only pproved templates will be shown</p>
                    <Alert
                        message=""
                        description={<Paragraph className='m-0' copyable>https://waba.smsforyou.biz/api/ext-templates?apikey=<strong>YourApiKey</strong></Paragraph>}
                        type="info"
                    />

                </div>
                <div className='body rounded my-3'>
                    <p className='p-0 m-0'>Success Response of Media Message API</p>
                    <Paragraph className='m-0'>
                        <pre>
                            {JSON.stringify({
                                status: true,
                                templates: ['Template Object','Template Object'],
                            }, null, 2)}
                        </pre>
                    </Paragraph>


                </div>
            </Card>

        </>
    )
}

export default ApiDocument
