import React from 'react';
import { Row, Col, Form, Select, Input, Button, Divider, Card } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';

const { Option } = Select;

const CarouselTemplateButtons = ({ tabKey, state, setState, form }) => {
    const handleStateChange = (newState) => {
        setState((prevState) => ({ ...prevState, ...newState }));
    };

    const handleTypeChange = (value) => {
        handleStateChange({ buttonType: value });

        // Reset fields based on removed button types
        if (!value.includes('visit_website')) {
            form.resetFields([
                `visitWebsite1_button_text_${tabKey}`,
                `visitWebsite1_button_url_${tabKey}`,
                `visitWebsite2_button_text_${tabKey}`,
                `visitWebsite2_button_url_${tabKey}`,
            ]);
            handleStateChange({
                visitWebsite1: { button_text: '', button_url: '' },
                visitWebsite2: { button_text: '', button_url: '' },
                visitWebsiteCount: 1,
            });
        }
        if (!value.includes('call_phone_number')) {
            form.resetFields([`phoneNumber_button_text_${tabKey}`, `phoneNumber_${tabKey}`]);
            handleStateChange({ phoneNumber: { button_text: '', number: '' } });
        }
        if (!value.includes('quick_reply')) {
            handleStateChange({ quickReply: [] });
            form.resetFields([`quick_reply_${tabKey}`]);
        }
    };

    const handleAddQuickReply = () => {
        if (state.quickReply.length < 9) {
            const newQuickReply = [...state.quickReply, ''];
            handleStateChange({ quickReply: newQuickReply });
        }
    };

    const handleRemoveQuickReply = (index) => {
        const newQuickReply = state.quickReply.filter((_, i) => i !== index);
        handleStateChange({ quickReply: newQuickReply });
        form.resetFields([`quick_reply_${tabKey}_${index}`]);
    };

    const handleQuickReplyChange = (index, value) => {
        const newQuickReply = [...state.quickReply];
        newQuickReply[index] = value;
        handleStateChange({ quickReply: newQuickReply });
    };

    const handleVisitWebsiteCount = () => {
        setState(prevState => ({
            ...prevState,
            visitWebsiteCount: prevState.visitWebsiteCount === 1 ? 2 : 1
        }));
    };

    return (
        <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
                <Form.Item label="Button Type" name={`Button_Type_${tabKey}`}>
                    <Select
                    name={`button_type_${tabKey}`}
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select Button Type"
                        onChange={handleTypeChange}
                        // value={state.buttonType}
                    >
                        <Option value="visit_website">Visit Website</Option>
                        <Option value="call_phone_number">Call Phone Number</Option>
                        <Option value="quick_reply">Quick Reply</Option>
                    </Select>
                </Form.Item>
            </Col>

            {state.buttonType?.includes('quick_reply') && (
                <>
                    <Divider variant="dotted" className='my-1'>Quick Reply</Divider>
                    <Card className="w-100 bg-gray-lightest">
                        <Row gutter={ROW_GUTTER}>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item label="Quick Reply" name={'Quick_'+tabKey}>
                                    <Button type="dashed" onClick={handleAddQuickReply} block icon={<PlusOutlined />}>
                                        Add Quick Reply
                                    </Button>
                                </Form.Item>
                            </Col>
                            {state.quickReply.map((reply, idx) => (
                                <Col xs={24} sm={24} md={12} key={idx}>
                                    <Form.Item
                                        label={`Quick Reply ${idx + 1}`}
                                        name={`quick_reply_${tabKey}_${idx}`}
                                        rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                    >
                                        <Input
                                            value={reply}
                                            onChange={(e) => handleQuickReplyChange(idx, e.target.value)}
                                            suffix={<CloseOutlined onClick={() => handleRemoveQuickReply(idx)} />}
                                        />
                                    </Form.Item>
                                </Col>
                            ))}
                        </Row>
                    </Card>
                </>
            )}

            {state.buttonType?.includes('visit_website') && (
                <>
                    <Divider variant="dotted" className='my-1'>Visit Webiste</Divider>
                    <Card className="w-100 bg-gray-lightest">
                        <Row gutter={ROW_GUTTER}>

                            <Col xs={24} sm={24} md={10}>
                                <Form.Item
                                    label="Button 1 Text"
                                    name={`visitWebsite1_button_text_${tabKey}`}
                                    rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                >
                                    <Input
                                        value={state.visitWebsite1.button_text}
                                        onChange={(e) => handleStateChange({ visitWebsite1: { ...state.visitWebsite1, button_text: e.target.value } })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={10}>
                                <Form.Item
                                    label="Button 1 Url"
                                    name={`visitWebsite1_button_url_${tabKey}`}
                                    rules={[{ required: true, message: 'Please enter a valid URL!' }]}
                                >
                                    <Input
                                        value={state.visitWebsite1.button_url}
                                        onChange={(e) => handleStateChange({ visitWebsite1: { ...state.visitWebsite1, button_url: e.target.value } })}
                                    />
                                </Form.Item>
                            </Col>
                            {state.visitWebsiteCount === 2 && (
                                <>
                                    <Col xs={24} sm={24} md={10}>
                                        <Form.Item
                                            label="Button 2 Text"
                                            name={`visitWebsite2_button_text_${tabKey}`}
                                            rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                        >
                                            <Input
                                                value={state.visitWebsite2.button_text}
                                                onChange={(e) => handleStateChange({ visitWebsite2: { ...state.visitWebsite2, button_text: e.target.value } })}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={10}>
                                        <Form.Item
                                            label="Button 2 URL"
                                            name={`visitWebsite2_button_url_${tabKey}`}
                                            rules={[{ required: true, message: 'Please enter a valid URL!' }]}
                                        >
                                            <Input
                                                value={state.visitWebsite2.button_url}
                                                onChange={(e) => handleStateChange({ visitWebsite2: { ...state.visitWebsite2, button_url: e.target.value } })}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                            <Col xs={24} sm={24} md={4}>
                                <Form.Item
                                    label={state.visitWebsiteCount === 1 ? "Add" : 'Remove'}
                                    name="Button_Type"
                                    className='cursor-pointer'
                                >
                                    <span className='border rounded-circle p-2' onClick={() => handleVisitWebsiteCount()}>
                                        {state.visitWebsiteCount === 1 ? <PlusOutlined /> : <CloseOutlined />}
                                    </span>
                                </Form.Item>

                            </Col>
                        </Row>
                    </Card>
                </>
            )}

            {state.buttonType?.includes('call_phone_number') && (
                <>
                    <Divider variant="dotted" className='my-1'>Call Phone Number</Divider>
                    <Card className="w-100 bg-gray-lightest">
                        <Row gutter={ROW_GUTTER}>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    label="Button Text"
                                    name={`phoneNumber_button_text_${tabKey}`}
                                    rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                >
                                    <Input
                                        value={state.phoneNumber.button_text}
                                        onChange={(e) => handleStateChange({ phoneNumber: { ...state.phoneNumber, button_text: e.target.value } })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    label="Phone Number"
                                    name={`phoneNumber_${tabKey}`}
                                    rules={[{ required: true, message: 'Please enter a valid phone number!' }]}
                                >
                                    <Input
                                        value={state.phoneNumber.number}
                                        onChange={(e) => handleStateChange({ phoneNumber: { ...state.phoneNumber, number: e.target.value } })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </>
            )}
        </Row>
    );
};

export default CarouselTemplateButtons;