import React, { useEffect, useState } from 'react';
import { Card, Carousel, Image, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/shared-components/Flex';
import { faPhone, faPlay, faReply, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { UnorderedListOutlined } from '@ant-design/icons';
const { Text } = Typography;
const CarouselPreview = ({ isLoop, mainBody, newMesaage, customMessage, mediaLink, cards }) => {
  // mobile preview style 
  const styles = {

    chatBackground: {
      backgroundImage: `url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: '8px',
      padding: '16px',

      // height:'100%'
    },

    chatBubble: {
      background: 'white',
      borderRadius: '8px',
      padding: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      position: 'relative',
      marginLeft: '20px',
      maxWidth: '85%',
      wordWrap: 'break-word',
    },
    buttoneBubble: {
      background: 'white',
      borderRadius: '8px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,

      // padding: '16px',
      boxShadow: '0 8px 8px -4px rgba(0, 0, 0, 0.15)',
      position: 'relative',
      bottom: '0.5rem',
      marginLeft: '20px',
      maxWidth: '85%',
      wordWrap: 'break-word',
    },
    chatBubbleBefore: {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '-5px',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '10px 10px 10px 0',
      borderColor: 'transparent white transparent transparent',
      background: '#ffff',
      backgroundColor: '#ffff',
      clipPath: 'polygon(0 0, 0 100%, 100% 100%)',
      transform: 'rotate(180deg)',


    },
    chatTime: {
      textAlign: 'right',
      fontSize: '12px',
      color: 'gray',
      // marginTop: '4px',
    },
    footerMessageStyle: {
      fontSize: '12px',
      marginTop: '5px',
      color: '#918f8f'
    },
    buttonStyle: {
      // fontSize: '11px',
      wordWrap: 'break-word',
      fontFamily: 'system-ui',
      display: 'block',
      textAlign: 'justify',
    }
  };

  const [displayText, setDisplayText] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  useEffect(() => {
    setDisplayText(mainBody?.text);
  }, [mainBody]);


  // useEffect(() => {
  //   cards?.map((item,index)=>console.log(item,index))
  // }, [cards]);
  useEffect(() => {
    const updateCurrentTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      setCurrentTime(`${hours}:${minutes}`);
    };

    updateCurrentTime();
    const interval = setInterval(updateCurrentTime, 60000);

    return () => clearInterval(interval);
  }, []);
  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <div style={styles.chatBackground}>
        <div style={styles.chatBubble}>
          <div style={styles.chatBubbleBefore} />
          {displayText && (
            <>
              {displayText?.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line.split(/\*([^*]+)\*/g).map((part, index) => {
                    if (index % 2 === 1) {
                      return <Text key={index} style={{ fontWeight: 'bold' }}>{part}</Text>;
                    } else {
                      return <Text key={index}>{part}</Text>;
                    }
                  })}
                </React.Fragment>
              ))}
            </>
          )}
          <div style={styles.chatTime}>{currentTime}</div>
        </div>
        <Carousel
          dots={false}
          autoplay={isLoop}
          draggable>
          {cards?.map((item, index) => (
            <div key={index} className='mt-1'>
              <div style={styles.chatBubble}>
                {item?.header?.format === 'DOCUMENT' ?
                  <div className='border rounded d-flex justify-content-center align-items-center mb-3' style={{ background: '#ccd0d5' }}>
                    <img
                      src={'/img/pdfBackground.png'}
                      className='my-3'
                      width={70}
                      alt='pdf'
                    />
                  </div>
                  :
                  item?.header?.format === 'IMAGE' ?
                    <Image
                      src={mediaLink ? mediaLink : '/img/others/dummy-header.jpg'}
                      className='mt-1 rounded' />
                    :
                    item?.header && item?.header?.format === 'VIDEO' ?
                      <div className=' d-flex justify-content-center align-items-center mb-3' style={{ background: '' }}>
                        <img src={'/img/dummyvideo.png'} className='mt-1 border rounded' alt='video' width={'100%'} />
                      </div>
                      :
                      <div className='header'>
                        <h5>{item?.header?.text}</h5>
                      </div>
                }
                {item?.body?.text?.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line.split(/\*([^*]+)\*/g).map((part, index) => {
                      if (index % 2 === 1) {
                        return <Text key={index} style={{ fontWeight: 'bold' }}>{part}</Text>;
                      } else {
                        return <Text key={index}>{part}</Text>;
                      }
                    })}
                    <br />
                  </React.Fragment>
                ))}
                <span className='text-gray-light h6'>Card {index+1}</span>
              </div>
              <div style={styles.buttoneBubble}>
                {item?.buttons?.buttons?.length > 0 && item?.buttons?.buttons?.map((item, index) => {
                  // Display the first 2 QUICK_REPLY buttons and all other buttons
                  if (item?.type === "QUICK_REPLY" && index < 2) {
                    return (
                      <span style={styles.buttonStyle} className="border-top py-2" key={index}>
                        <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                          <Flex justifyContent='center' alignItems='center' gap='5px'>
                            <FontAwesomeIcon icon={faReply} />
                            <span>{item?.text}</span>
                          </Flex>
                        </button>
                      </span>
                    );
                  } else if (item?.type !== "QUICK_REPLY") {
                    return (
                      <span style={styles.buttonStyle} className="border-top py-2" key={index}>
                        <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                          <Flex justifyContent='center' alignItems='center' gap='5px'>
                            {item?.type === "URL" && <FontAwesomeIcon icon={faUpRightFromSquare} />}
                            {item?.type === "PHONE_NUMBER" && <FontAwesomeIcon icon={faPhone} />}
                            <span>{item?.text}</span>
                          </Flex>
                        </button>
                      </span>
                    );
                  }
                  return null;
                })}
              </div>
              {/* {item?.buttons?.buttons?.filter(item => item?.type === "QUICK_REPLY").length > 3 &&
                <span style={styles.buttonStyle} className="border-top py-2">
                  <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                    <Flex justifyContent="center" alignItems="center" gap="5px">
                      <UnorderedListOutlined />
                      See all options
                    </Flex>
                  </button>
                </span>
              } */}
            </div>
          ))}
        </Carousel>
        {/* <div style={styles.buttoneBubble}>
          {template?.buttons?.buttons?.length > 0 && template?.buttons?.buttons?.map((item, index) => {
            // Display the first 2 QUICK_REPLY buttons and all other buttons
            if (item?.type === "QUICK_REPLY" && index < 2) {
              return (
                <span style={styles.buttonStyle} className="border-top py-2" key={index}>
                  <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                    <Flex justifyContent='center' alignItems='center' gap='5px'>
                      <FontAwesomeIcon icon={faReply} />
                      <span>{item?.text}</span>
                    </Flex>
                  </button>
                </span>
              );
            } else if (item?.type !== "QUICK_REPLY") {
              return (
                <span style={styles.buttonStyle} className="border-top py-2" key={index}>
                  <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                    <Flex justifyContent='center' alignItems='center' gap='5px'>
                      {item?.type === "URL" && <FontAwesomeIcon icon={faUpRightFromSquare} />}
                      {item?.type === "PHONE_NUMBER" && <FontAwesomeIcon icon={faPhone} />}
                      <span>{item?.text}</span>
                    </Flex>
                  </button>
                </span>
              );
            }
            return null;
          })}

          {template?.buttons?.buttons?.filter(item => item?.type === "QUICK_REPLY").length > 3 &&
            <span style={styles.buttonStyle} className="border-top py-2">
              <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                <Flex justifyContent="center" alignItems="center" gap="5px">
                  <UnorderedListOutlined />
                  See all options
                </Flex>
              </button>
            </span>
          }
        </div> */}
      </div>
      {/* <div style={styles.carouselContainer}>
        <Carousel
          slidesToShow={1.25}
          infinite={false}
          centerMode={false}
          dots={false}
          cssEase="linear"
        >
          {cards.map((card, index) => (
            <div key={index}>
              <div style={styles.carouselCard}>
                <div style={styles.cardHeader}>
                  {card.headerType === 'image' ? (
                    <img src={card.headerSrc} alt={`Card ${index + 1}`} style={styles.cardHeaderContent} />
                  ) : (
                    <>
                      <video style={styles.cardHeaderContent}>
                        <source src={card.headerSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <div style={styles.playButton}>
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </>
                  )}
                </div>
                <div style={styles.cardBody}>{card.body}</div>
                <button style={styles.cardButton}>
                  <FontAwesomeIcon icon={faUpRightFromSquare} /> {card.buttonText}
                </button>
              </div>
            </div>
          ))}
        </Carousel>
      </div> */}
    </div>
  )
}

export default CarouselPreview
