import { Input, Row, Col, Card, Form, Upload, Select, Button, Tabs, message, Collapse, List, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useMyContext } from 'Context/MyContextProvider';
import { ReactTransliterate } from 'react-transliterate';
import Picker from 'emoji-picker-react';
import ChatPreview from '../Template/newPreview';
import Flex from 'components/shared-components/Flex';
import { PlusOutlined, RightOutlined, CloseOutlined, UploadOutlined, SmileOutlined } from '@ant-design/icons';
import CarouselCard from './CarouselCard';
import { capitalize } from 'lodash';
const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;

const NewCarouselTemplate = () => {
    const { waToken, temlateSubmitApi, navigate, appId, api } = useMyContext()
    const [templateName, setTemplateName] = useState('')
    const [header, setHeader] = useState('')
    const [body, setBody] = useState('')
    const [footer, setFooter] = useState('')
    const [buttonType, setButtonType] = useState('')
    const [subButtonType, setSubButtonType] = useState('')
    const [media, setMedia] = useState('')
    const [language, setLanguage] = useState('')
    const [phoneNumber, setPhoneNumber] = useState({ button_text: '', number: '' })

    const [offerCode, setOfferCode] = useState({ button_text: '', offer: '' });
    const [visitWebsite1, setVisitWebsite1] = useState({ button_text: '', button_url: '' });
    const [visitWebsite2, setVisitWebsite2] = useState({ button_text: '', button_url: '' });

    const [vsitWebsiteCount, setVisitWebsiteCount] = useState(1);
    const [quickReply, setQuickReply] = useState([]);


    const [headerType, setHeaderType] = useState('');
    const [templateType, setTamplateType] = useState('Text');
    const [headerHandle, setHeaderHandle] = useState('header_handle');
    const [preview, setPreview] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (templateName) {
            const newValue = templateName.replace(/\s/g, '_').toLowerCase();
            setTemplateName(newValue);
        }
    }, [templateName])

    useEffect(() => {
        if (media) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [media]);



    const props = {
        // showUploadList: false,
    beforeUpload: file => {
        const isValidType = ['image/jpeg', 'image/png', 'image/jpg', 'video/mp4', 'video/3gpp'].includes(file.type);
        
        if (!isValidType) {
            message.error('You can only upload JPG, JPEG, PNG, MP4, or 3GP files!');
            return Upload.LIST_IGNORE; // Prevents the file from being added to the list
        }

        setMedia([file]);
        return false; // Prevents auto-upload
    },
        onRemove: (file) => {
            setMedia(''); // Clear the media state when a file is removed
        },
    };


    const handleAddVariable = () => {
        // Extract existing variable numbers from body
        const variableRegex = /{{(\d+)}}/g;
        const existingVariables = [];
        let match;
        while ((match = variableRegex.exec(body)) !== null) {
            existingVariables.push(parseInt(match[1], 10));
        }

        // Determine next variable number
        const nextNumber = existingVariables.length === 0
            ? 1
            : Math.max(...existingVariables) + 1;

        const newVariable = `{{${nextNumber}}}`;

        // Update body state
        setBody(prevBody => {
            const newVariables = [...existingVariables, nextNumber];
            const sortedVariables = [...new Set(newVariables)]
                .map((_, index) => `{{${index + 1}}}`);

            const updatedBody = `${prevBody} ${newVariable}`;

            const reorderedBody = updatedBody.replace(variableRegex, match => {
                const number = parseInt(match.slice(2, -2), 10);
                return `{{${sortedVariables.indexOf(`{{${number}}}`) + 1}}}`;
            });

            return reorderedBody;
        });
    };


    useEffect(() => {
        setBody(body);
    }, [body]);






    useEffect(() => {
        if (subButtonType === 'visit_website') {
            setPhoneNumber({ button_text: '', number: '' })
            setOfferCode({ button_text: '', offer: '' })
        } else if (subButtonType === 'call_phone_number') {
            setVisitWebsite1({ button_text: '', button_url: '' })
            setVisitWebsite2({ button_text: '', button_url: '' })
            setOfferCode({ button_text: '', offer: '' })
        } else if (subButtonType === 'copy_offer_code') {
            setVisitWebsite1({ button_text: '', button_url: '' })
            setVisitWebsite2({ button_text: '', button_url: '' })
            setPhoneNumber({ button_text: '', number: '' })
        }
    }, [subButtonType])

    useEffect(() => {
        if (
            header ||
            // templateName ||
            body ||
            footer ||
            (quickReply && quickReply.length > 0) ||
            (visitWebsite1.button_text && visitWebsite1.button_url) ||
            (phoneNumber.button_text && phoneNumber.number) ||
            (phoneNumber.button_text && phoneNumber.offer)
        ) {
            setPreview(true);
        }
    }, [header, templateName, body, footer, quickReply, visitWebsite1, phoneNumber]);


    const styles = {
        textarea: {
            width: '100%',
            border: '1px solid #ced4da',
            borderRadius: '.5rem',
            outline: 'none',
        },
    };


    const onEmojiClick = (emoji) => {
        setBody(prevBody => prevBody + emoji.emoji);
    }

    const [showPicker, setShowPicker] = useState(false);
    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    const [tabsData, setTabsData] = useState([
        { key: '1', body: '', buttonType: '' }
    ]);

    const handleAdd = () => {
        if (tabsData.length < 10) {
            const newKey = (Math.max(...tabsData.map(tab => parseInt(tab.key)), 0) + 1).toString();
            setTabsData([...tabsData, { key: newKey, body: '', buttonType: '' }]);
        } else {
            // You can show an alert or some message to the user indicating that the limit has been reached.
            message.warning('Maximum 10 cards allowed.');
        }
    };

    // Remove an existing tab
    const handleRemove = (targetKey) => {
        setTabsData(tabsData.filter(tab => tab.key !== targetKey));
    };

    // Handle tab edit events
    const handleEdit = (targetKey, action) => {
        // console.log('Edit action:', action, 'Target key:', targetKey);
        if (action === 'add') {
            handleAdd();
        } else if (action === 'remove') {
            // console.log('Removing tab:', targetKey);
            handleRemove(targetKey);
        }
    };

    const handleDataUpdate = (tabKey, updatedData) => {

        setTabsData(prevTabsData =>
            prevTabsData.map(tab =>
                tab.key === tabKey ? { ...tab, ...updatedData } : tab
            )
        );
    };

    const items = tabsData.map(tab => ({
        label: `Card ${tab.key}`,
        key: tab.key,
        children: (
            <CarouselCard
                key={tab.key}
                tabKey={tab.key}
                data={tab}
                language={language}
                onUpdate={(updatedData) => handleDataUpdate(tab.key, updatedData)}
            />
        ),
    }));

    const steps = [
        'Select a Template Type.',
        'Add a Template Name.',
        'Choose a Template Language.',
        'Write Bubble Text to introduce the message.',
        'Upload a sample Header Image/Video.',
        'Add Card Data with a description and buttons (if applicable).',
        'Create additional cards if needed. (Max 10 per template)',
        'Ensure all cards have the same Button Type (e.g., quick reply).',
        'Submit the template to Meta by clicking "Submit".'
    ];
    const [loading, setLoading] = useState(false);

    // const GetSessionID = async () => {
    //     if (media && media.length > 0) {
    //         const file = media[0];
    //         const filename = file.name; 
    //         const filetype = file.type; 
    //         const filesize = file.size; 
    //         const url = `https://graph.facebook.com/v20.0/${appId}/uploads?file_name=${filename}&file_length=${filesize}&file_type=${filetype}&access_token=${waToken}`
    //         try {
    //             const res = await axios.post(url);
    //             if (res) {
    //                 console.log(res)
    //                 let sessionId = res.data.id
    //                 GetHanderHandle(sessionId, file)
    //             }
    //         } catch (error) {

    //         }
    //     }
    // }
    // const GetHanderHandle = async (uploadSessionId, file) => {
    //     const url = `https://graph.facebook.com/v20.0/${uploadSessionId}`;

    //     const formData = new FormData();
    //     formData.append('data-binary', file);

    //     try {
    //         const res = await axios.post(url, formData, {
    //             headers: {
    //                 Authorization: `OAuth ${waToken}`, // Assuming waToken contains the user access token
    //                 'file_offset': 0, // Custom header for file offset
    //                'Content-Type': 'multipart/form-data', // Important to set the correct content type
    //                'Access-Control-Allow-Origin': '*',
    //             }
    //         });

    //         if (res) {
    //             console.log(res.data);
    //             setHeaderHandle(res.data); // Handle response data
    //         }
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //     }
    // };

    const uploadFile = async () => {
        if (media) {
            const formData = new FormData();
            formData.append('media', media[0]);
            formData.append('waToken', waToken); // Append waToken to form data
            formData.append('appId', appId); // Append appId to form data

            try {
                const response = await axios.post(`${api}upload-session`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                message.success('Header Set Successfully')
                setHeaderHandle(response.data);
                // Handle the response as needed
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            // console.log('No file selected');
        }
    };
    // submit template 
    const HandleAddTemplate = async () => {
        try {
            // console.log(body) ; return
            const variableRegex = /{{\d+}}/g;
            const matches = body?.match(variableRegex);
            //console.log(matches?.length)
            if (!templateType || !templateName || !language || !body || !headerHandle || !tabsData) {
                message.error('Something is still missing');
                return false;  // Return false if any validation fails
            } else {
                setLoading(true)
                const createHeader = () => ({
                    type: "HEADER",
                    format: "IMAGE",
                    example: {
                        header_handle: headerHandle // assuming header contains the appropriate image handle string
                    }
                });

                const createBody = (body, matches) => ({
                    type: 'BODY',
                    text: body,
                    ...(matches?.length > 0 && {
                        example: {
                            body_text: matches?.map((item, index) => `item${index}`) // Create example body text
                        }
                    })
                });

                const createButtons = (buttonType, visitWebsite1, visitWebsite2, phoneNumber, visitWebsiteCount, quickReply) => {
                    const buttons = [];
                   // console.log(visitWebsiteCount)
                    // Validate quick reply buttons
                    if (buttonType.includes('quick_reply')) {
                        if (!quickReply || quickReply.length === 0) {
                            message.error('Please Re-enter the details: Quick reply buttons value.');
                            return null;
                        }
                
                        quickReply.forEach(item => {
                            buttons.push({
                                type: 'QUICK_REPLY',
                                text: item
                            });
                        });
                    }
                
                    // Validate visit website buttons
                    if (buttonType.includes('visit_website')) {
                        if (!visitWebsite1.button_text || !visitWebsite1.button_url) {
                            message.error('Please Re-enter the details: Visit website button text and URL.');
                            return null;
                        }
                
                        buttons.push({
                            type: 'URL',
                            text: visitWebsite1.button_text,
                            url: visitWebsite1.button_url
                        });
                
                        // Add second website button if applicable
                        if (visitWebsiteCount === 2) {
                            if (!visitWebsite2.button_text || !visitWebsite2.button_url) {
                                message.error('Please Re-enter the details: Second website button text and URL.');
                                return null;
                            }
                            buttons.push({
                                type: 'URL',
                                text: visitWebsite2.button_text,
                                url: visitWebsite2.button_url
                            });
                        }
                    }
                
                    // Validate call phone number button
                    if (buttonType.includes('call_phone_number')) {
                        if (!phoneNumber.button_text || !phoneNumber.number) {
                            message.error('Please Re-enter the details: Phone button text and number.');
                            return null;
                        }
                
                        buttons.push({
                            type: 'PHONE_NUMBER',
                            text: phoneNumber.button_text,
                            phone_number: phoneNumber.number
                        });
                    }
                
                    // If no buttons are created, show an error
                    if (buttons.length === 0) {
                        message.error('At least one button must be created.');
                        return null;
                    }
                
                    return {
                        type: 'BUTTONS',
                        buttons
                    };
                };

                const createCards = () => {
                    return tabsData.map((tab, index) => ({
                        components: [
                            createHeader(tab.header, index),
                            createBody(tab.body),
                            createButtons(tab.buttonType, tab.visitWebsite1, tab.visitWebsite2, tab.phoneNumber,tab.visitWebsiteCount,tab.quickReply)
                        ]
                    }));
                };

                const createComponents = () => {
                    const components = [];

                    // Add body text component
                    if (body) {
                        components.push(createBody(body, matches));
                    }

                    // Add carousel with cards
                    components.push({
                        type: 'CAROUSEL',
                        cards: createCards()
                    });

                    return components;
                };
                let final = {
                    messaging_product: "whatsapp",
                    name: templateName,
                    language: 'en_US',
                    category: templateType,
                    components: createComponents()
                }
                // console.log(final);
                // return;
                await axios.post(temlateSubmitApi, final, { headers: { Authorization: 'Bearer ' + waToken } });
            }
            navigate('carousel/template');
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }
    const [form] = Form.useForm();
    return (
        <>
            <Row gutter={ROW_GUTTER}>
                <Col xs={24} sm={24} md={24} lg={18}>
                    <Card title="New Carousel Template">
                        <Form
                            name="basicInformation"
                            form={form}
                            layout="vertical"
                        >
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Row gutter={ROW_GUTTER}>
                                        <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                                label="Template type"
                                                name="template_type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select template type!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size={'default'}
                                                    defaultValue={{ key: "choose..." }}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setTamplateType(value)}>
                                                    <Option value="MARKETING">MARKETING</Option>
                                                    <Option value="UTILITY">UTILITY</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                                label="Template name"
                                                name="template_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your template name!',
                                                    },
                                                ]}
                                            >
                                                <Input onChange={(e) => setTemplateName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                                label="Language"
                                                name="language"
                                            >
                                                <Select className='mb-2'
                                                    defaultValue="en"
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setLanguage(value)}
                                                >
                                                    <Option value="en_US">English</Option>
                                                    <Option value="gu">Gujarati</Option>
                                                    <Option value="hi">Hindi</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Row gutter={ROW_GUTTER}>
                                                <Col xs={24} sm={24} md={16}>
                                                    <Form.Item
                                                        label="Bubble Text(Body)"
                                                        name="Body"
                                                    >
                                                        <ReactTransliterate
                                                            renderComponent={(customProps) =>
                                                                <textarea
                                                                    {...customProps}
                                                                    rows={4}
                                                                    className='p-2'
                                                                    style={styles.textarea}
                                                                    required
                                                                    maxLength={1024}
                                                                />
                                                            }
                                                            value={body}
                                                            onChangeText={(text) => {
                                                                setBody(text);
                                                            }}
                                                            lang={language}
                                                        />
                                                        <Flex gap='1rem' className='justify-content-end align-items-center'>
                                                            <div className='position-relative float-right d-flex' style={{ gap: '10px' }}>
                                                                {showPicker ? <CloseOutlined onClick={togglePicker} /> : <SmileOutlined onClick={togglePicker} />}
                                                                <span className=''>{body?.length}/1024</span>
                                                            </div>
                                                            <Flex className='justify-content-end'>
                                                                <div style={{ background: '#eee', cursor: 'pointer' }} className='p-2 rounded' onClick={() => handleAddVariable()}>
                                                                    <PlusOutlined />
                                                                    <span className='ml-2'>Add variable</span>
                                                                </div>
                                                            </Flex>
                                                        </Flex>
                                                        <div className='position-absolute' style={{ zIndex: 99, right: '10%' }}>
                                                            {showPicker && <Picker onEmojiClick={onEmojiClick} />}
                                                        </div>
                                                        {/* <Input.TextArea onChange={(e) => setBody(e.target.value)} value={body} /> */}
                                                        <span className='d-none'>{body}</span>
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} sm={24} md={8}>
                                                    <Row gutter={ROW_GUTTER}>
                                                        <Col xs={24} sm={24} md={12}>
                                                            <Form.Item
                                                                label="Header Type"
                                                                name="Header_Type"
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    size={'default'}
                                                                    // defaultValue={{ key: "choose..." }}
                                                                    style={{ width: '100%' }}
                                                                    onChange={(value) => setHeaderType(value)}>
                                                                    <Option value="">None</Option>
                                                                    <Option value="image">Image</Option>
                                                                    <Option value="video">Video</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        {
                                                            headerType &&
                                                            <>
                                                                <Col xs={24} sm={24} md={12} className='text-right'>
                                                                    <div>
                                                                        <Form.Item
                                                                            label=" "
                                                                            name="Header_Type"
                                                                        >
                                                                            <Upload {...props}  accept=".jpg,.jpeg,.png,.mp4,.3gp">
                                                                                <Button icon={<UploadOutlined />} disabled={disabled}>Select Sample {capitalize(headerType)}</Button>
                                                                            </Upload>
                                                                        </Form.Item>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={24} className='text-right'>
                                                                    <Button type="primary" danger disabled={!disabled} onClick={() => uploadFile()}>Upload</Button>
                                                                </Col>
                                                            </>
                                                        }
                                                    </Row>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24}>
                                            <Card className='shadow-sm'>
                                                <Tabs
                                                    type="editable-card"
                                                    defaultActiveKey="1"
                                                    onEdit={handleEdit}
                                                    size={'small'}
                                                    items={items} />
                                            </Card>
                                        </Col>
                                        {/* <Col xs={24} sm={24} md={24}>
                                            <Form.Item
                                                label="Footer"
                                                name="Footer"
                                            >
                                                <Input onChange={(e) => setFooter(e.target.value)} />
                                            </Form.Item>
                                        </Col> */}
                                    </Row>
                                    <div className='d-flex justify-content-end'>
                                        <Button type="primary" className='mr-2' danger onClick={() => navigate('carousel/template')}>
                                            Cancel
                                        </Button>
                                        <Button loading={loading} disabled={loading} type="primary" htmlType="submit"
                                            onClick={() => HandleAddTemplate()}>
                                            Submit
                                        </Button>
                                        <span className='d-none'>{visitWebsite1 + visitWebsite2 + phoneNumber}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6}>
                    <ChatPreview
                        headerType={headerType}
                        header={header}
                        body={body}
                        footer={footer}
                        media={media}
                        buttonType={buttonType}
                        quickReply={quickReply}
                        subButtonType={subButtonType}
                        vsitWebsiteCount={vsitWebsiteCount}
                        phoneNumber={phoneNumber}
                        visitWebsite1={visitWebsite1}
                        visitWebsite2={visitWebsite2}
                        preview={preview}
                    />
                    <Collapse defaultActiveKey={['1']} className='mb-2 bg-white'>
                        <Panel header={<Title level={4} style={{ fontWeight: 'bold', margin: 0 }}>Template Submission Steps</Title>} key="1">
                            <List
                                itemLayout="horizontal"
                                dataSource={steps}
                                renderItem={(item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<RightOutlined style={{ color: '#00a5f4' }} />}
                                            title={item}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Panel>
                    </Collapse>
                </Col>
            </Row >

        </>
    )



}

export default NewCarouselTemplate
