import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Checkbox, Col, DatePicker, Form, Input, message, Modal, Progress, Result, Row, Select, Spin } from 'antd'
import axios from 'axios'
import { useMyContext } from 'Context/MyContextProvider';
import { Link, useLocation } from 'react-router-dom';
import { motion } from "framer-motion"


import "react-transliterate/dist/index.css";


import { debounce, toLower } from 'lodash';
import Flex from 'components/shared-components/Flex';
import TemplateComponent from '../Campaign/TemplateComponent';
import NewMobilePreview from '../Campaign/newMobilePreview';
import MediModel from '../Campaign/MediModel';
const { Option } = Select;

const ReTagret = () => {

    const { UserData, api, authToken, messagesApi, waToken, templates, Permisson, navigate, userBalance, calculateRequredBalance } = useMyContext()


    useEffect(() => {
        if (!Permisson?.includes('View Campaign')) {
            navigate('404')
        }
    }, [Permisson]);

    const [templateList, setTemplateList] = useState([]);
    const [componentSize, setComponentSize] = useState('small');
    const [number, setNumber] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const [numberError, setNumberError] = useState(false);
    const [requestType, setRequestType] = useState('template');
    const [dynamicValues, setDynamicValues] = useState([]);
    const [dynamicButtonUrl, setDynamicUrlButton] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [newMesaage, setNewMessage] = useState('');
    const [name, setName] = useState('');
    const [templatePreview, setTemplatePreview] = useState([])
    const [mediaLink, setMediaLink] = useState('')
    const [mediaName, setMediaName] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const [mediaType, setMediaType] = useState('Media')
    const [template, setTemplate] = useState({
        header: null,
        body: null,
        footer: null,
        buttons: null
    });
    const [templateKey, setTemplateKey] = useState(0);
    const [templateInputs, setTemplateInputs] = useState([]);
    const [templateUrlInputs, setTemplateUrlInputs] = useState([]);
    const [urlButtons, setUrlButtons] = useState([]);
    const [numbers, SetNumbers] = useState([]);

    const [singleLineNumber, setSingleLineNumber] = useState([])
    const [userStatus, setUerStatus] = useState(false)
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [templateCategory, setTemplateCategory] = useState('MARKETING');
    const [blockNumbers, setBlockNumbers] = useState([]);
    const [templateLanguage, setTemplateLanguage] = useState('');
    const [openMediaModel, setOpenMediaModel] = useState(false);
    const [openScheduleModel, setOpenSchedule] = useState(false);
    const [loading, setLoading] = useState(false);




    const GetBlockNumber = async () => {
        try {
            const response = await axios.get(`${api}get-block-numbers/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const serverBlocked = response.data.serverBlocked
            const userBlocked = response.data.serverBlocked

            const formatNumber = (number) => {
                const digits = number.toString();
                if (digits.length === 12) {
                    return digits;
                } else if (digits.length === 10) {
                    return '91' + digits;
                } else {
                    // Handle other cases if needed
                    return null; // Or throw an error
                }
            };
            // Extract and format numbers from serverBlocked
            const serverNumbers = serverBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);

            // Extract and format numbers from userBlocked
            const userNumbers = userBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);


            // Combine both arrays into one
            const total = [...serverNumbers, ...userNumbers];
            setBlockNumbers([...new Set(total)]);
        } catch (error) {
            console.log(error);
        }
    }

    const location = useLocation();
    const { id } = location.state || {}; // Get the passed id
    const [campaignID, setCampaignID] = useState();
    useEffect(() => {
        GetBlockNumber();
        fetchCampaigns();
        if (id) {
            setCampaignID(id);
        }
    }, []);


    useEffect(() => {
        if (templates.length > 0) {
            const nonCarouselTemplates = templates.filter(template =>
                !template.components?.some(comp => comp.type === 'CAROUSEL')
            );
            setTemplateList(nonCarouselTemplates);
        }
    }, [templates]);



    const onFormLayoutChange = ({ size }) => {
        // console.log(size)
        setComponentSize(size);
    };



    useEffect(() => {
        // console.log(number)
        if (number) {
            setNumberError(false)
        }
    }, [number])




    const HandleTemplate = (value) => {

        setMediaLink('');
        setTemplateName(value);
        setTemplateKey(prevKey => prevKey + 1);
        const template = templates?.find((item) => item.name === value);

        setTemplateCategory(template?.category)
        setTemplateLanguage(template?.language)
        setTemplatePreview(template);
        const { header, body, footer, buttons } = extractTemplateSections(template);
        setTemplate({ header, body, footer, buttons });

        const templateBodyDynamicValue = body?.text?.match(/{{\d+}}/g);
        if (templateBodyDynamicValue) {
            const inputs = generateInputsForDynamicValues(templateBodyDynamicValue);
            setTemplateInputs(inputs);
        } else {
            setTemplateInputs([]);
        }

        if (buttons?.buttons) {
            const urlInputs = generateUrlInputs(buttons.buttons);
            setTemplateUrlInputs(urlInputs);
        } else {
            setTemplateUrlInputs([]);
        }
    }

    const extractTemplateSections = (template) => {
        let header = null, body = null, footer = null, buttons = null;
        template?.components?.forEach(section => {
            switch (section.type) {
                case "HEADER":
                    header = section;
                    break;
                case "BODY":
                    body = section;
                    break;
                case "FOOTER":
                    footer = section;
                    break;
                case "BUTTONS":
                    buttons = section;
                    break;
                default:
                    // Handle any other section types if needed
                    break;
            }
        });
        return { header, body, footer, buttons };
    }

    const generateInputsForDynamicValues = (dynamicValues) => {
        return dynamicValues.map((placeholder, index) => (
            <Input
                key={index}
                type="text"
                placeholder={`Enter value for ${placeholder}`}
                onChange={(e) => handleInputChange(index, e.target.value)}
            />
        ));
    }

    const generateUrlInputs = (urlButtons) => {
        return urlButtons.map((item, index) => {
            const isDisabled = !item.hasOwnProperty('example');

            // Only render the input if it's not disabled
            if (!isDisabled) {
                return (
                    <div key={index}>
                        <Link>{item.url}</Link>
                        <Input
                            type="text"
                            placeholder={`Your "${item.text}" Button Value`}
                            onChange={(e) => handleUrlButton(index, e.target.value)}
                            disabled={isDisabled} // Input is not disabled because of the condition
                        />
                    </div>
                );
            }

            // Do not render anything if the item is disabled
            return null;
        });
    };

    const handleInputChange = (index, value) => {
        setDynamicValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index] = value;
            return updatedValues;
        });

    };
    const handleUrlButton = (index, value) => {
        setDynamicUrlButton(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index] = value;
            return updatedValues;
        });
    };

    const HandleDynamicValues = (templatePreview) => {
        if (dynamicValues.length > 0) {
            const text = templatePreview?.components[1]?.text;
            let updatedText = text;
            dynamicValues.forEach((item, index) => {
                updatedText = updatedText?.replace(`{{${index + 1}}}`, item);
            });
            setNewMessage(updatedText); // Log the updated text array
        }
    }

    useEffect(() => {
        HandleDynamicValues(templatePreview)
    }, [templatePreview, dynamicValues])





    useEffect(() => {
        if (requestType === 'template') {
            setCustomMessage('')
        } else {
            setTemplatePreview([])
            setTemplateInputs([]);
            setDynamicValues([]);
            setTemplateUrlInputs([]);
            setDynamicUrlButton([]);
            setTemplate({
                header: null,
                body: null,
                footer: null,
                buttons: null
            });

        }
    }, [requestType])

    useEffect(() => {
        if (numbers?.length > 0) {
            const numbersWithNewlines = numbers?.join('\n');
            setSingleLineNumber(numbersWithNewlines)
        }
        // console.log(numbers)
    }, [numbers])




    const showModal = async () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };


    // for media model 

    const showMediaModel = async () => {
        setOpenMediaModel(true);
    };
    const handleCancelMediaModel = () => {
        setOpenMediaModel(false);
    };

    // for media model 
    const CreditCalculation = async (numberCount) => {
        try {
            const MP = UserData?.pricing_model?.marketing_price;
            return numberCount * MP >= userBalance
        } catch (error) {
            console.error(error);
        }
    }

    const showScheduleModel = async () => {
        const filteredNumbers = filterNumbers(numbers, blockNumbers);
        let CreditNotAvailable = await CreditCalculation(filteredNumbers?.length)
        if (CreditNotAvailable) {
            showModal()
        } else {
            setOpenSchedule(true);
        }
    };
    const handleCancelScheduleModel = () => {
        setOpenSchedule(false);
    };


    useEffect(() => {
        const filteredList = templates
            .filter(item =>
                (!templateCategory || item.category === templateCategory))
            .filter(item => !item.components?.some(comp => comp.type === 'CAROUSEL')
            );

        setTemplateList(filteredList);
    }, [templateCategory, templates]);



    const successFunction = (apiResult) => {
        if (apiResult) {
            // message.success('Campaign Created Successfully');
            // navigate('/campaign-history');
        }
    };


    const buttonStyle = {
        background: 'linear-gradient(116deg,  #40e495, #30dd8a, #2bb673)',
        color: 'white',
        border: 'none'
    }

    const HandleUploadImage = async (path, name) => {
        if (path) {
            setMediaLink(path)
            setMediaName(name)
            setOpenMediaModel(false)
        }
    };


    const HandleScheduleTime = (value) => {
        const { $d } = value;
        const year = $d.getFullYear();
        const month = $d.getMonth() + 1;
        const day = $d.getDate();
        const hours = $d.getHours();
        const minutes = $d.getMinutes();
        // const seconds = $d.getSeconds();
        setScheduleDate(`${year}-${month}-${day}`);
        setScheduleTime(`${hours}:${minutes}:00`);
    }


    const filterNumbers = (numbers, blockNumbers) => {
        const uniqueNumbersSet = [...new Set(numbers)];
        // const numbersArray = number.split(',').map(number => number.trim());
        const numbersArray = number.split(',').map(item => item.trim()).flatMap(item => item.split('\n')).map(number => number.trim());
        // console.log(numbersArray);return
        const combinedNumbers = [...uniqueNumbersSet, ...numbersArray];
        // console.log(combinedNumbers)
        const finalFilteredNumbers = combinedNumbers.filter(number => {
            const num = parseInt(number);
            return !isNaN(num) && (num.toString().length === 10 || num.toString().length === 12);
        }).map(number => {
            const num = parseInt(number);
            return num.toString().length === 10 ? '91' + num : number;
        });
        const filteredNumbers = finalFilteredNumbers.filter(finalNumber => !blockNumbers.includes(finalNumber));
        return filteredNumbers;
    };
    // final submit 

    const HandleSchedule = async () => {
        setLoading(true)
        const filteredNumbers = filterNumbers(numbers, blockNumbers);
        // return
        const campaignResponse = await axios.post(`${api}schedule-campaign/${UserData?.id}`, {
            name,
            user_id: UserData.id,
            numbers: filteredNumbers,
            campaign_type: requestType,
            custom_text: customMessage,
            template_name: templateName,
            header_type: template.header?.format,
            header_media_url: mediaLink,
            body_values: dynamicValues,
            button_type: template?.buttons?.buttons[0].type,
            button_value: dynamicButtonUrl,
            schedule_date: scheduleDate,
            schedule_time: scheduleTime,
        }, {
            headers: {
                Authorization: "Bearer " + authToken,
            }
        });
        // console.log(campaignResponse)
        if (campaignResponse.data.status) {
            navigate('/campaign')
        }
        setLoading(false)
    }





    // send message code start from here 
    const generateContent = (type) => {
        switch (type) {
            case 'IMAGE':
                return { image: { id: mediaLink } };
            case 'VIDEO':
                return { video: { id: mediaLink } };
            case 'DOCUMENT':
                return { document: { id: mediaLink } };
            default:
                return {};
        }
    };
    const [isSending, setIsSending] = useState(true);
    const [progress, setProgress] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const [rateLimit, setRateLimit] = useState();
    // Function to generate the payload for each message
    const generatePayload = (item, requestType, templateName, templateLanguage, template, dynamicValues, dynamicButtonUrl) => {
        return {
            messaging_product: "whatsapp",
            to: item,
            type: "template",
            template: {
                name: templateName,
                language: { code: templateLanguage },
                components: [
                    ...(template.header && template.header?.format !== "TEXT" ? [
                        {
                            type: "header",
                            parameters: [
                                {
                                    type: (() => {
                                        switch (template.header?.format) {
                                            case "IMAGE":
                                                return "image";
                                            case "VIDEO":
                                                return "video";
                                            case "DOCUMENT":
                                                return "document";
                                            default:
                                                return "text";
                                        }
                                    })(),
                                    ...generateContent(template.header?.format, template.header?.example?.header_handle[0]),
                                },
                            ],
                        },
                    ] : []),
                    {
                        type: "body",
                        parameters: dynamicValues && dynamicValues.map((item) => ({ type: "text", text: item })),
                    },
                    ...(template?.buttons?.buttons.length < 3 && template?.buttons && template?.buttons?.buttons[0] && dynamicButtonUrl?.length ? [
                        {
                            type: "button",
                            sub_type: (() => {
                                switch (template?.buttons?.buttons[0].type) {
                                    case "QUICK_REPLY":
                                        return "quick_reply";
                                    case "URL":
                                        return "url";
                                    case "PHONE_NUMBER":
                                        return "url";
                                    default:
                                        return "";
                                }
                            })(),
                            ...(template?.buttons?.buttons[0].type === 'URL' && template?.buttons?.buttons[0]?.example ? {
                                index: 0
                            } : {
                                index: 1
                            }),
                            ...(template?.buttons?.buttons[0]?.example && {
                                parameters: (() => {
                                    switch (template?.buttons?.buttons[0].type) {
                                        case "QUICK_REPLY":
                                            return [{ type: "payload", payload: "btntwo" }];
                                        case "URL":
                                            return dynamicButtonUrl.map((item) => ({ type: "text", text: item }));
                                        default:
                                            return [];
                                    }
                                })()
                            }),
                        },
                    ] : []),
                ]
            },
        }
    };


    // Function to handle the rate-limited message sending
    const sendMessageWithRateLimit = async (messagePayloads, messagesApi, waToken, campaignData, api, authToken) => {
        const customRateLimit = 20; // messages per second
        const interval = 1000 / customRateLimit; // milliseconds
        const reportData = [];
        const totalMessages = messagePayloads.length;
        let sentMessages = 0;
        const sendBatch = async (batch) => {
            await Promise.all(batch.map(async (payload) => {
                const mobileNumber = payload.to; // Store the mobile number for reporting

                try {
                    const res = await axios.post(messagesApi, payload, {
                        headers: { Authorization: `Bearer ${waToken}` },
                    });

                    if (res.data.messages[0].message_status === 'accepted' || res.data.messages[0]?.id) {
                        // Collect the report data
                        reportData.push({
                            campaign_id: campaignData.id,
                            message_id: res.data.messages[0].id,
                            mobile_number: mobileNumber,
                        });
                    }
                } catch (error) {
                    console.error('Error sending message:', error);
                    // Optionally, handle individual error reporting here
                }
                sentMessages++;
                const currentProgress = (sentMessages / totalMessages) * 100;
                setProgress(currentProgress);
            }));
        };

        const processMessages = async () => {
            for (let i = 0; i < messagePayloads.length; i += customRateLimit) {
                const batch = messagePayloads.slice(i, i + customRateLimit);
                await sendBatch(batch);

                // Wait for the next interval before sending the next batch
                await new Promise(resolve => setTimeout(resolve, interval));
            }

            // After all messages are processed, store the campaign reports
            setIsSending(false); // Update sending status
            await storeCampaignReports(reportData, campaignData, api, authToken);
        };

        setIsSending(true); // Start sending process
        processMessages();
    };



    const storeCampaignReports = async (reportData, campaignData, api, authToken) => {
        try {
            setLoading(true)
            const response = await axios.post(`${api}create-campaign-report`, {
                campaign_id: campaignData.id,
                reports: reportData,
                template_category: toLower(templateCategory)
            }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });

            if (response.data.status) {
                console.log('Campaign reports stored successfully:', response.data.message);
            } else {
                console.error('Failed to store campaign reports:', response.data.message);
            }
        } catch (error) {
            console.error('Error storing campaign reports:', error);
        } finally {
            setLoading(false)
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling
        });
    };


    const [showProcessingModal, setShowProcessingModal] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isSending) {
                e.preventDefault();
                e.returnValue = ''; // For most browsers
                return ''; // For older browsers
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isSending]);

    useEffect(() => {
        const handlePopstate = (e) => {
            if (isSending) {
                e.preventDefault();
                setShowProcessingModal(true);
            }
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [isSending]);

    const resetCampaign = () => {
        setProgress()
        setIsModalVisible(false)
        setSingleLineNumber([])
        setNumber('')
    }
    const conicColors = {
        '0%': '#16702F',
        // '50%': '#ffe58f',
        '100%': '#34B55A',
    };

    const [customMediaName, setMediaCustomName] = useState('text')
    const handleCustomMedia = (id, name) => {
        //console.log(id,name)
        setCustomMessage(id)
        setMediaCustomName(name)
        setOpenMediaModel(false)
    }


    const validateNumbers = (number, numbers, setError) => {
        if (number || numbers) {
            const inputNumbers = number
                ? number
                    .split(',')
                    .map(item => item.trim())
                    .flatMap(item => item.split('\n').map(subItem => subItem.trim()))
                    .filter(item => item !== '' && (item.length === 10 || item.length === 12))
                : [];

            const total = (numbers?.length || 0) + inputNumbers.length;

            if (total > 5000) {
                setError('You can only process up to 5000 numbers.');
            } else {
                setError('');
            }
        }
    };

    const debouncedValidateNumbers = debounce(validateNumbers, 500); // Debounce with 500ms delay

    useEffect(() => {
        debouncedValidateNumbers(number, numbers, setError);

        // Cleanup the debounce on unmount
        return () => {
            debouncedValidateNumbers.cancel();
        };
    }, [number, numbers]);




    const [campaigns, setCampaigns] = useState([])
    const [campaignName, setCampaignName] = useState()
    const fetchCampaigns = async () => {
        setLoading(true);
        const params = {};

        try {
            const response = await axios.get(`${api}campaigns/${UserData?.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                },
                params
            });
            const data = (response.data.campaign).reverse();
            setCampaigns(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const [campaignReport, setCampaignReport] = useState([]);
    const [statusNumbers, setStatusNumbers] = useState({
        read: 0,
        delivered: 0,
        sent: 0,
    });
    const plainOptions = [
        {
            label: `Read (${statusNumbers?.read?.length || 0})`,
            value: 'read',
        },
        {
            label: `Delivered (${statusNumbers?.delivered?.length || 0})`,
            value: 'delivered',
        },
        {
            label: `Sent (${statusNumbers?.sent?.length || 0})`,
            value: 'sent',
        }
    ];
    const getCampaignName = (id) => {
        let data = campaigns.find((item) => item?.id === id)
        HandleTemplate(data?.template_name)
        setCampaignName(data?.name + ' - Retarget')
    }

    const fetchCampaignReports = async (id) => {
        getCampaignName(id)
        try {
            setLoading(true)
            const response = await axios.get(`${api}campaign-reports/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data.campaign
            const readNumbers = data.filter(item => item.status === 'read').map(item => item.mobile_number);
            const deliveredNumbers = data.filter(item => item.status === 'delivered').map(item => item.mobile_number);
            const sentNumbers = data.filter(item => item.status === 'sent').map(item => item.mobile_number);
            setStatusNumbers({
                read: readNumbers,
                delivered: deliveredNumbers,
                sent: sentNumbers,
            });
            setCampaignReport(response.data.campaign);
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    const HandleSetNumber = (id) => {
        fetchCampaignReports(id)
    }

    const [selectedCategories, setSelectedCategories] = useState([]);

    const onChange = (checkedValues) => {
        setSelectedCategories(checkedValues);

        // Update numbers based on the selected categories
        let updatedNumbers = [];

        if (checkedValues.includes('read')) {
            updatedNumbers = [...updatedNumbers, ...statusNumbers.read];
        }
        if (checkedValues.includes('delivered')) {
            updatedNumbers = [...updatedNumbers, ...statusNumbers.delivered];
        }
        if (checkedValues.includes('sent')) {
            updatedNumbers = [...updatedNumbers, ...statusNumbers.sent];
        }

        // Set the updated numbers
        SetNumbers(updatedNumbers);
    };

    useEffect(() => {
        // Only call `getCampaignName` and fetch reports if `campaigns` and `campaignID` are available
        if (campaigns.length && campaignID) {
            getCampaignName(campaignID);
            fetchCampaignReports(campaignID);
        }
    }, [campaignID, campaigns]);

    // Main handler function
    // const HandleApiTest = async () => {
    //     console.log(campaignName, numbers, requestType, templateName)
    //     if (!requestType || (requestType === 'template' && templateName === '')) {
    //         setError('You have to select a template first');
    //         scrollToTop()
    //         return;
    //     } else if (template.header && template.header.format !== 'TEXT' && !mediaLink) {
    //         setError('Please Select Media Before Submitting')
    //         scrollToTop()
    //         return;
    //     }
    //     else {
    //         const filteredNumbers = filterNumbers(numbers, blockNumbers);

    //         if (filteredNumbers.length > 5000) {
    //             setError('You can only process up to 5000 numbers.');
    //         } else if (campaignName && filteredNumbers.length > 0 && requestType && templateName) {
    //             // return;
    //             try {
    //                 setLoading(true);
    //                 setIsModalVisible(true);
    //                 const UserResponse = await axios.get(`${api}chek-user/${UserData.id}`, {
    //                     headers: { 'Authorization': `Bearer ${authToken}` },
    //                 });
    //                 setUerStatus(UserResponse.data.status);

    //                 if (UserResponse.data.status === false) {
    //                     showModal();
    //                     return false;
    //                 }

    //                 // Create campaign
    //                 const campaignData = await axios.post(`${api}create-campaign`, {
    //                     name: campaignName,
    //                     user_id: UserData.id,
    //                     templateName: templateName ? templateName : `Custom Message : ${customMessage}`,
    //                 }, {
    //                     headers: { Authorization: `Bearer ${authToken}` },
    //                 }).then(response => response.data.campaign);

    //                 // Generate message payloads
    //                 const messagePayloads = filteredNumbers.map(item => generatePayload(
    //                     item, requestType, templateName, templateLanguage, template, customMessage, dynamicValues, dynamicButtonUrl
    //                 ));

    //                 // Send messages with rate limiting and reporting
    //                 await sendMessageWithRateLimit(messagePayloads, messagesApi, waToken, campaignData, api, authToken);

    //                 successFunction(true);
    //             } catch (error) {
    //                 setError(error.message);
    //             } finally {
    //                 setLoading(false);
    //             }
    //         } else {
    //             setError('One or more fields required');
    //         }
    //     }
    // };





    const isJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };
    const getErrorMessage = () => {
        if (typeof error === 'string') {
            return error;
        } else if (isJSON(JSON.stringify(error))) {
            return JSON.stringify(error);
        }
        return 'An unexpected error occurred';
    };






    // Input validation function
    const validateInputs = () => {
        if (!requestType || (requestType === 'template' && templateName === '')) {
            return 'You have to select a template first';
        } else if (template.header && template.header.format !== 'TEXT' && !mediaLink) {
            return 'Please Select Media Before Submitting';
        }
        return null;
    };

    // User status and balance validation function
    const validateUserAndBalance = async (numbersLength) => {
        const isEnoughBalance = calculateRequredBalance(numbersLength, toLower(templateCategory));
        if (isEnoughBalance === false) {
            showModal()
            message.error('Not enough balance in your account');
            // setError('Not enough balance in your account');
            // scrollToTop();
            return false;
        }
        return true;
    };

    // Fetch user status function
    const fetchUserStatus = async (userId) => {
        return await axios.get(`${api}chek-user/${userId}`, {
            headers: { 'Authorization': `Bearer ${authToken}` },
        });
    };

    // Create campaign function
    const createCampaign = async (name, userId, templateName, customMessage) => {
        const response = await axios.post(`${api}create-campaign`, {
            name : campaignName,
            user_id: userId,
            templateName: templateName ? templateName : `Custom Message : ${customMessage}`,
        }, {
            headers: { Authorization: `Bearer ${authToken}` },
        });
        return response.data.campaign;
    };

    // Generate message payloads function
    const generateMessagePayloads = (filteredNumbers) => {
        return filteredNumbers.map(item => generatePayload(
            item, requestType, templateName, templateLanguage, template, customMessage, dynamicValues, dynamicButtonUrl
        ));
    };
    const HandleApiTest = async () => {
        // Validate inputs
        const errorMessage = validateInputs();
        if (errorMessage) {
            setError(errorMessage);
            scrollToTop();
            return;
        }

        const filteredNumbers = filterNumbers(numbers, blockNumbers);

        if (filteredNumbers.length > 5000) {
            setError('You can only process up to 5000 numbers.');
            scrollToTop();
            return;
        }

        if (!await validateUserAndBalance(filteredNumbers.length)) {
            console.log(validateUserAndBalance)
            showModal()
            return; // Validation failed, error already set
        }
        // return;
        try {
            setLoading(true);
            setIsModalVisible(true);

            const userResponse = await fetchUserStatus(UserData.id);
            setUerStatus(userResponse.data.status);

            if (userResponse.data.status === false) {
                showModal();
                return false;
            }
            const campaignData = await createCampaign(name, UserData.id, templateName, customMessage);
            const messagePayloads = generateMessagePayloads(filteredNumbers);

            // Send messages with rate limiting and reporting
            await sendMessageWithRateLimit(messagePayloads, messagesApi, waToken, campaignData, api, authToken);

            successFunction(true);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            {/* for belance alert  */}
            <Modal
                title="Insufficient Credits"
                open={open}
                onCancel={handleCancel}
                footer={null}
            >
                <Result
                    title="Insufficient credits to run this campaign"
                    extra={
                        <Link to={`/users/manage/${UserData.id}/billing`}>
                            <Button type="primary" key="console">
                                Add Credits
                            </Button>
                        </Link>
                    }
                />
            </Modal>

            {/* schedule model  */}
            <Modal
                title="Schedule Campaign"
                open={openScheduleModel}
                onCancel={handleCancelScheduleModel}
                footer={null}
            >
                <Result
                    status="success"
                    title="Select date and Time"
                    extra={
                        <>
                            <DatePicker
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                onChange={(value) => HandleScheduleTime(value)} />
                            <Link to={``}>
                                <Button type="primary" key="console" onClick={() => HandleSchedule()}>
                                    Schedule Campaign
                                </Button>
                            </Link>
                        </>
                    }
                />
            </Modal>
            {/* model for media file  */}
            <Modal
                title="Select Media"
                open={openMediaModel}
                onCancel={handleCancelMediaModel}
                footer={null}
                width={1200}
            >
                <MediModel
                    HandleUploadImage={HandleUploadImage}
                    requestType={requestType}
                    handleCustomMedia={handleCustomMedia}
                />
            </Modal>

            {/* progress model */}
            <Modal
                title={
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        {progress === 100 ? "Campaign Execution Completed" : "Campaign Execution Under Process"}
                    </div>
                }
                open={isModalVisible}
                footer={null}
                closable={false}
            // onCancel={() => setIsModalVisible(false)}
            // closable={progress === 100}
            >
                <div className="d-flex justify-content-center align-items-center flex-column mt-3">
                    {!loading && <Progress type="circle" percent={Math.round(progress)} strokeColor={conicColors} />}
                    {loading && <div className="d-flex flex-column my-3 justify-content-center align-items-center gap-3">
                        <Spin size='medium' />
                        <p>Generating Reports...</p>
                    </div>
                    }
                    {(progress === 100 && !loading) && (
                        <div className="d-flex justify-content-center mt-3">
                            <Button className="mr-2" onClick={() => resetCampaign()}>New Campaign</Button>
                            <Button onClick={() => navigate('/campaign-history')}>View Report</Button>
                        </div>
                    )}
                </div>
            </Modal>

            <Row gutter={12}>
                {Permisson?.includes('View Campaign') &&
                    <>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <Card title="Re Target Campaign">
                                {error &&
                                    <Col span={24}>
                                        <motion.div
                                            initial={{ opacity: 0, marginBottom: 0 }}
                                            animate={{
                                                opacity: error ? 1 : 0,
                                                marginBottom: error ? 20 : 0
                                            }}>
                                            <Alert type="error" showIcon message={getErrorMessage()}></Alert>
                                        </motion.div>
                                    </Col>
                                }
                                <Col span={24}>
                                    <Form
                                        name="basicInformation"
                                        layout="vertical"
                                        initialValues={{ size: componentSize }}
                                        onValuesChange={onFormLayoutChange}
                                        size={componentSize}
                                    >
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Select Campaign"
                                                    name="Campaign-Name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter campaign name!',
                                                        },
                                                    ]}

                                                >
                                                    <Select
                                                        size="default"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => HandleSetNumber(value)}
                                                        showSearch
                                                        value={campaignID}
                                                    >
                                                        <Option value="choose...">select</Option>
                                                        {campaigns?.length > 0 ? (
                                                            campaigns?.map((item, index) => (
                                                                <Option value={item?.id} key={index}>
                                                                    {item.name}
                                                                </Option>
                                                            ))
                                                        ) : (
                                                            <Option value="">
                                                                You Don't Have Any Approved Templates
                                                            </Option>
                                                        )}
                                                    </Select>
                                                    <span className='d-none'>{campaignID}</span>
                                                </Form.Item>
                                                {loading ?
                                                    <div className="d-flex justify-content-center">
                                                        <Spin />
                                                    </div>
                                                    :
                                                    campaignName &&
                                                    <>
                                                        <Form.Item label="Select Category">
                                                            <Checkbox.Group
                                                                options={plainOptions}
                                                                defaultValue={['Apple']}
                                                                onChange={onChange}
                                                            />
                                                        </Form.Item>
                                                        {numbers?.length > 0 &&
                                                            <Form.Item label="Mobile Number from Groups">
                                                                <Input.TextArea
                                                                    rows={numbers?.length >= 10 ? 10 : 5}
                                                                    type="text"
                                                                    value={singleLineNumber}
                                                                    className='cursor-not-allowed'
                                                                >
                                                                    {/* {singleLineNumber} */}

                                                                </Input.TextArea>

                                                            </Form.Item>
                                                        }
                                                        <Form.Item label={`${numbers?.length > 0 ? 'Additional ' : ''}Mobile Numbers`} >
                                                            <Input.TextArea rows={5} type="text" value={number} onChange={(e) => setNumber(e.target.value)} />
                                                            <p className='float-right'>
                                                                Max 5000 Numbers | Total numbers: {
                                                                    numbers.length +
                                                                    number
                                                                        .split(',')
                                                                        .map(item => item.trim())
                                                                        .flatMap(item => item.split('\n').map(subItem => subItem.trim()))
                                                                        .filter(item => item !== '' && (item.length === 10 || item.length === 12)).length
                                                                }
                                                            </p>
                                                        </Form.Item>

                                                        <TemplateComponent
                                                            templateName={templateName}
                                                            templateCategory={templateCategory}
                                                            setTemplateCategory={setTemplateCategory}
                                                            templateList={templateList}
                                                            HandleTemplate={HandleTemplate}
                                                            template={template}
                                                            mediaType={mediaType}
                                                            showMediaModel={showMediaModel}
                                                            mediaLink={mediaLink}
                                                            buttonStyle={buttonStyle}
                                                            mediaName={mediaName}
                                                            setMediaLink={setMediaLink}
                                                            templateInputs={templateInputs}
                                                            templateUrlInputs={templateUrlInputs} // Pass the templateUrlInputs
                                                            HandleApiTest={HandleApiTest} // Pass the API test handler
                                                            showScheduleModel={showScheduleModel} // Pass the schedule model handler
                                                            loading={loading} // Pass the loading state
                                                            Permisson={Permisson} // Pass the permission list
                                                        />
                                                    </>
                                                }

                                                {/* Handling form buttons */}
                                                <Flex alignItems="center" gap={5}>
                                                    {Permisson?.includes('Create Campaign') && (
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            onClick={(e) => HandleApiTest(e)}
                                                            loading={loading}
                                                        >
                                                            Submit
                                                        </Button>
                                                    )}
                                                    {Permisson?.includes('Schedule Campaign') && (
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            onClick={(e) => showScheduleModel(e)}
                                                            loading={loading}
                                                        >
                                                            Schedule Campaign
                                                        </Button>
                                                    )}
                                                </Flex>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>

                            </Card>
                        </Col>
                        {/* mobile preview  */}
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Card title="Campaign Preview">
                                <NewMobilePreview
                                    requestType={requestType}
                                    template={template}
                                    mediaLink={mediaLink}
                                    thumbnail={thumbnail}
                                    newMesaage={newMesaage}
                                    urlButtons={urlButtons}
                                    customMessage={customMessage}
                                />
                            </Card>
                        </Col>
                    </>
                }
            </Row >
        </>
    )
}

export default ReTagret
