import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Input, Row, Col, message, Alert } from 'antd';
import { useMyContext } from 'Context/MyContextProvider';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ChangePassword = () => {

  const { api, Permisson, navigate, authToken, HandleSendEmail, userRole } = useMyContext();
  const [password, setPassword] = useState()
  const [oldPassword, setOldPassword] = useState()
  const [error, setError] = useState('')
  const { id } = useParams();
  useEffect(() => {
    if (!Permisson?.includes('Change Password')) {
      navigate('404')
    }
  }, [Permisson]);

  const changePasswordFormRef = useRef();
  const onFinish = async () => {
    await axios.post(`${api}update-password/${id}`, { current_password: oldPassword, password }, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      }
    }).then((response) => {
      if (response.data.status) {
        message.success({ content: 'Password Changed successfully!', duration: 2 });
        HandleSendEmail(
          id,
          { template: 'Password Changed', email: response.data.email }
        )
        onReset();
      }
    }).catch((error) => {
      console.log(error.response.data.error);
      setError(error.response.data.error);
    });

  };

  const onReset = () => {
    changePasswordFormRef.current.resetFields();
  };


  const StrongPasswordRule = {
    validator(_, value) {
      if (!value) {
        return Promise.reject(new Error('Please enter your new password!'));
      }
      // Check length
      if (value.length < 8) {
        return Promise.reject(new Error('Password must be at least 8 characters long!'));
      }
      if (value === oldPassword) {
        return Promise.reject(new Error('Old Password and new password should not be same!'));
      }
      // Check if it contains at least one lowercase letter, one uppercase letter, one number, and one special character
      if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/.test(value)) {
        return Promise.reject(new Error('Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character!'));
      }
      // You can add more checks here like avoiding common words, patterns, or personal information
      return Promise.resolve();
    },
  };
  
  return (
    <>
      <h2 className="mb-4">Change Password</h2>
      <Row>
        <Col xs={24} sm={24} md={24} lg={8}>
          {error !== '' &&
            <Alert
              showIcon
              closable
              message={error}
              type="error"
              className='mb-3'
            />
          }
          <Form
            name="changePasswordForm"
            layout="vertical"
            ref={changePasswordFormRef}
            onFinish={onFinish}
          >
            {userRole === 'User' &&
              <Form.Item
                label="Current Password"
                name="currentPassword"
                rules={[{ required: true, message: 'Please enter your currrent password!' }]}
                onChange={(e) => setOldPassword(e.target.value)}
              >
                <Input.Password />
              </Form.Item>
            }

            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                StrongPasswordRule
              ]}
            >
              <Input.Password onChange={(e) => setPassword(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { required: true, message: 'Please confirm your password!' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Password not matched!');
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            {Permisson?.includes('Change Password') &&
              <Button type="primary" htmlType="submit">
                Change password
              </Button>
            }
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ChangePassword;
