import React, { useEffect, useState } from 'react'
import { Card, Table, Tooltip, Button, Form, Modal, Input } from 'antd';
import { EditOutlined, SafetyOutlined } from '@ant-design/icons';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useMyContext } from 'Context/MyContextProvider';

const Role = () => {
    // console.log(api)
    const { api, authToken, waToken } = useMyContext();

    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);



    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (_, item) => (
                <div className="text-right d-flex">
                    <Tooltip title="Edit">
                        <Link onClick={() => HandleEdit(item?.name, item?.id)}>
                            <Button
                                type=""
                                className="mr-2 bg-warning"
                                icon={<EditOutlined className='text-white' />}
                                size="small"
                            />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Permission">
                        <Link to={`permission/${item.id}/${encodeURIComponent(item.name)}`}>
                            <Button
                                type="primary"
                                className="mr-2"
                                icon={<SafetyOutlined className='h4' />}
                                size="small"
                            />
                        </Link>
                    </Tooltip>
                </div>
            ),
        },
    ];


    //role
    const RoleData = async () => {
        try {
            const response = await axios.get(`${api}role-list`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + waToken,
                    }
                });
            const data = (response.data.role).reverse();
            setRoles(data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    useEffect(() => {
        setLoading(true);

        RoleData();
    }, [])


    //model
    const [name, setName] = useState('');
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setIsId] = useState('');
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = async () => {
        try {
            const response = await axios.post(`${api}create-role`, { name, guard_name: name }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                RoleData();
                handleCancel()
            }
        } catch (error) {
            handleCancel()
            console.log(error);
        }

    };
    const handleCancel = () => {
        setName("");
        setOpen(false);
        setIsEdit(false);
    };

    const HandleEdit = (name, id) => {
        setName(name)
        setIsId(id)
        setIsEdit(true);
        setOpen(true);
    }
    const UpdateRole = async () => {
        try {
            const response = await axios.post(`${api}role-update`, { id: editId, name }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                RoleData();
                handleCancel()
            }
        } catch (error) {
            handleCancel()
            console.log(error);
        }

    };
    return (
        <>
            <Modal
                title={`${isEdit ? 'Edit' : 'Create New '} Role`}
                open={open}
                onOk={isEdit ? UpdateRole : handleOk}
                onCancel={handleCancel}
            >
                <Form.Item label="Name" className=" my-3">
                    <Input type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Role name"
                    />
                </Form.Item>
            </Modal>
            <Card bodyStyle={{ padding: '0px' }}>
                <div className="container-fluid">
                    <Flex justifyContent="space-between" alignItems="center" className="py-4">

                        <h2>Roles</h2>
                        <div>
                            <Button
                                type="primary"
                                className="ml-2"
                                onClick={showModal}
                            >
                                <PlusOutlined />
                                <span>New</span>
                            </Button>
                        </div>
                    </Flex>
                </div>
                <div className="table-responsive">
                    <Table loading={loading} columns={tableColumns} dataSource={roles} rowKey="id" />
                </div>
                {/* <UserView data={selectedUser} visible={userProfileVisible} close={() => closeUserProfile()} /> */}
            </Card >
        </>
    );
};

export default Role
