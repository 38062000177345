import React, { useState, useEffect } from 'react'
import { Layout, Typography, Card, Timeline, Collapse, Button, Tooltip, Badge, Row, Col } from 'antd'
import { ClockCircleOutlined, QuestionCircleOutlined, PhoneOutlined, CheckCircleOutlined } from '@ant-design/icons'
import MaintenanceGif from '../../../../assets/image/Maintenance.gif'
const { Content } = Layout
const { Title, Paragraph, Text } = Typography
const { Panel } = Collapse

const statusUpdates = [
  { time: '10:00 AM', message: 'Maintenance started. Estimated downtime: 30 minutes.', done: true },
  { time: '10:15 AM', message: 'Database optimization in progress.', done: true },
  { time: '10:30 AM', message: 'Updating API endpoints for improved performance.', done: false },
  { time: '10:45 AM', message: 'Final testing and system restart.', done: false },
]

const faqs = [
  {
    question: 'Will this affect my ongoing WhatsApp campaigns?',
    answer: 'Any scheduled messages will be sent once the system is back online. There may be a slight delay, but no messages will be lost.',
  },
  {
    question: 'Do I need to reconnect my WhatsApp Business account after maintenance?',
    answer: 'No, your account will remain connected. You should be able to resume operations as normal once maintenance is complete.',
  },
  {
    question: 'Will I lose any data during this maintenance?',
    answer: 'No, all your data is safely backed up and will be available once the maintenance is complete.',
  },
]

const WhatsAppIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.90-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
  </svg>
)

export default function Maintenance() {
  const [timeLeft, setTimeLeft] = useState(30 * 60) // 30 minutes in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0))
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const minutes = Math.floor(timeLeft / 60)
  const seconds = timeLeft % 60

  return (
    <Layout className="min-vh-100" style={{ background: '#F0F2F5' }}>
      <Content className="d-flex align-items-center justify-content-center p-3">
        <div style={{ maxWidth: '800px', width: '100%' }}>
          <Card 
            className="shadow-lg mb-4"
            title={
              <div className="d-flex align-items-center">
                <WhatsAppIcon />
                <Title level={3} className="mb-0 ml-2">WhatsApp Business API Maintenance</Title>
              </div>
            }
            extra={
              <Badge status="processing" text={<Text strong style={{ color: '#25D366' }}>Live Updates</Text>} />
            }
          >
            <Row gutter={[16, 16]} align="middle">
              <Col xs={24} md={12}>
                <Paragraph className="mb-4" style={{ fontSize: '16px' }}>
                  We're currently enhancing our WhatsApp Business API services to provide you with an even better experience. 
                  We appreciate your patience and understand the importance of reliable communication for your business.
                </Paragraph>
                <Card className="mb-4" style={{ background: '#E7F3FF' }}>
                  <Title level={4} style={{ color: '#075E54' }}>
                    <ClockCircleOutlined className="mr-2" /> Estimated completion in: 
                    <Text strong className="ml-2" style={{ color: '#128C7E' }}>
                      {minutes}:{seconds.toString().padStart(2, '0')}
                    </Text>
                  </Title>
                </Card>
              </Col>
              <Col xs={24} md={12} className="text-center">
                <img 
                  src={MaintenanceGif}
                  alt="Maintenance in progress" 
                  style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }}
                />
              </Col>
            </Row>
            <Title level={4} className="mb-3 mt-4" style={{ color: '#075E54' }}>Maintenance Progress</Title>
            <Timeline mode="left">
              {statusUpdates.map((update, index) => (
                <Timeline.Item 
                  key={index} 
                  color={update.done ? '#25D366' : 'gray'}
                  label={update.time}
                  dot={update.done ? <CheckCircleOutlined /> : undefined}
                >
                  {update.message}
                </Timeline.Item>
              ))}
            </Timeline>
          </Card>
          <Card className="shadow-lg mb-4" title={<Title level={4} style={{ color: '#075E54' }}><QuestionCircleOutlined className="mr-2" /> Frequently Asked Questions</Title>}>
            <Collapse bordered={false} ghost>
              {faqs.map((faq, index) => (
                <Panel header={<Text strong>{faq.question}</Text>} key={index}>
                  <p>{faq.answer}</p>
                </Panel>
              ))}
            </Collapse>
          </Card>
          <Tooltip title="Contact Support" placement="left">
            <Button 
              type="primary" 
              shape="circle" 
              icon={<PhoneOutlined />} 
              size="large"
              style={{ 
                position: 'fixed', 
                bottom: '20px', 
                right: '20px', 
                background: '#25D366', 
                borderColor: '#25D366',
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)' 
              }}
            />
          </Tooltip>
        </div>
      </Content>
    </Layout>
  )
}