import React, { useEffect, useState } from 'react'

import { Row, Col, Tag, Image, Modal, message } from 'antd';
// import {  } from '@ant-design/icons';
import ProjectListData from '../../apps/project/project-list/ProjectListData';
import ReactPlayer from 'react-player'
import {
    PlayCircleOutlined,
    FileExcelOutlined
} from '@ant-design/icons';
import { COLORS } from 'constants/ChartConstant';
import Flex from 'components/shared-components/Flex';


import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';

const VIEW_GRID = 'GRID';



const ItemHeader = ({ name, category }) => (
    <div>
        <h5 className="mb-0">{name.length > 15 ? name.substring(0, 16) + '...' : name}</h5>
        <span className="text-muted">{category}</span>
    </div>
)



const mediaBoxStyle = {
    // width: '50rem',
    height: '10rem',
    overflow: 'hidden', // Hide any overflow content
    position: 'relative', // Positioning context for absolute positioning
};

const imageStyle = {
    width: '1020px',
    height: '1020px',
    objectFit: 'contain', // Maintain aspect ratio and fit within the box
    //position: 'absolute', // Position the image within the container
    top: '0',
    left: '0',
};
const GridItem = ({ data, removeId, HandleUploadImage, handleCustomMedia, requestType }) => {
    // video 
    const [openVideo, setOpenVideo] = useState(false);
    const [video, setVideo] = useState({
        path: '',
        title: ''
    });
    const HandleVideoPlay = (path, title) => {
        setOpenVideo(true);
        setVideo({ path: path, title: title })
    };
    const handleCancelVideo = () => {
        setOpenVideo(false);
    };
    const PassMediaData = (media_id, name, url) => {
        if (requestType === 'Custom') {
            handleCustomMedia(media_id, name, url)
        } else {
            HandleUploadImage(media_id, name, url)
        }
    }
    return (
        <>
            {/* video model  */}
            <Modal
                destroyOnClose
                maskClosable={false}
                open={openVideo}
                onCancel={handleCancelVideo}
                footer={null}
            >
                <Flex justifyContent='center' flexDirection='column'>
                    <h4 style={{ width: '90%' }}>{video?.title}</h4>
                    <div className='player'>
                        <ReactPlayer autoPlay={true} url={video?.path} controls={true} width={'100%'} height={'auto'} className='rounded' />
                    </div>
                </Flex>
            </Modal>
            {/* video model end  */}
            <div className='mb-5 border rounded p-2' onClick={() => PassMediaData(data?.media_id, data?.name, data?.path)}>
                <Flex alignItems="center" justifyContent="space-between">
                    {(data.type === 'image/png') || (data.type === 'image/jpg') || (data.type === 'image/jpeg') ?
                        <ItemHeader name={data?.name}
                        // category={data?.type} 
                        />
                        :
                        <ItemHeader
                            name={data?.name}
                        // category={'excel'} 
                        />
                    }
                </Flex>
                <div className="mt-2">
                    <div className='image-box d-flex justify-content-center align-items-center rounded' style={mediaBoxStyle}>
                        {
                            (data.type === 'image/png') || (data.type === 'image/jpg') || (data.type === 'image/jpeg') ?
                                <Image src={data?.path} className='border rounded cursor-pointer' style={imageStyle} preview={false} />
                                :
                                data?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ?
                                    <span style={{ fontSize: '5rem' }}>
                                        <FileExcelOutlined />
                                    </span>
                                    :
                                    data?.type === 'video/mp4' ?
                                        <span style={{ fontSize: '5rem' }}>
                                            <PlayCircleOutlined onClick={() => HandleVideoPlay(data?.path, data?.name)} />

                                        </span>
                                        :
                                        ''
                        }
                    </div>
                </div>
            </div>
        </>
    )
}





const MediModel = ({ HandleUploadImage, handleCustomMedia, requestType }) => {
    const { UserData, api, authToken, Permisson } = useMyContext()
    const [view, setView] = useState(VIEW_GRID);
    const [list, setList] = useState(ProjectListData);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(false);




    const getMedia = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}media/${UserData?.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                },
            });

            // if (response.data?.status) {
            setMedia(response.data?.media)
            setLoading(false)
            // }
        } catch (error) {
            setLoading(false)
            message.error(error);
        }
    };

    useEffect(() => {
        getMedia()
    }, [])



    const deleteItem = id => {
        const data = list.filter(elm => elm.id !== id)
        setList(data)
    }

    return (
        <>
            <div className={`my-4 container-fluid `}>

                <Row gutter={16}>
                    {media.map(item => (
                        <Col xs={24} sm={24} lg={8} xl={8} xxl={4} key={item.id}>
                            <GridItem
                                data={item}
                                removeId={id => deleteItem(id)}
                                HandleUploadImage={HandleUploadImage}
                                handleCustomMedia={handleCustomMedia}
                                requestType={requestType}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    )
}

export default MediModel

