import React, { useCallback, useEffect, useState } from 'react'
import { Card, Table, Tag, Tooltip, Button, Select, Form, DatePicker, message, Input } from 'antd';
import { CloudDownloadOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { useMyContext } from 'Context/MyContextProvider';
import { debounce } from 'lodash';

const { RangePicker } = DatePicker;
const { Option } = Select
const OutReports = () => {
    const { api, authToken, UserData, Permisson, navigate, HandleExport, UserList, userRole } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Outgoing Reports')) {
            navigate('404')
        }
    }, [Permisson]);
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [users, setUsers] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [userId, setUserId] = useState(UserData?.id);
    const [searchValue, setSearchValue] = useState('');
    const [category, setCategory] = useState('');

    const getStatusColor = status => {
        if (status === 'sent') {
            return 'blue'
        }
        if (status === 'delivered') {
            return 'cyan'
        }
        if (status === 'read') {
            return 'green'
        }
        if (status === 'failed') {
            return 'red'
        }
        return ''
    }

    const fetchReports = useCallback(async (id, value) => {
        if (!id) return;
        setLoading(true);
        const params = {};
        if (startDate) {
            params.startDate = dayjs(startDate, 'DD/MM/YY').format('DD/MM/YY');
        }
        if (endDate) {
            params.endDate = dayjs(endDate, 'DD/MM/YY').format('DD/MM/YY');
        }
        if (value) {
            params.search = value.toLowerCase();
        }
        if (category) {
            params.category = category.toLowerCase();
        }
        try {
            const response = await axios.get(`${api}out-reports/${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                },
                params
            });
            const outReports = response.data.out_reports;
            const reversedData = outReports.reverse();
            setReports(reversedData);
        } catch (error) {
            message.error('Error fetching reports');
        } finally {
            setLoading(false);
        }
    }, [api, authToken, startDate, endDate, category]);


    useEffect(() => {
        ErrorCodes()
    }, []);

    useEffect(() => {
        if (userId) {
            fetchReports(userId);
        }
    }, [userId, fetchReports]);

    useEffect(() => {
        setUsers(UserList);
    }, [UserList]);

    const HandleUserChange = (value) => {
        setUserId(value);
    };

    const HandleDateRangeChange = (dates) => {
        if (dates && dates.length > 0) {
            const sortedDates = dates.slice().sort((a, b) => new Date(a) - new Date(b));
            const formattedDates = sortedDates.map(date => dayjs(date).format('DD/MM/YY'));
            setStartDate(formattedDates[0]);
            setEndDate(formattedDates[formattedDates.length - 1]);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };

    // Debounce the fetchReports function
    const debouncedFetchReports = useCallback(
        debounce((id, value) => fetchReports(id, value), 300),
        [fetchReports]
    );

    useEffect(() => {
        if (searchValue) {
            debouncedFetchReports(userId, searchValue);
        } else {
            fetchReports(userId)
        }
    }, [searchValue, userId, debouncedFetchReports]);

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            fetchReports(userId, searchValue);
        }
    };

    const [errorData, setErrorData] = useState();
    const ErrorCodes = async () => {
        try {
            const response = await axios.get(`${api}error-codes`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                }
            });
            const data = response.data.codes;
            setErrorData(data);
        } catch (error) {
        }
    };
    const PasreErrorMessage = (code) => {
        if (code) {
            const message = errorData?.find(item => item.code === parseInt(code));
            return message?.description;
        }
    };

    const tableColumns = [
        {
            title: 'Message Category',
            dataIndex: 'category',
            render: status => (
                <div className="text-capitalize" >
                    {status}
                </div>
            ),
            sorter: {
                compare: (a, b) => a.text_body.length - b.text_body.length,
            },
        },
        {
            title: 'To',
            dataIndex: 'recipient_id',
            sorter: {
                compare: (a, b) => a.recipient_id.length - b.recipient_id.length,
            },
        },
        {
            title: 'Sent Time',
            dataIndex: 'timestamp',
            render: timestamp => {
                const date = dayjs.unix(timestamp);
                const formattedDate = date.format('MM-DD-YYYY');
                const formattedTime = date.format('hh:mm:ss A');
                return (
                    <span>
                        {formattedDate} | {formattedTime}
                    </span>
                );
            },
            sorter: {
                compare: (a, b) => a.timestamp.length - b.timestamp.length,
            },
        },
        {
            title: 'Delivered Time',
            dataIndex: 'delivered_time',
            render: (delivered_time) => {
                return delivered_time ? delivered_time : 'N/A'
            },
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
        {
            title: 'Read Time',
            dataIndex: 'read_time',
            render: (read_time) => {

                return read_time ? read_time : 'N/A';
            },
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
        {
            title: 'status',
            dataIndex: 'status',
            render: (_, record) => {
                // Access the context for error message parsing
                const errorMessage = PasreErrorMessage(record.error_code); // Get the error message if available

                // Determine the content of the Tag
                const tagContent = (
                    <Tag color={getStatusColor(record.status)} style={{ textTransform: 'capitalize', letterSpacing: '1px' }}>
                        {record.status}
                    </Tag>
                );

                // Wrap the Tag in a Tooltip if the status is 'failed'
                return record.status === 'failed' ? (
                    <Tooltip title={errorMessage || 'Unknown error'}>{tagContent}</Tooltip>
                ) : (
                    tagContent
                );
            },
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
        {
            title: 'Billable',
            dataIndex: 'balance_count',
            render: balance_count => (
                <div className="text-capitalize" >
                    {balance_count === 0 ? 'No' : 'Yes'}
                </div>
            ),
            sorter: {
                compare: (a, b) => a.balance_count.length - b.balance_count.length,
            },
        }


    ];

    return (

        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="py-4">
                    <h2>Outgoing Reports</h2>
                </Flex>
            </div>
            <div className='container-fluid'>
                <Flex justifyContent="space-between">
                    <Flex className="mb-1" mobileFlex={false}>
                        <div className="mr-md-3 mb-3">
                            <Form.Item label="">
                                <Input placeholder="Search" prefix={<SearchOutlined />}
                                    value={searchValue}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </Form.Item>
                        </div>
                        <div className="mr-md-3 mb-3">
                            <Form.Item label="Date Range">
                                <RangePicker
                                    allowClear
                                    // value={startDate && endDate ? [startDate, endDate] : undefined}
                                    onChange={(dates) => HandleDateRangeChange(dates)}
                                />
                            </Form.Item>
                        </div>
                        <div className="mr-md-3 mb-3">
                            <Form.Item label="Category">
                                <Select
                                    showSearch
                                    defaultValue={{ key: "choose..." }}
                                    style={{ minWidth: 180 }}
                                    onChange={(value) => setCategory(value)}
                                >
                                    <Option value="service">Service</Option>
                                    <Option value="utility">Utility</Option>
                                    <Option value="marketing">Marketing</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        {(userRole === 'Admin' || userRole === 'Reseller') &&
                            <div className="mr-md-3 mb-3">
                                <Form.Item label="Username">
                                    <Select
                                        showSearch
                                        // size={'default'}
                                        defaultValue={{ key: "choose..." }}
                                        style={{ minWidth: 180 }}
                                        onChange={(value) => HandleUserChange(value)}
                                    >
                                        {
                                            users?.length > 0 ?
                                                users.map((item, index) => (
                                                    <Option value={item.id} key={index}>{item.company_name}</Option>
                                                ))
                                                :
                                                <Option >No Users Found</Option>
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        }
                    </Flex>
                    <div className="mb-3 text-right">
                        <Button icon={<CloudDownloadOutlined />} onClick={() => HandleExport('export-reports', 'incoming-report.xlsx')}>Export Outgoing Report</Button>
                    </div>
                </Flex>
            </div>
            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={reports}
                    rowKey="id"
                    expandable={{
                        expandedRowRender: (record) => <p className='ml-5'>{record.status_id}</p>,
                        // rowExpandable: (record) => record.name !== 'Not Expandable',
                    }}

                />
            </div>
            {/* <UserView data={selectedUser} visible={userProfileVisible} close={() => closeUserProfile()} /> */}
        </Card >
    );
};

export default OutReports
