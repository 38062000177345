import React, { useEffect, useImperativeHandle, useState } from 'react'
import { EyeOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import utils from 'utils'
import Flex from 'components/shared-components/Flex';
import { Link } from 'react-router-dom';
import { message, Modal, Table } from 'antd';
import { useMyContext } from 'Context/MyContextProvider';
import axios from 'axios';
const { confirm } = Modal;
const ExistingMailTemplate = ({ handleEdit, TemplatesData, loading, templates }) => {
    const { api, authToken } = useMyContext();
    const [templatePreview, setTemplatePreview] = useState('')
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        TemplatesData()
    }, [])

    const tableColumns = [
        {
            title: 'Tempate Name',
            dataIndex: 'template_id',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'name')
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'subject')
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            sorter: {
                compare: (a, b) => a.created_at.length - b.created_at.length,
            },
            render: (created_at) => {
                const date = new Date(created_at);
                const day = ("0" + date.getDate()).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                let hours = date.getHours();
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // Handle midnight
                const formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`; // Include seconds
                return `${day}/${month}/${year} | ${formattedTime}`;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '1rem' }}>
                    <Link onClick={() => handleOpen(item?.id)} className='d-flex h4'>
                        <EyeOutlined />
                    </Link>
                    <Link onClick={() => handleEditTemplate(item?.id)} className='d-flex h4'>
                        <EditOutlined />
                    </Link>
                    <span className='d-flex h4 cursor-pointer' onClick={() => showConfirm(item.id)}>
                        <DeleteOutlined />
                    </span>
                </Flex>
            )
        }
    ];

    const handleOpen = (id) => {
        setOpen(true);
        fetchTemplateDetail(id);
    }
    const handleEditTemplate = (id) => {
        let Body = templates.find((item) => item.id === id);
        handleEdit(Body);
    }
    const handleCancel = () => {
        setOpen(false);
    };

    const fetchTemplateDetail = (id) => {
        // console.log(id)
        let Body = templates.find((item) => item.id === id);
        setTemplatePreview(Body);
    }
    const showConfirm = (id) => {
        confirm({
            title: 'Do you Want to delete these item?',
            icon: <ExclamationCircleOutlined />,
            content: 'It will be deleted permanently',
            onOk() {
                HandleDelete(id);
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const HandleDelete = async (id) => {
        try {
            const response = await axios.delete(`${api}email-templates/${id}`, {
                headers: {
                    Authorization: `Bearer ${authToken}` // Pass the token in the Authorization header
                }
            });
            if (response.data.status) {
                TemplatesData();
                message.success('Email template deleted successfully:', response.data.message);
            } else {
                message.error('Failed to delete email template:', response.data.message);
            }
        } catch (error) {
            message.error('Error deleting the email template:', error);
        }
    };
    return (
        <>
            {/* video model  */}
            <Modal
                destroyOnClose
                maskClosable={false}
                open={open}
                onCancel={handleCancel}
                footer={null}
            >
                <Flex justifyContent='center' flexDirection='column'>
                    <h3 className='text-center'>{templatePreview.subject}</h3>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: templatePreview.body,
                        }}
                    />
                </Flex>
            </Modal>
            {/* video model end  */}
            <div className="table-responsive">
                <Table
                    pagination={{ pageSize: 5 }}
                    loading={loading}
                    columns={tableColumns}
                    dataSource={templates}
                    rowKey='id'
                />
            </div>
        </>
    )
}

export default ExistingMailTemplate