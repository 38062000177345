import React, { useState } from 'react';
import { Card, Button, List, Typography, Row, Col, Badge, Tooltip } from 'antd';
import { CheckOutlined, MessageOutlined, RocketOutlined, CrownOutlined } from '@ant-design/icons';
import styled, { keyframes } from 'styled-components';

const { Title, Text } = Typography;

// Keyframes for animation
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(34, 175, 75, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(34, 175, 75, 0); }
  100% { box-shadow: 0 0 0 0 rgba(34, 175, 75, 0); }
`;

// Styled Components for Animations
const AnimatedCard = styled(Card)`
  animation: ${fadeIn} 0.5s ease-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(34, 175, 75, 0.2);
  }
`;

const FloatingIcon = styled.div`
  animation: ${float} 3s ease-in-out infinite;
`;

const PulseButton = styled(Button)`
  animation: ${pulse} 2s infinite;
`;

// Pricing plans data
const pricingPlans = [
    {
        title: 'Basic',
        icon: <MessageOutlined style={{ fontSize: '36px', color: '#fff' }} />,
        price: '$9.99',
        period: '/month',
        features: [
            'Up to 1,000 SMS/month',
            'Basic SMS templates',
            'Email support',
            'SMS delivery reports',
        ],
        buttonText: 'Start Texting',
    },
    {
        title: 'Business',
        icon: <RocketOutlined style={{ fontSize: '36px', color: '#fff' }} />,
        price: '$29.99',
        period: '/month',
        features: [
            'Up to 10,000 SMS/month',
            'Advanced SMS templates',
            '24/7 phone support',
            'SMS scheduling',
            'API access',
        ],
        buttonText: 'Upgrade to Business',
        recommended: true,
    },
    {
        title: 'Enterprise',
        icon: <CrownOutlined style={{ fontSize: '36px', color: '#fff' }} />,
        price: 'Custom',
        period: '',
        features: [
            'Unlimited SMS',
            'Custom integrations',
            'Dedicated account manager',
            'Advanced analytics',
            'Multi-user access',
            'Priority sending',
        ],
        buttonText: 'Contact Sales',
    },
];

// Main component
export default function SMSForYouPricing() {
    const [hoveredPlan, setHoveredPlan] = useState(null);

    return (
        <div className="py-5 bg-light">
            <div className="container">
                <div className="text-center mb-5 p-4 rounded-lg bg-success text-white">
                    <FloatingIcon>
                        <MessageOutlined style={{ fontSize: '64px', marginBottom: '16px' }} />
                    </FloatingIcon>
                    <Title level={2} className="font-weight-bold">
                        SMS For You Pricing
                    </Title>
                    <Text className="mt-3 h5">
                        Choose the perfect plan to connect with your audience
                    </Text>
                </div>

                <Row gutter={[24, 24]} className="mt-4">
                    {pricingPlans.map((plan, index) => (
                        <Col xs={24} md={8} key={index}>
                            <AnimatedCard
                                className={`h-100 d-flex flex-column ${plan.recommended ? 'border-success border-2' : ''
                                    }`}
                                onMouseEnter={() => setHoveredPlan(index)}
                                onMouseLeave={() => setHoveredPlan(null)}
                                hoverable
                                cover={
                                    <div className="text-center py-3 bg-success bg-opacity-25">
                                        <FloatingIcon>{plan.icon}</FloatingIcon>
                                        <Title level={3} className="text-dark mt-2">
                                            {plan.title}
                                        </Title>
                                    </div>
                                }
                            >
                                <Badge.Ribbon
                                    text="Most Popular"
                                    color="#22af4b"
                                    style={{ display: plan.recommended ? 'block' : 'none' }}
                                >
                                    <div className="text-center mb-3">
                                        <Title level={2} className="mb-0 text-success">
                                            {plan.price}
                                            <span className="text-secondary h6 font-weight-normal">
                                                {plan.period}
                                            </span>
                                        </Title>
                                    </div>
                                </Badge.Ribbon>
                                <List
                                    dataSource={plan.features}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Tooltip title={item}>
                                                <Text>
                                                    <CheckOutlined className="text-success mr-2" />
                                                    {item}
                                                </Text>
                                            </Tooltip>
                                        </List.Item>
                                    )}
                                    className="flex-grow"
                                />
                                <PulseButton
                                    type={plan.recommended ? 'primary' : 'default'}
                                    size="large"
                                    block
                                    className={`mt-4 ${hoveredPlan === index ? 'bg-success text-white' : ''
                                        }`}
                                    style={{
                                        backgroundColor: plan.recommended ? '#22af4b' : '',
                                        borderColor: plan.recommended ? '#22af4b' : '',
                                        boxShadow: plan.recommended
                                            ? '0 4px 14px 0 rgba(34, 175, 75, 0.39)'
                                            : '',
                                    }}
                                >
                                    {plan.buttonText}
                                </PulseButton>
                            </AnimatedCard>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
}
