import React, { useEffect, useState } from 'react'
import { Card, Col, Input, Row, Table } from 'antd';
import { EditOutlined,SearchOutlined } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import { Link } from "react-router-dom";
import utils from 'utils'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import moment from 'moment';
const UserConfig = () => {
    const { api, authToken } = useMyContext();

    const [groupListData, setGroupData] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [loading, setLoading] = useState(false)

    const ConfigData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}configs-with-user`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const data = (response.data.data).reverse();
            setGroupData(data);
            setFilteredUsers(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        ConfigData()
    }, [])

    const resetCodes = () => setGroupData(filteredUsers)

    const tableColumns = [
        {
            title: 'Name', // Updated title for clarity
            dataIndex: 'user', // The key corresponding to the user object
            render: (_, item) => item?.user?.company_name, // Render the company name from the user object
            sorter: (a, b) => utils.antdTableSorter(a.user.company_name, b.user.company_name) // Sorting by company name
        },
        {
            title: 'App ID',
            dataIndex: 'app_id', // Directly corresponds to app_id in the data object
            sorter: (a, b) => utils.antdTableSorter(a.app_id, b.app_id) // Sorting by app_id
        },
        {
            title: 'Business Account ID',
            dataIndex: 'business_account_id', // Directly corresponds to business_account_id
            sorter: (a, b) => utils.antdTableSorter(a.business_account_id, b.business_account_id) // Sorting by business_account_id
        },
        {
            title: 'WhatsApp Business Account ID',
            dataIndex: 'whatsapp_business_account_id', // Corresponds to whatsapp_business_account_id
            sorter: (a, b) => utils.antdTableSorter(a.whatsapp_business_account_id, b.whatsapp_business_account_id) // Sorting by whatsapp_business_account_id
        },
        {
            title: 'Created At', // Title for the created_at column
            dataIndex: 'created_at', // Directly corresponds to created_at
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'), // Format date for better readability
            sorter: (a, b) => utils.antdTableSorter(a.created_at, b.created_at) // Sorting by created_at
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '1rem' }}>
                    <Link to={`/users/manage/${item?.user?.id}/credential`} className='d-flex h4'>
                        <EditOutlined />
                    </Link>
                </Flex>
            )
        }
    ];

    const [searchText, setSearchText] = useState('');

    const handleSearch = (value) => {
        setSearchText(value);
        if (value) {
            const searchLower = value.toLowerCase();
            const filtered = groupListData.filter((item) =>
                tableColumns.some((column) =>
                    item[column.dataIndex]?.toString().toLowerCase().includes(searchLower)
                )
            );
            setGroupData(filtered);
        } else {
            resetCodes()
        }
    };

    useEffect(() => {
        handleSearch(searchText)
    }, [searchText]);
    return (
        <Card>
            <div className="container-fluid mb-2">
                <Flex justifyContent="space-between" alignItems="center">
                    <h2>User Config</h2>
                </Flex>
                    <Row gutter={[16, 16]} justify="end" align="middle">
                        <Col xs={24} sm={12} md={10} lg={4}>
                            <Input
                                suffix={<SearchOutlined />}
                                allowClear
                                className="p-2 rounded"
                                size="large"
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Col>
                    </Row>
            </div>
            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={groupListData}
                    rowKey='id'
                />
            </div>
        </Card>
    )
}

export default UserConfig
