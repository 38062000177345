import { useMyContext } from 'Context/MyContextProvider';
import { Button, Col, Form, Input, Row, message } from 'antd';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';

const BillingPricingModel = ({ api, authToken, id }) => {
    const { Permisson } = useMyContext();

    const themeData = useSelector((auth) => auth.theme)
    const [priceAlert, setPriceAlert] = useState()
    const [marketingPrice, setMarketingPrice] = useState()
    const [utilityPrice, setUtilityPrice] = useState()
    const [servicePrice, setServicePrice] = useState()
    const [authenticationPrice, setAuthenticationPrice] = useState()
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState()
   
    const [form] = Form.useForm();

    const HandlePrice = async (e) => {
        // e.preventDefault();
        if (priceAlert || marketingPrice || utilityPrice) {
            await axios.post(`${api}pricing-model`, {
                user_id: id, priceAlert, marketingPrice, utilityPrice, servicePrice, authenticationPrice
            }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            }).then((response) => {
                if (response.data.status) {
                    message.success('Pricing Model Updated Successfully')
                }
            }).catch((error) => {
                message.error('Oops! Something Went Wrong')
                console.log(error);
            });
        }

    }

    const fetchHistory = async () => {
        setLoading(true)
        await axios.get(`${api}pricing-history/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((response) => {
                // if (response.data.status) {
                setLoading(false)
                setPriceAlert(parseFloat(response.data.pricing_history[0].price_alert))
                setMarketingPrice(parseFloat(response.data.pricing_history[0].marketing_price))
                setUtilityPrice(parseFloat(response.data.pricing_history[0].utility_price))
                setServicePrice(parseFloat(response.data.pricing_history[0].service_price))
                setAuthenticationPrice(parseFloat(response.data.pricing_history[0].authentication_price))
                // }
            })
            .catch((error) => {
                setLoading(false)
                // console.log(error);
            });
    }
    useEffect(() => {
        if (Permisson?.includes('Add or Update Pricing Model')) {
            setDisable(false)
        }
        fetchHistory();
    }, [])

    const handleKeyDown = (event) => {
        const { key, target } = event;
        if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight') {
            return;
        }
        if (!(/^\d$/.test(event.key) || event.key === '.')) {
            event.preventDefault();
        } else if (target.value.includes('.')) {
            const decimalIndex = target.value.indexOf('.');
            const decimalPart = target.value.substring(decimalIndex + 1);
            if (decimalPart.length >= 2) {
                event.preventDefault();
            }
        }
    };

    return (
        <>
            {!loading &&
                <Col xs={24} sm={24} md={24} lg={24} xl={8} >
                    <div className='border-0 rounded p-3'
                        style={{
                            background: themeData.currentTheme === 'dark' ? '#1b2531' : '#fafafb',
                            boxShadow: 'rgba(27, 37, 49, 0.1) 0px 2px 10px' // Note: Corrected alpha value
                        }}
                    >
                        <h4 className="mb-0">Pricing Model</h4>
                        <div className={``}>
                            <div>
                                <div className="d-flex align-items-center">
                                    {/* <h5 className="mb-0 font-weight-bold">Alert Credit</h5> */}
                                </div>
                                <div className="balance-form mt-2">
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form
                                                form={form}
                                                name="pricing model"
                                                layout="vertical"
                                                disabled={disable}
                                            >
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                    {[
                                                        { label: 'Low credit alert', name: 'price_alert', value: priceAlert, setter: setPriceAlert, size: 24 },
                                                        { label: 'Marketing Price', name: 'marketingPrice', value: marketingPrice, setter: setMarketingPrice, size: 12 },
                                                        { label: 'Utility Price', name: 'utilityPrice', value: utilityPrice, setter: setUtilityPrice, size: 12 },
                                                        { label: 'Service Price', name: 'servicePrice', value: servicePrice, setter: setServicePrice, size: 12 },
                                                        { label: 'Authentication Price', name: 'authenticationPrice', value: authenticationPrice, setter: setAuthenticationPrice, size: 12 },
                                                    ].map((item, index) => (
                                                        <Col xs={24} sm={24} md={24} lg={item.size} key={index}>
                                                            <Form.Item
                                                                label={item.label}
                                                                name={item.name}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: `Please enter ${item.label.toLowerCase()}!`
                                                                    }
                                                                ]}
                                                                initialValue={item.value}
                                                            >
                                                                <Input
                                                                    size="middle"
                                                                    controls="disabled"
                                                                    onKeyDown={handleKeyDown}
                                                                    onChange={(e) => item.setter(parseFloat(e.target.value))}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    ))}
                                                </Row>

                                                {Permisson?.includes('Add or Update Pricing Model') &&
                                                    <Flex justifyContent="end">
                                                        <Button type="primary" htmlType="submit" onClick={(e) => HandlePrice(e)}>
                                                            Submit
                                                        </Button>
                                                    </Flex>
                                                }
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            }
        </>
    )
}

export default BillingPricingModel
