import React, { useEffect, useState } from 'react';
import { Form, Button, Input, Row, Col, message, Checkbox } from 'antd';

import { ROW_GUTTER } from 'constants/ThemeConstant';
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';

const Security = ({ api, authToken, emailAlert, navigate, id }) => {

    const { Permisson, HandleSendEmail } = useMyContext()
    const [ipAuth, setIpAuth] = useState(false);
    const [twoFectorAuth, setTwoFectorAuth] = useState(false);
    const [ipAddresses, setIPAddress] = useState('');
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (Permisson?.includes('Update User Security')) {
            setDisable(false)
        }
        setLoading(true);
        axios.get(`${api}edit-user/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then(response => {
                // console.log(response.data.user[0])
                setIpAuth(response.data.user[0].ip_auth === 'true' ? true : false);
                setTwoFectorAuth(response.data.user[0].two_fector_auth === 'true' ? true : false);
                const ipAddressesArray = JSON.parse(response.data.user[0].ip_addresses); // Parse JSON string to array
                const commaSeparated = ipAddressesArray?.join(',');
                setIPAddress(commaSeparated);

                setLoading(false);

            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    }, [])

    const UpdateSecurity = async (e) => {
        e.preventDefault();
        const ipAddressesArray = ipAddresses?.split(',')?.map(ip => ip.trim());
        //console.log(ipAddressesArray)
        await axios.post(`${api}update-security`, {
            id,
            ip_auth: ipAuth,
            two_fector_auth: twoFectorAuth,
            ip_addresses: ipAddressesArray,
        }, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((response) => {
                message.success(response.data.message)
                if (emailAlert) {
                    HandleSendEmail(id, { template: 'Login Security Status Update', email: response.data.email })
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const HandleIp = (e) => {
        setIpAuth(e.target.checked);
    }
    const HandleTwoFector = (e) => {
        setTwoFectorAuth(e.target.checked);
    }


    return (
        <>
            <div className="mt-4">
                <h2 className="mb-4">Login Security</h2>
                {!loading && (
                    <Form
                        name="basicInformation"
                        layout="vertical"
                        disabled={disable}
                        initialValues={
                            {
                                'ip_auth': ipAuth && ipAuth,
                                'two_fector': twoFectorAuth && twoFectorAuth,
                                'IP_Addresses': ipAddresses && ipAddresses,
                            }
                        }
                    >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={16}>
                                <Row gutter={ROW_GUTTER}>
                                    <Col xs={24} sm={24} md={12}>
                                        <Row gutter={ROW_GUTTER}>
                                            <Col xs={24} sm={24} md={24}>
                                                <Form.Item
                                                    label="Protect Login By IP Adrress"
                                                    name="ip_auth"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your App ID!',
                                                        },
                                                    ]}
                                                >
                                                    <Checkbox checked={ipAuth} onChange={HandleIp} >IP Adrress Authentication</Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24}>
                                                <Form.Item
                                                    label="Enter Your IP Addresses"
                                                    name="IP_Addresses"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your IP Addresses!',
                                                        },
                                                    ]}
                                                >
                                                    <Input.TextArea onChange={(e) => setIPAddress(e.target.value)} />

                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            label="Two factor Otp Authentication"
                                            name="two_fector"
                                            rules={[{
                                                required: true,
                                                message: 'Please enter a valid Whatsapp Business Account ID!'
                                            }]}
                                        >
                                            <Checkbox checked={twoFectorAuth} onChange={HandleTwoFector}>Two Factor Authentication</Checkbox>
                                            <p style={{ fontSize: '12px' }}>user will get the otp on registered mobile number and email address</p>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='d-flex justify-content-end'>
                                    <Button type="primary" className='mr-2' danger onClick={(e) => navigate('/users')}>
                                        Cancel
                                    </Button>
                                    {Permisson?.includes('Update Meta Credential') &&
                                        <Button type="primary" htmlType="submit"
                                            onClick={(e) => UpdateSecurity(e)}
                                        >
                                            Save Change
                                        </Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )}
            </div>
        </>
    )
}

export default Security