import React, { useEffect, useState } from 'react'
import { Card, Table, Tag, Tooltip, Button, Col, Modal, message, Drawer, Switch } from 'antd';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { PlusOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useMyContext } from 'Context/MyContextProvider';
import CarouselPreview from './carouselPreview';

const { confirm } = Modal;
const CarouselTemplates = () => {
    const { templates, Permisson, navigate, HandleExport, templateDelete, waToken, GetTemplate } = useMyContext();
    const [loading, setLoading] = useState(false);
    const [carouselTemplate, setCarouselTemplate] = useState([]);

    useEffect(() => {
        if (!Permisson?.includes('View Template')) {
            navigate('404')
        }
        if (templates.length > 0) {
            const carouselTemplates = templates?.filter(template =>
                template.components?.some(comp => comp.type === 'CAROUSEL')
            );
            setCarouselTemplate(carouselTemplates)
        }
    }, [Permisson, templates]);



    const tableColumns = [
        {
            title: 'Template Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },
        {
            title: 'Language',
            dataIndex: 'language',
            sorter: {
                compare: (a, b) => a.language.length - b.language.length,
            },
        },
        {
            title: 'Template Type',
            dataIndex: 'category',
            //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: status => (
                <Tag className="text-capitalize" color={status === 'APPROVED' ? 'cyan' : 'red'}>
                    {status}
                </Tag>
            ),
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
        // {
        //     title: 'Message Delievered',
        //     dataIndex: 'category',
        //   //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
        //     sorter: {
        //       compare: (a, b) => a.name.length - b.name.length,
        //     },
        //   },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (_, item) => (
                <div className="text-right d-flex">
                    {Permisson?.includes('Preview Template') &&
                        <Tooltip title="View">
                            <Button
                                type="primary"
                                className="mr-2"
                                icon={<EyeOutlined />}
                                onClick={() => { showDrawer(item) }}
                                size="small"
                            />
                        </Tooltip>
                    }
                    {Permisson?.includes('Delete Template') &&
                        <Tooltip title="Delete">
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => { showConfirm(item.id, item.name) }}
                                size="small"
                            />
                        </Tooltip>
                    }
                </div>
            ),
        },
    ];

    function showConfirm(id, name) {
        confirm({
            title: 'Do you want to delete this template?',
            content: "By deleting the template, it will be permanently removed from Meta and can't be restored.",
            onOk() {
                HandleDelete(id, name)
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    const HandleDelete = async (id, name) => {
        const deleteApi = templateDelete
            .replace(':template_id:', id)
            .replace(':name:', name);
        setLoading(true)
        // console.log(deleteApi)
        await axios.delete(deleteApi, {
            headers: {
                Authorization: "Bearer " + waToken,
            }
        }).then((res) => {
            if (res.data.success) {
                setLoading()
                GetTemplate()
                message.success('Template Deleted Successfully')
            }
        }).catch((error) => {
            setLoading()
            message.error("Template Cant't be Deleted")
            console.log(error)
        })
    }


    //drawer states 
    const [open, setOpen] = useState(false);
    const [template, setTemplate] = useState([]);
    const [mainBody, setMainBody] = useState(null);
    const [cards, setCards] = useState();
    const showDrawer = (item) => {
        const { templateCards, mainBodyTemp } = extractTemplateSections(item);
        setMainBody(mainBodyTemp);
        setCards(templateCards)
        setOpen(true);
    };

    const extractTemplateSections = (template) => {
        if (template) {
            let mainBodyTemp = null;
            let templateCards = [];
            template.components.forEach(section => {
                if (section.type === "BODY") {
                    mainBodyTemp = section;
                } else if (section.type === "CAROUSEL") {
                    templateCards = section.cards.map(card => {
                        let cardHeader = null;
                        let cardBody = null;
                        let cardButtons = null;
                        card.components.forEach(cardSection => {
                            switch (cardSection.type) {
                                case "HEADER":
                                    cardHeader = cardSection;
                                    break;
                                case "BODY":
                                    cardBody = cardSection;
                                    break;
                                case "BUTTONS":
                                    cardButtons = cardSection;
                                    break;
                                default:
                                    break;
                            }
                        });
                        return { header: cardHeader, body: cardBody, buttons: cardButtons };
                    });
                }
            });
            return { mainBodyTemp, templateCards }
        }
    }

    const [isLoop, setIsLoop] = useState(true);

    const handleSwitchChange = (checked) => {
        setIsLoop(checked)
    }


    const onClose = () => {
        setOpen(false);
    };


    return (
        <>
            <Card bodyStyle={{ padding: '0px' }}>
                <div className="container-fluid">
                    <Flex justifyContent="space-between" alignItems="center" className="py-4">
                        <h2>Carousel Templates</h2>
                        <div className='d-flex'>
                            <div>
                                <Col span={4}>
                                    <div className="mb-3 text-right">
                                        <Button icon={<CloudDownloadOutlined />} onClick={() => HandleExport('export-template', 'templates.xlsx')}>Export Templates</Button>
                                    </div>
                                </Col>
                            </div>
                            {Permisson?.includes('Create Template') &&
                                <div>
                                    <Link to='add'>
                                        <Button type="primary" className="ml-2" >
                                            <PlusOutlined />
                                            <span>New</span>
                                        </Button>
                                    </Link>
                                </div>
                            }
                        </div>
                    </Flex>
                </div>

                <div className="table-responsive">
                    <Table loading={loading} columns={tableColumns} dataSource={carouselTemplate} rowKey="id" />
                </div>
            </Card>
            <div className="">

                <Drawer
                    title="Template Preview"
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    open={open}
                    getContainer={false}
                    rootStyle={{
                        position: 'absolute',
                    }}
                >
                    <Flex alignItems='center' justifyContent="space-between" className='mb-2'>
                        <span>Template Preview</span>
                        <Flex alignItems='center'>
                            <span className='h5 m-0 mr-2 p-0'>Auto Scroll</span>
                            <Switch checked={isLoop} onChange={handleSwitchChange} />
                        </Flex>
                    </Flex>
                    <CarouselPreview
                        mainBody={mainBody}
                        requestType={'template'}
                        cards={cards}
                        isLoop={isLoop}
                    // thumbnail={thumbnail}
                    // newMesaage={newMesaage}
                    // urlButtons={urlButtons}
                    // customMessage={customMessage}
                    />
                </Drawer>
            </div>
        </>
    );
};

export default CarouselTemplates
