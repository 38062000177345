import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Radio, Row, Col } from 'antd';
import { PlusOutlined, CloseOutlined, SmileOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Picker from 'emoji-picker-react'; // Assuming you have a picker component
import Flex from 'components/shared-components/Flex';
import { ReactTransliterate } from 'react-transliterate';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import CarouselTemplateButtons from './CarouselTemplateButtons';

const { Option } = Select;

const DynamicInput = ({ label, value, onChange, placeholder, rules }) => (
    <Col xs={24} sm={24} md={24}>
        <Form.Item label={label} rules={rules}>
            <Input
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </Form.Item>
    </Col>
);

const CarouselCard = ({ tabKey, activeTab, data, onUpdate, language }) => {
    const [state, setState] = useState({
        phoneNumber: { button_text: '', number: '' },
        media: '',
        offerCode: { button_text: '', offer: '' },
        visitWebsite1: { button_text: '', button_url: '' },
        visitWebsite2: { button_text: '', button_url: '' },
        buttonType: [],
        subButtonType: '',
        visitWebsiteCount: 1,
        quickReply: [],
        selected: null,
        variables: [''],
        body: '',
        showPicker: false,
        quickReplyInputs: []
    });

    useEffect(() => {
        if (data) {
            onUpdate(state);
        }
    }, [state]);

    // useEffect(() => {
    //     // Reset state if tab changes
    //     if (activeTab !== index) {
    //         setState(prevState => ({
    //             ...prevState,
    //             phoneNumber: { button_text: '', number: '' },
    //             header: '',
    //             media: '',
    //             offerCode: { button_text: '', offer: '' },
    //             visitWebsite1: { button_text: '', button_url: '' },
    //             visitWebsite2: { button_text: '', button_url: '' },
    //             buttonType: '',
    //             subButtonType: '',
    //             visitWebsiteCount: 1,
    //             quickReply: [],
    //             selected: null,
    //             headerType: 'Text',
    //             language: '',
    //             variables: [''],
    //             body: '',
    //             showPicker: false,
    //             quickReplyInputs: []
    //         }));
    //     }
    // }, [activeTab]);


    const styles = {
        textarea: {
            width: '100%',
            border: '1px solid #ced4da',
            borderRadius: '.5rem',
            outline: 'none',
        },
    };

    const onEmojiClick = (emoji) => {
        setState(prevState => ({ ...prevState, body: prevState.body + emoji.emoji }));
    };

    const togglePicker = () => {
        setState(prevState => ({ ...prevState, showPicker: !prevState.showPicker }));
    };

    // const handleAddVariable = () => {
    //     // Extract existing variable numbers from body
    //     const variableRegex = /{{(\d+)}}/g;
    //     const existingVariables = [];
    //     let match;
    //     while ((match = variableRegex.exec(state.body)) !== null) {
    //         existingVariables.push(parseInt(match[1], 10));
    //     }

    //     // Determine next variable number
    //     const nextNumber = existingVariables.length === 0
    //         ? 1
    //         : Math.max(...existingVariables) + 1;

    //     const newVariable = `{{${nextNumber}}}`;

    //     // Update state
    //     setState(prevState => {
    //         const newVariables = [...prevState.variables, newVariable];
    //         const sortedVariables = [...new Set(newVariables)]
    //             .map((_, index) => `{{${index + 1}}}`);

    //         const updatedBody = `${prevState.body} ${newVariable}`;

    //         const reorderedBody = updatedBody.replace(variableRegex, match => {
    //             const number = parseInt(match.slice(2, -2), 10);
    //             return `{{${sortedVariables.indexOf(`{{${number}}}`) + 1}}}`;
    //         });
    //         console.log(sortedVariables)
    //         return {
    //             ...prevState,
    //             variables: sortedVariables,
    //             body: reorderedBody
    //         };
    //     });
    // };

    const handleStateChange = (newState) => {
        setState(prevState => ({ ...prevState, ...newState }));
    };

    const [form] = Form.useForm();
    return (
        <div>
            <Col xs={24} sm={24} md={24}>
                <Form.Item
                    form={form}
                    label="Card Text (Body)"
                    name={`Body_${tabKey}`} // Add tabKey to name
                >
                    <ReactTransliterate
                        renderComponent={(customProps) => (
                            <textarea
                                {...customProps}
                                rows={2}
                                className='p-2'
                                style={styles.textarea}
                                required
                                maxLength={159}
                            />
                        )}
                        value={state.body}
                        onChangeText={(text) => handleStateChange({ body: text })}
                        lang={language}
                    />
                    <Flex gap='1rem' className='justify-content-end align-items-center'>
                        <div className='position-relative float-right d-flex' style={{ gap: '10px' }}>
                            {state.showPicker ? <CloseOutlined onClick={togglePicker} /> : <SmileOutlined onClick={togglePicker} />}
                            <span>{state.body?.length}/160</span>
                        </div>
                    </Flex>
                    <div className='position-absolute' style={{ zIndex: 99, right: '10%' }}>
                        {state.showPicker && <Picker onEmojiClick={onEmojiClick} />}
                    </div>
                    <span className='d-none'>{state.body}</span>
                </Form.Item>
            </Col>
            <CarouselTemplateButtons
                tabKey={tabKey}
                state={state}
                setState={setState}
                form={form}
            />
        </div>

    );
};

export default CarouselCard;
