import React, { useEffect, useState } from 'react'
import { Card, Table, Row, Select, Form, Col, DatePicker, Button } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { useMyContext } from 'Context/MyContextProvider';


const { RangePicker } = DatePicker;
const { Option } = Select
const CreditHistory = () => {
    const { api, authToken, UserData, HandleExport, Permisson, navigate } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Payment History')) {
            navigate('404')
        }
    }, [Permisson]);
    const [loading, setLoading] = useState(false);

    const [componentSize, setComponentSize] = useState('medium');
    const [users, setUsers] = useState([]);
    const [userAnaLytics, setUserAnaLytics] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [endDate, setEndDate] = useState('');
    const [history, setHistory] = useState([])
    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };


    const fetchHistory = async () => {
        setLoading(true)
        await axios.get(`${api}balance-history/${UserData.id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((response) => {
            let data = response.data?.allHistory?.reverse()
            setLoading(false)
            setHistory(data);
        })
    }

    useEffect(() => {
        setLoading(true);
        fetchHistory();
    }, [])


    const tableColumns = [
        {
            title: 'Company Name',
            dataIndex: 'name',
            render: (_, record) => { return record?.user?.company_name },
            sorter: {
                compare: (a, b) => a?.user?.company_name.length - b?.user?.company_name.length,
            },
        },
        {
            title: 'Account Manager',
            dataIndex: null,
            render: (_, record) => { return record?.account_manager?.company_name },
            sorter: {
                compare: (a, b) => a?.account_manager?.company_name.length - b?.account_manager?.company_name.length,
            },
        },
        {
            title: 'Credit',
            dataIndex: 'new_credit',
            //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
            sorter: {
                compare: (a, b) => a.new_credit.length - b.new_credit.length,
            },
        },
        {
            title: 'Transaction Type',
            dataIndex: 'type',
            render: (_, record) => { return record?.manual_deduction !== null ? 'Debit' : 'Credit' },
            sorter: {
                compare: (a, b) => a.total_outreports_count.length - b.total_outreports_count.length,
            },
        },

        {
            title: 'date',
            dataIndex: 'created_at',
            render: (created_at) => {
                const date = new Date(created_at);
                const day = ("0" + date.getDate()).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                let hours = date.getHours();
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // Handle midnight
                const formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`; // Include seconds
                return `${day}/${month}/${year} | ${formattedTime}`;
            },
            sorter: {
                compare: (a, b) => a.outreports_category_marketing_count.length - b.outreports_category_marketing_count.length,
            },
        },
    ];

    const UserList = () => {
        setLoading(true);
        axios.get(`${api}users`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then(response => {
                setUsers(response.data.user);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        UserList();
    }, []);


    const HandleUserChange = (value) => {
        // combineData();
        const filter = userAnaLytics.filter((item) => item.conversation_category === value)
        setFilterData(filter);
    }


    const HandleDateRangeChange = (dates) => {

        if (dates && dates.length > 0) {
            // Sort the dates in ascending order
            const sortedDates = dates.slice().sort((a, b) => new Date(a) - new Date(b));

            // Format sorted dates in "dd mm yy" format
            const formattedDates = sortedDates.map(date => {
                const formattedDate = new Date(date);
                const dd = String(formattedDate.getDate()).padStart(2, '0');
                const mm = String(formattedDate.getMonth() + 1).padStart(2, '0'); // January is 0!
                const yy = String(formattedDate.getFullYear()).slice(-2); // Get last 2 digits of the year
                return `${dd} ${mm} ${yy}`;
            });

            // Now you can use formattedDates as the sorted date range in "dd mm yy" format
            setEndDate(formattedDates[0])
            setEndDate(formattedDates[formattedDates.length - 1])
        }
    };



    return (

        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="py-4">

                    <h2>Credit History</h2>
                    <Button icon={<CloudDownloadOutlined />} onClick={() => HandleExport('export-credit-history', 'crdit_history.xlsx')}>Export Credit History</Button>
                </Flex>
            </div>
            <div className='container-fluid'>
                <Row>
                    <Col span={24}>
                        <Form
                            layout="horizontal"
                            initialValues={{ size: componentSize }}
                            onValuesChange={onFormLayoutChange}
                            size={componentSize}
                        >
                            <Form.Item>
                                <Row style={{ gap: '1rem' }}>
                                    <Col span={3}>

                                        <Form.Item label="Username">
                                            <Select
                                                showSearch
                                                size={'default'}
                                                defaultValue={{ key: "choose..." }}
                                                style={{ width: '100%' }}
                                                onChange={(value) => HandleUserChange(value)}
                                            >
                                                {
                                                    users?.length > 0 ?
                                                        users.map((item, index) => (
                                                            <Option value={item.name} key={index}>{item.name}</Option>
                                                        ))
                                                        :
                                                        <Option >No Users Found</Option>
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>

                                        <Form.Item label="Category">
                                            <Select
                                                showSearch
                                                size={'default'}
                                                defaultValue={{ key: "choose..." }}
                                                style={{ width: '100%' }}
                                                onChange={(value) => HandleUserChange(value)}
                                            >
                                                <Option value="MARKETING">MARKETING</Option>
                                                <Option value="UTILITY">UTILITY</Option>

                                            </Select>
                                        </Form.Item>

                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Date Range">
                                            <RangePicker
                                                onChange={(dates) => HandleDateRangeChange(dates)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
            <div className="table-responsive">
                <Table loading={loading} columns={tableColumns} dataSource={filterData?.length > 0 ? filterData : history} rowKey="id" />
            </div>
        </Card >
    );
};

export default CreditHistory
