import React, { useEffect, useState } from 'react';
import { Card, Image, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/shared-components/Flex';
import { faPhone, faReply, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { UnorderedListOutlined } from '@ant-design/icons';
const { Text } = Typography;
const NewMobilePreview = ({ requestType, template, newMesaage, customMessage, mediaLink }) => {
  // mobile preview style 
  const styles = {

    chatBackground: {
      backgroundImage: `url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: '8px',
      padding: '16px',

      // height:'100%'
    },
    chatBubble: {
      background: 'white',
      borderRadius: '8px',
      padding: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      position: 'relative',
      marginLeft: '20px',
      maxWidth: '85%',
      wordWrap: 'break-word',
    },
    buttoneBubble: {
      background: 'white',
      borderRadius: '8px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,

      // padding: '16px',
      boxShadow: '0 8px 8px -4px rgba(0, 0, 0, 0.15)',
      position: 'relative',
      bottom: '0.5rem',
      marginLeft: '20px',
      maxWidth: '85%',
      wordWrap: 'break-word',
    },
    chatBubbleBefore: {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '-5px',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '10px 10px 10px 0',
      borderColor: 'transparent white transparent transparent',
      background: '#ffff',
      backgroundColor: '#ffff',
      clipPath: 'polygon(0 0, 0 100%, 100% 100%)',
      transform: 'rotate(180deg)',
    },
    chatTime: {
      textAlign: 'right',
      fontSize: '12px',
      color: 'gray',
      marginTop: '8px',
    },
    footerMessageStyle: {
      fontSize: '12px',
      marginTop: '5px',
      color: '#918f8f'
    },
    buttonStyle: {
      // fontSize: '11px',
      wordWrap: 'break-word',
      fontFamily: 'system-ui',
      display: 'block',
      textAlign: 'justify',
    }
  };

  const [displayText, setDisplayText] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  useEffect(() => {
    setDisplayText(newMesaage ? newMesaage : template?.body?.text);
  }, [newMesaage, template]);


  useEffect(() => {
    const updateCurrentTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      setCurrentTime(`${hours}:${minutes}`);
    };

    updateCurrentTime();
    const interval = setInterval(updateCurrentTime, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <div style={styles.chatBackground}>
        <div style={styles.chatBubble}>
          <div style={styles.chatBubbleBefore}></div>
          {requestType === 'template' ? (
            <>
              {
                template?.header?.format === 'DOCUMENT' ?
                  <div className='border rounded d-flex justify-content-center align-items-center mb-3' style={{ background: '#ccd0d5' }}>
                    <img
                      src={'/img/pdfBackground.png'}
                      className='my-3'
                      width={70}
                      alt='pdf'
                    />
                  </div>
                  :
                  template?.header?.format === 'IMAGE' ?
                    <Image
                      // src={mediaLink ? mediaLink : template?.header?.example?.header_handle[0]}
                      src='/img/others/dummy-header.jpg'
                      className='mt-1' />
                    :
                    template?.header && template?.header?.format === 'VIDEO' ?
                      <div className=' d-flex justify-content-center align-items-center mb-3' style={{ background: '' }}>
                        <img src={'/img/dummyvideo.png'} className='mt-1 border rounded' alt='video' width={'100%'} />
                      </div>
                      :
                      <div className='header'>
                        <h5>{template?.header?.text}</h5>
                      </div>
              }
              {displayText && (
                <div className='mt-1'>
                  {displayText?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line.split(/\*([^*]+)\*/g).map((part, index) => {
                        if (index % 2 === 1) {
                          return <Text key={index} style={{ fontWeight: 'bold' }}>{part}</Text>;
                        } else {
                          return <Text key={index}>{part}</Text>;
                        }
                      })}
                      <br />
                    </React.Fragment>
                  ))}

                  <br />
                </div>
              )}
              <span style={styles.footerMessageStyle}>{template?.footer?.text}</span>
            </>
          ) : (
            customMessage?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line.split(/\*([^*]+)\*/g).map((part, subIndex) => (
                  subIndex % 2 === 1 ? (
                    <Text key={subIndex} style={{ fontWeight: 'bold' }}>{part}</Text>
                  ) : (
                    <Text key={subIndex}>{part}</Text>
                  )
                ))}
                <br />
              </React.Fragment>
            ))
          )}
          <div style={styles.chatTime}>{currentTime}</div>
        </div>
        {/* <div style={styles.buttoneBubble}>
          {template?.buttons?.buttons?.length > 0 && template?.buttons?.buttons?.map((item, index) => {
            return (
              index < 2 &&
              <span style={styles.buttonStyle} className="border-top py-2">
                <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                  <Flex justifyContent='center' alignItems='center' gap='5px'>
                    {item?.type === "QUICK_REPLY" && <FontAwesomeIcon icon={faReply} />}
                    {item?.type === "URL" && <FontAwesomeIcon icon={faUpRightFromSquare} />}
                    {item?.type === "PHONE_NUMBER" && <FontAwesomeIcon icon={faPhone} />}
                    <span>{item?.text}</span>
                  </Flex>
                </button>
              </span>
            )
          })
          }
          {template?.buttons?.buttons?.length > 3 &&
            <span style={styles.buttonStyle} className="border-top py-2">
              <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                <Flex justifyContent="center" alignItems="center" gap="5px">
                  <UnorderedListOutlined />
                  See all options
                </Flex>
              </button>
            </span>
          }
        </div> */}
        <div style={styles.buttoneBubble}>
          {template?.buttons?.buttons?.length > 0 && template?.buttons?.buttons?.map((item, index) => {
            // Display the first 2 QUICK_REPLY buttons and all other buttons
            if (item?.type === "QUICK_REPLY" && index < 2) {
              return (
                <span style={styles.buttonStyle} className="border-top py-2" key={index}>
                  <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                    <Flex justifyContent='center' alignItems='center' gap='5px'>
                      <FontAwesomeIcon icon={faReply} />
                      <span>{item?.text}</span>
                    </Flex>
                  </button>
                </span>
              );
            } else if (item?.type !== "QUICK_REPLY") {
              return (
                <span style={styles.buttonStyle} className="border-top py-2" key={index}>
                  <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                    <Flex justifyContent='center' alignItems='center' gap='5px'>
                      {item?.type === "URL" && <FontAwesomeIcon icon={faUpRightFromSquare} />}
                      {item?.type === "PHONE_NUMBER" && <FontAwesomeIcon icon={faPhone} />}
                      <span>{item?.text}</span>
                    </Flex>
                  </button>
                </span>
              );
            }
            return null;
          })}

          {template?.buttons?.buttons?.filter(item => item?.type === "QUICK_REPLY").length > 3 &&
            <span style={styles.buttonStyle} className="border-top py-2">
              <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                <Flex justifyContent="center" alignItems="center" gap="5px">
                  <UnorderedListOutlined />
                  See all options
                </Flex>
              </button>
            </span>
          }
        </div>

      </div>
    </div>
  )
}

export default NewMobilePreview
