import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import 'react-dotenv';
export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	twoFactor: false,
	redirect: '',
	user: [],
	token: null,
	isImpersonating: false
}

const api = process.env.REACT_APP_API_PATH;
// console.log(api);
export const signIn = createAsyncThunk('login', async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await axios.post(`${api}login`, { email, password })

		// console.log(response)
		return response.data
	} catch (err) {

		return rejectWithValue(
			err.response.data.emailError ? err.response.data.emailError :
				err.response.data.error ? err.response.data.error :
					err.response.data.passwordError ? err.response.data.passwordError :
						err.response.data.ipAuthError ? err.response.data.ipAuthError :
							err.response.data.message
		);

	}
})

// Impersonate User Async Thunk
// Impersonate User Async Thunk
export const impersonateUser = createAsyncThunk('auth/impersonateUser', async ({ userId, token,adminId }, { rejectWithValue }) => {  // Destructure the first argument as an object
	try {
		const response = await axios.post(`${api}impersonate/${userId}`,{adminId},
			{
				headers: {
					Authorization: `Bearer ${token}`,  // Pass token in Authorization header
				},
			}
		);
		return response.data;  // New user data when impersonating
	} catch (error) {
		return rejectWithValue(error.response?.data || 'Something went wrong');
	}
}
);


// Stop Impersonation Async Thunk
export const stopImpersonation = createAsyncThunk('auth/stopImpersonation', async (token, { rejectWithValue }) => {
	try {
		// console.log(token)
		const response = await axios.post(`${api}impersonate/stop`, {},
			{
				headers: {
					Authorization: `Bearer ${token}`,  // Pass token in Authorization header
				},
			});
		return response.data;  // Return admin data after stopping impersonation
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});





export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false

			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		logout: (state, action) => {
			state.loading = false
			state.token = null
			state.user = []
			state.redirect = '/login'
			localStorage.removeItem(AUTH_TOKEN);
			state.twoFactor = false
			state.isImpersonating = false;
		},
		validateTwoFector: (state) => {
			state.twoFactor = false
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				if (action?.payload?.user?.two_fector_auth === 'true') {
					state.twoFactor = true
				}
				state.loading = false
				state.token = action.payload.token
				state.user = action.payload.user
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})



			// Impersonate User Thunk
			.addCase(impersonateUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(impersonateUser.fulfilled, (state, action) => {
				state.user = action.payload.user;  // Update user data with impersonated user
				state.token = action.payload.token;
				state.isImpersonating = true;  // Set impersonation flag
				state.loading = false;
			})
			.addCase(impersonateUser.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})


			// Stop Impersonation Thunk
			.addCase(stopImpersonation.pending, (state) => {
				state.loading = true;
			})
			.addCase(stopImpersonation.fulfilled, (state, action) => {
				state.user = action.payload.user;  // Restore admin user
				state.token = action.payload.token;
				state.isImpersonating = false;  // Reset impersonation flag
				state.loading = false;
			})
			.addCase(stopImpersonation.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			});
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	logout,
	validateTwoFector,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer