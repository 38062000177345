import React, { useState } from 'react';
import { Dropdown, message, Modal, Tooltip, Upload } from 'antd';
import { DesktopOutlined, PlayCircleOutlined, PaperClipOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import Flex from 'components/shared-components/Flex';
import { SPACER, FONT_SIZES } from 'constants/ThemeConstant'
import MediModel from '../Campaign/MediModel';
import { useMyContext } from 'Context/MyContextProvider';
import axios from 'axios';

const Icon = styled.div(() => ({
    fontSize: FONT_SIZES.LG
}))

const MenuItem = (props) => (
    <Flex as="a" href={props.path} alignItems="center" gap={SPACER[2]}>
        <Icon>{props.icon}</Icon>
        <span>{props.label}</span>
    </Flex>
)

export const UploadDrawer = ({ GetMedia, disabled,setDisable }) => {
    const {uploadMediaApi ,waToken} = useMyContext()
    const [openMediaModel, setOpenMediaModel] = useState(false);
    const props = {
        accept: '.jpg,.jpeg,.png,.mp4,.3gp,.pdf',  // Specify accepted file types
        beforeUpload: (file) => {
            const isValidFileType = ['image/jpeg', 'image/png', 'video/mp4', 'video/3gp', 'application/pdf'].includes(file.type);
            if (!isValidFileType) {
                message.error('You can only upload JPG, JPEG, PNG, MP4, 3GP, and PDF files!');
            } else {
                UploadMedia(file)
                return false; 
            }
        },
    };
    const UploadMedia = async (file) => {
        const MAX_FILE_SIZE_MB = 15;
        if (file) {
            try {
                // Loop through each file in selectedMedia
                const type = file.type; // Get the file type (e.g., image/jpeg, application/pdf)
                const name = file.name; // Get the file name
                const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
                if (fileSizeMB > MAX_FILE_SIZE_MB) {
                    message.error(`File "${name}" exceeds the maximum size of ${MAX_FILE_SIZE_MB} MB.`);
                    return; // Skip this file and move to the next
                }
                setDisable(true)
                // Create a FormData object
                const formData = new FormData();
                formData.append('file', file);
                formData.append('type', type);
                formData.append('filename', name);
                formData.append('messaging_product', 'whatsapp');

                // Upload each file
                const responseMeta = await axios.post(`${uploadMediaApi}`, formData, {
                    headers: { 'Authorization': 'Bearer ' + waToken },
                });
                const mediaId = responseMeta.data.id;
                GetMedia(mediaId, file.name, null)
                //console.log(file.name, mediaId);
            } catch (error) {
                console.error('Error uploading media:', error);
            }
        } else {
            console.error('No media selected for upload.');
        }
    }
    const showMediaModel = async () => {
        setOpenMediaModel(true);
    };
    const handleCancelMediaModel = () => {
        setOpenMediaModel(false);
    };
    const HandleUploadImage = async (path, name, link) => {
        if (path) {
            GetMedia(path, name, link)
            setOpenMediaModel(false)
        }
    };
    const items = [
        {
            key: 'From Media',
            label: <span onClick={() => showMediaModel()}><MenuItem label="From Media" icon={<PlayCircleOutlined />} /></span>,
        },
        {
            key: 'From Device',
            label: <Upload {...props} showUploadList={false}><MenuItem label="From Device" icon={<DesktopOutlined />} /></Upload>
        },
    ]

    return (
        <>
            {/* model for media file  */}
            <Modal
                title="Select Media"
                open={openMediaModel}
                onCancel={handleCancelMediaModel}
                footer={null}
                width={1200}
            >
                <MediModel HandleUploadImage={HandleUploadImage} />
            </Modal>
            <Tooltip title="">
                <Dropdown placement="topLeft" menu={{ items }} trigger={["click"]} disabled={disabled}>
                    <PaperClipOutlined />
                </Dropdown>
            </Tooltip>
        </>
    );
}

export default UploadDrawer
