import React, { useEffect, useRef, useState } from 'react'
import { Card, Table, Input, Button, Modal, Row, Col, Form, Upload, message } from 'antd';
import {  DeleteOutlined, SearchOutlined, UploadOutlined, CloudDownloadOutlined, FileExcelFilled } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import {  useParams } from "react-router-dom";
import utils from 'utils'
import { useMyContext } from 'Context/MyContextProvider';
import axios from 'axios';
import * as XLSX from 'xlsx';

const { confirm } = Modal;
const ContactList = () => {
    const { api, authToken,HandleExport } = useMyContext();

    const [ContactList, setContactList] = useState([])
    const [contactListData, setContactListData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [importedData, setImportedData] = useState([])
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const [contactName, setContactName] = useState('')
    const [number, setNumber] = useState('')
    const [email, setEmail] = useState('')
    const [location, setLocation] = useState('')
    const { id } = useParams();
    const addContactRef = useRef();
    const resetImport = useRef();
    const GroupData = async () => {
        try {
            const response = await axios.get(`${api}edit-group/${id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            // console.log(response.data.groups.contacts)
            setName(response.data.groups.name);
            setDescription(response.data.groups.description);
            setDescription(response.data.groups.description);
            const data = (response.data.groups.contacts).reverse();
            setContactList(data);
            setContactListData(data);

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        GroupData()
    }, [])

 


    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Mobile Number',
            dataIndex: 'number',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'number')
        },
        {
            title: 'Email ID',
            dataIndex: 'email',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'email')
        },
        {
            title: 'Location',
            dataIndex: 'location',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'location')
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '1rem' }}>
                    <span className='d-flex h4 cursor-pointer' onClick={() => showConfirm(item.id)}>
                        <DeleteOutlined />
                    </span>
                </Flex>
            )
        }
    ];


    function showConfirm(id) {
        confirm({
            title: 'Do you Want to delete this contact?',
            content: "By deleting the contact, it will be permanently removed from the system and can't be restored.",
            onOk() {
                HandleDeleteContact(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    function showConfirmForMulti() {
        confirm({
            title: 'Do you Want to delete these contacts?',
            content: "By deleting the contacts, it will be permanently removed from the system and can't be restored.",
            onOk() {
                HandleMultiDelete()
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const rowSelection = {
        onChange: (key, rows) => {
            setSelectedRows(rows)
            setSelectedRowKeys(key)
        }
    };

    const onSearch = e => {
        const value = e.currentTarget.value
        const searchArray = e.currentTarget.value ? ContactList : contactListData
        const data = utils.wildCardSearch(searchArray, value)
        setContactList(data)
        setSelectedRowKeys([])
    }


    const props = {
        beforeUpload: file => {
            setImportedData([])
            const reader = new FileReader();
            const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isExcel) {
                message.error('You can only upload Excel files (.xlsx)!');
                return false;
            }
            reader.onload = (event) => {
                const binaryString = event.target.result;
                const workbook = XLSX.read(binaryString, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                const filteredData = jsonData.slice(1).filter(row => row.length > 0);
               // console.log(filteredData);
                setImportedData(filteredData);
            };

            reader.readAsBinaryString(file);
            return false;
        },
    };

    const HandleImport = async () => {
        // console.log(importedData)
        // return
        try {
            const response = await axios.post(`${api}import-group/${id}`, { importedData }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
           
            if (response.data.status) {
                GroupData();
                resetImport.current.resetFields();
            }
        } catch (error) {
            // setConfirmLoading(false);
            // handleCancel()
            console.log(error);
        }
    }
    const HandleDeleteContact = async (deleteid) => {

        try {
            const response = await axios.delete(`${api}delete-contact/${deleteid}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {
                GroupData();
            }
        } catch (error) {
            // setConfirmLoading(false);
            // handleCancel()
            console.log(error);
        }
    }
    const HandleMultiDelete = async () => {
        try {
            const response = await axios.delete(`${api}multi-delete-contacts`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
                data: {
                    ids: selectedRowKeys
                }
            });
            console.log(response);
            if (response.data.status) {
                GroupData();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const HandleSubmit = async () => {
        try {
            const response = await axios.post(`${api}create-contact/${id}`, { group_id: id, number, name: contactName, email: email, location: location }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {

                GroupData();
                addContactRef.current.resetFields();
            }
        } catch (error) {
            console.log(error);
        }
    }

    // const handleDownload = () => {
    //     // Replace 'sample_file.xlsx' with the actual filename and path of your sample file
    //     const sampleFileUrl = 'https://login.smsforyou.biz/assets/images/smsforyou.png';

    //     // Create a temporary anchor element
    //     const link = document.createElement('a');
    //     link.href = sampleFileUrl;
    //     link.download = 'sample_file.xlsx'; // Rename the downloaded file if needed
    //     document.body.appendChild(link);

    //     // Trigger the click event to start the download
    //     link.click();

    //     // Clean up the temporary anchor element
    //     document.body.removeChild(link);
    // };

    return (
        <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Row gutter={24}>
                    {/* group detais */}
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Card title="Edit Group Detail">
                            <Row>
                                <Col span={24}>
                                    <Form
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 10 }}
                                        layout="horizontal"
                                    >
                                        <Form.Item label="Group Name" className=" my-3">
                                            <Input type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="Group Name"
                                            />
                                        </Form.Item>
                                        <Form.Item label="Group Description" className=" my-3">
                                            <Input type="text"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                placeholder="Group Description"
                                            />
                                        </Form.Item>
                                        <Form.Item label=" " className=" my-3">
                                            <Button type="primary">Update</Button>
                                        </Form.Item>

                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    {/* contact list  */}
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Card title="Contacts">
                            <Flex alignItems="center" justifyContent="space-between" mobileFlex={false}>
                                <Flex className="mb-1" mobileFlex={false}>
                                    <div className="mr-md-3 mb-3">
                                        <Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                                    </div>
                                    {selectedRowKeys?.length > 0 &&
                                        <div className="mr-md-3 mb-3 d-flex align-items-center border rounded px-2 py-1 cursor-pointer" onClick={() => showConfirmForMulti()}>
                                            <span className='h4 m-0'><DeleteOutlined /></span>
                                        </div>
                                    }
                                    {/*                                 
                                    <div className="mb-3">
                                        <Select
                                            showSearch
                                            defaultValue="Select Action"
                                            className="w-100"
                                            style={{ minWidth: 180 }}
                                            onChange={handleShowCategory}
                                            placeholder="Category"
                                        >
                                            <Option value="Action" disabled>Select Action</Option>
                                            <Option value="Delete">Delete</Option>
                                        </Select>
                                    </div> */}

                                </Flex>
                                <div className="mb-3 text-right">
                                    <Button icon={<CloudDownloadOutlined />} onClick={() => HandleExport('export-group-contacts', name+'_contacts.xlsx')}>Export Contacts</Button>
                                </div>
                            </Flex>
                            <div className="table-responsive">
                                <Table
                                    columns={tableColumns}
                                    dataSource={ContactList}
                                    rowKey='id'
                                    rowSelection={{
                                        selectedRowKeys: selectedRowKeys,
                                        type: 'checkbox',
                                        preserveSelectedRowKeys: false,
                                        ...rowSelection,
                                    }}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Col>

            {/* new or update contact  */}
            <Col xs={24} sm={24} md={24} lg={12}>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Card title={`Import Contact`}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 10 }}
                                        layout="horizontal"
                                        ref={resetImport}
                                        onFinish={HandleImport}
                                    >
                                        <Form.Item
                                            label="Choose File"
                                            name="Choose File"
                                            rules={[{ required: true, message: 'Please select your file!' }]}
                                            className=" my-3">
                                            <Upload {...props}
                                                accept=".xlsx"
                                            >
                                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item label=" " className="">
                                            <p className='text-light mt-2'>Please choose excel(.xlsx) file only</p>
                                            <p>Download Sample File to import
                                                <a href='https://smsforyou.biz/sample_assets/sample_group.xlsx' className='cursor-pointer h3 ml-2' download>
                                                    <FileExcelFilled />
                                                </a>
                                            </p>
                                            <Button type="primary" htmlType='submit'>Upload</Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Card title={`New Contact`}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 10 }}
                                        layout="horizontal"
                                        ref={addContactRef}
                                        onFinish={HandleSubmit}
                                    >
                                        <Form.Item
                                            label=" Name"
                                            name=" Name"
                                            className=" my-3"
                                            rules={[{ message: 'Please enter your name!' }]}
                                        >
                                            <Input type="text"
                                                value={contactName}
                                                onChange={(e) => setContactName(e.target.value)}
                                                placeholder=" Name"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Mobile Number"
                                            name="Mobile Number"
                                            className=" my-3"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter  Mobile Number!',
                                                },
                                            ]}>
                                            <Input type="text"
                                                value={number}
                                                onChange={(e) => setNumber(e.target.value)}
                                                placeholder="Mobile Number"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label=" Email ID"
                                            name=" Email ID"
                                            className=" my-3"
                                            rules={[
                                                {
                                                    message: 'Please enter campaign name!',
                                                },
                                            ]}>
                                            <Input type="text"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Email ID"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Location"
                                            name="Location"
                                            className=" my-3"
                                            rules={[
                                                {
                                                    message: 'Please enter campaign name!',
                                                },
                                            ]}>
                                            <Input type="text"
                                                value={location}
                                                onChange={(e) => setLocation(e.target.value)}
                                                placeholder="Location"
                                            />
                                        </Form.Item>
                                        <Form.Item label=" " className=" my-3">
                                            <Button type="primary" htmlType='submit'>Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                </Row>
            </Col>


        </Row>

    )
}

export default ContactList
