import { Alert, Button, Card, Col, DatePicker, Form, Input, List, Modal, Progress, Radio, Result, Row, Select, Typography, Upload, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useMyContext } from 'Context/MyContextProvider';
import { CloudUploadOutlined, RightOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import MediModel from './MediModel';
import Flex from 'components/shared-components/Flex';
import { SPACER } from 'constants/ThemeConstant';
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
import * as XLSX from 'xlsx';
import NewMobilePreview from './newMobilePreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { toLower } from 'lodash';

const { Option } = Select;
const { Text } = Typography;
const ExcelCampaign = () => {

  const { UserData, api, authToken, messagesApi, waToken, templates, Permisson, navigate, calculateRequredBalance } = useMyContext()


  useEffect(() => {
    if (!Permisson?.includes('View Campaign')) {
      navigate('404')
    }
  }, [Permisson]);

  const [templateList, setTemplateList] = useState([]);
  const [componentSize, setComponentSize] = useState('small');
  const [number, setNumber] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [numberError, setNumberError] = useState(false);
  const [requestType, setRequestType] = useState('template');
  const [dynamicValues, setDynamicValues] = useState([]);
  const [dynamicButtonUrl, setDynamicUrlButton] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [newMesaage, setNewMessage] = useState('');
  const [name, setName] = useState('');
  const [templatePreview, setTemplatePreview] = useState([])
  const [mediaLink, setMediaLink] = useState('')
  const [mediaName, setMediaName] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const [mediaType, setMediaType] = useState('Media')
  const [template, setTemplate] = useState({
    header: null,
    body: null,
    footer: null,
    buttons: null
  });
  const [templateKey, setTemplateKey] = useState(0);
  const [templateInputs, setTemplateInputs] = useState([]);
  const [templateUrlInputs, setTemplateUrlInputs] = useState([]);
  const [urlButtons, setUrlButtons] = useState([]);
  const [numbers, SetNumbers] = useState([]);

  const [singleLineNumber, setSingleLineNumber] = useState([])
  const [userStatus, setUerStatus] = useState(false)
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [templateCategory, setTemplateCategory] = useState('MARKETING');
  const [blockNumbers, setBlockNumbers] = useState([]);
  const [templateLanguage, setTemplateLanguage] = useState('');
  const [openMediaModel, setOpenMediaModel] = useState(false);
  const [openScheduleModel, setOpenSchedule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customLanguage, setCustomLanguage] = useState('en');
  const [importedData, setImportedData] = useState([])
  const [disableUpload, setDisableUpload] = useState(true);
  const [fileList, setFileList] = useState([]);

  const GetBlockNumber = async () => {
    try {
      const response = await axios.get(`${api}get-block-numbers/${UserData.id}`, {
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
      const serverBlocked = response.data.serverBlocked
      const userBlocked = response.data.serverBlocked

      const formatNumber = (number) => {
        const digits = number.toString();
        if (digits.length === 12) {
          return digits;
        } else if (digits.length === 10) {
          return '91' + digits;
        } else {
          return null;
        }
      };
      // Extract and format numbers from serverBlocked
      const serverNumbers = serverBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);

      // Extract and format numbers from userBlocked
      const userNumbers = userBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);


      // Combine both arrays into one
      const total = [...serverNumbers, ...userNumbers];
      setBlockNumbers([...new Set(total)]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    GetBlockNumber()
  }, [])



  useEffect(() => {
    setTemplateList(templates)
  }, [templates])




  useEffect(() => {
    // console.log(number)
    if (number) {
      setNumberError(false)
    }
  }, [number])


  const onChange = e => {
    setRequestType(e.target.value);
  };

  const HandleTemplate = (value) => {
    setMediaLink('');
    setTemplateName(value);
    setTemplateKey(prevKey => prevKey + 1);
    const template = templates?.find((item) => item.name === value);
    // console.log(template)
    setTemplateLanguage(template?.language)
    setTemplatePreview(template);
    const { header, body, footer, buttons } = extractTemplateSections(template);
    setTemplate({ header, body, footer, buttons });

    const templateBodyDynamicValue = body?.text?.match(/{{\d+}}/g);
    if (templateBodyDynamicValue) {
      const inputs = generateInputsForDynamicValues(templateBodyDynamicValue);
      setTemplateInputs(inputs);
    } else {
      setTemplateInputs([]);
    }

    if (buttons?.buttons) {
      const urlInputs = generateUrlInputs(buttons.buttons);
      setTemplateUrlInputs(urlInputs);
    } else {
      setTemplateUrlInputs([]);
    }
  }

  const extractTemplateSections = (template) => {
    let header = null, body = null, footer = null, buttons = null;
    template?.components?.forEach(section => {
      switch (section.type) {
        case "HEADER":
          header = section;
          break;
        case "BODY":
          body = section;
          break;
        case "FOOTER":
          footer = section;
          break;
        case "BUTTONS":
          buttons = section;
          break;
        default:
          // Handle any other section types if needed
          break;
      }
    });
    return { header, body, footer, buttons };
  }

  const generateInputsForDynamicValues = (dynamicValues) => {
    return dynamicValues.map((placeholder, index) => (
      <Input
        key={index}
        type="text"
        placeholder={`Enter value for ${placeholder}`}
        onChange={(e) => handleInputChange(index, e.target.value)}
      />
    ));
  }

  const generateUrlInputs = (urlButtons) => {
    return urlButtons.map((item, index) => {
      // console.log(item)
      const isDisabled = !item.hasOwnProperty('example');
      return (
        <div key={index}>
          {!isDisabled ?
            <>
              <Link>{item.url}</Link>
              <Input
                type="text"
                // value={isDisabled ? item.url : ''}
                placeholder={`Your "${item.text}" Button Value`}
                onChange={(e) => handleUrlButton(index, e.target.value)}
                disabled={isDisabled}
              />
            </>
            :
            <Input
              type="text"
              value={item.url}
              onChange={(e) => handleUrlButton(index, e.target.value)}
              disabled={isDisabled}
            />
          }
        </div>
      );
    });
  }



  const handleInputChange = (index, value) => {
    setDynamicValues(prevValues => {
      const updatedValues = [...prevValues];
      updatedValues[index] = value;
      return updatedValues;
    });

  };

  const handleUrlButton = (index, value) => {
    setDynamicUrlButton(prevValues => {
      const updatedValues = [...prevValues];
      updatedValues[index] = value;
      return updatedValues;
    });
  };

  const HandleDynamicValues = (templatePreview) => {
    if (importedData.length > 0) {
      const text = templatePreview?.components[1]?.text;
      let updatedText = text;
      importedData[0]?.value?.forEach((item, index) => {
        updatedText = updatedText?.replace(`{{${index + 1}}}`, item);
      });

      setNewMessage(updatedText); // Log the updated text array
    }
  }
  useEffect(() => {
    HandleDynamicValues(templatePreview)
    // console.log(importedData)
  }, [templatePreview, importedData])





  useEffect(() => {
    if (requestType === 'template') {
      setCustomMessage('')
    } else {
      setTemplatePreview([])
      setTemplateInputs([]);
      setDynamicValues([]);
      setTemplateUrlInputs([]);
      setDynamicUrlButton([]);
      setTemplate({
        header: null,
        body: null,
        footer: null,
        buttons: null
      });

    }
  }, [requestType])





  useEffect(() => {
    if (numbers?.length > 0) {
      const numbersWithNewlines = numbers?.join('\n');
      setSingleLineNumber(numbersWithNewlines)
    }
    // console.log(numbers)
  }, [numbers])




  const showModal = async () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };


  // for media model 

  const showMediaModel = async () => {
    setOpenMediaModel(true);
  };
  const handleCancelMediaModel = () => {
    setOpenMediaModel(false);
  };

  const handleCancelScheduleModel = () => {
    setOpenSchedule(false);
  };


  useEffect(() => {
    const filteredList = templateCategory
      ? templates.filter(item => item.category === templateCategory)
      : templates;

    setTemplateList(filteredList);
  }, [templateCategory, templates]);




  const buttonStyle = {
    background: 'linear-gradient(116deg,  #40e495, #30dd8a, #2bb673)',
    color: 'white',
    border: 'none'
  }

  const HandleUploadImage = async (path, name) => {
    if (path) {
      setMediaLink(path)
      setMediaName(name)
      setOpenMediaModel(false)
    }
  };


  const HandleScheduleTime = (value) => {
    const { $d } = value;
    const year = $d.getFullYear();
    const month = $d.getMonth() + 1;
    const day = $d.getDate();
    const hours = $d.getHours();
    const minutes = $d.getMinutes();
    // const seconds = $d.getSeconds();
    setScheduleDate(`${year}-${month}-${day}`);
    setScheduleTime(`${hours}:${minutes}:00`);
  }


  const filterNumbers = (numbers, blockNumbers) => {
    const uniqueNumbersSet = [...new Set(numbers)];
    // const numbersArray = number.split(',').map(number => number.trim());
    const numbersArray = number.split(',').map(item => item.trim()).flatMap(item => item.split('\n')).map(number => number.trim());
    // console.log(numbersArray);return
    const combinedNumbers = [...uniqueNumbersSet, ...numbersArray];
    // console.log(combinedNumbers)
    const finalFilteredNumbers = combinedNumbers.filter(number => {
      const num = parseInt(number);
      return !isNaN(num) && (num.toString().length === 10 || num.toString().length === 12);
    }).map(number => {
      const num = parseInt(number);
      return num.toString().length === 10 ? '91' + num : number;
    });
    const filteredNumbers = finalFilteredNumbers.filter(finalNumber => !blockNumbers.includes(finalNumber));
    return filteredNumbers;
  };
  // final submit 
  const HandleSchedule = async () => {
    setLoading(true)
    const filteredNumbers = filterNumbers(numbers, blockNumbers);
    // return
    const campaignResponse = await axios.post(`${api}schedule-campaign/${UserData?.id}`, {
      name,
      user_id: UserData.id,
      numbers: filteredNumbers,
      campaign_type: requestType,
      custom_text: customMessage,
      template_name: templateName,
      header_type: template.header?.format,
      header_media_url: mediaLink,
      body_values: dynamicValues,
      button_type: template?.buttons?.buttons[0].type,
      button_value: dynamicButtonUrl,
      schedule_date: scheduleDate,
      schedule_time: scheduleTime,
    }, {
      headers: {
        Authorization: "Bearer " + authToken,
      }
    });
    // console.log(campaignResponse)
    if (campaignResponse.data.status) {
      navigate('/campaign')
    }
    setLoading(false)
  }


  const styles = {
    textarea: {
      width: '100%',
      border: '1px solid #ced4da',
      borderRadius: '.5rem',
      outline: 'none',
    },
  };

  useEffect(() => {
    setImportedData([])
    setFileList([]);
    setDisableUpload(true)
  }, [template]);

  //import
  const props = {
    onRemove: file => {
      setImportedData([]);
      setFileList([]);
      setDisableUpload(true)
    },

    beforeUpload: file => {
      setImportedData([])
      const reader = new FileReader();
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        message.error('You can only upload Excel files (.xlsx)!');
        return false;
      }
      reader.onload = (event) => {
        const binaryString = event.target.result;
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        let dataLength = jsonData[0].length;
        let expectedLength = templateInputs.length + 1;

        if (dataLength !== expectedLength) {
          setDisableUpload(true)
          message.error('Invalid file column!');
          return false;
        } else {
          setDisableUpload(false)
          //console.log(jsonData)
          setFileList([file]);
          setImportedData(jsonData);
        }
      };

      reader.readAsBinaryString(file);
      return false;
    },
  };

  const HandleImport = () => {
    const numbersArray = importedData.map(item => item[0]);
    SetNumbers(numbersArray);
    const updatedMobileNumbers = importedData.map(item => ({
      number: item[0],
      value: item.length > 1 ? item.slice(1) : []
    }));
    setImportedData(updatedMobileNumbers);
    setDisableUpload(true)
  };



  // send message code start from here 
  const generateContent = (type) => {
    switch (type) {
      case 'IMAGE':
        return { image: { id: mediaLink } };
      case 'VIDEO':
        return { video: { id: mediaLink } };
      case 'DOCUMENT':
        return { document: { id: mediaLink } };
      default:
        return {};
    }
  };
  const [isSending, setIsSending] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [rateLimit, setRateLimit] = useState();
  // Function to generate the payload for each message
  const generatePayload = (item, requestType, templateName, templateLanguage, template, customMessage, dynamicValues, dynamicButtonUrl) => {
    return {
      messaging_product: "whatsapp",
      to: item,
      type: requestType === "template" ? "template" : "text",
      ...(requestType === "template" ? {
        template: {
          name: templateName,
          language: { code: templateLanguage },
          components: [
            ...(template.header && template.header?.format !== "TEXT" ? [
              {
                type: "header",
                parameters: [
                  {
                    type: (() => {
                      switch (template.header?.format) {
                        case "IMAGE":
                          return "image";
                        case "VIDEO":
                          return "video";
                        case "DOCUMENT":
                          return "document";
                        default:
                          return "text";
                      }
                    })(),
                    ...generateContent(template.header?.format, template.header?.example?.header_handle[0]),
                  },
                ],
              },
            ] : []),
            {
              type: "body",
              parameters: dynamicValues && dynamicValues.map((item) => ({ type: "text", text: item })),
            },
            ...(template?.buttons?.buttons.length < 3 && template?.buttons && template?.buttons?.buttons[0] && dynamicButtonUrl?.length ? [
              {
                type: "button",
                sub_type: (() => {
                  switch (template?.buttons?.buttons[0].type) {
                    case "QUICK_REPLY":
                      return "quick_reply";
                    case "URL":
                      return "url";
                    case "PHONE_NUMBER":
                      return "url";
                    default:
                      return "";
                  }
                })(),
                ...(template?.buttons?.buttons[0].type === 'URL' && template?.buttons?.buttons[0]?.example ? {
                  index: 0
                } : {
                  index: 1
                }),
                ...(template?.buttons?.buttons[0]?.example && {
                  parameters: (() => {
                    switch (template?.buttons?.buttons[0].type) {
                      case "QUICK_REPLY":
                        return [{ type: "payload", payload: "btntwo" }];
                      case "URL":
                        return dynamicButtonUrl.map((item) => ({ type: "text", text: item }));
                      default:
                        return [];
                    }
                  })()
                }),
              },
            ] : []),
          ]
        },
      } : {
        text: { preview_url: false, body: customMessage },
      })
    }
  };


  // Function to handle the rate-limited message sending
  const sendMessageWithRateLimit = async (messagePayloads, messagesApi, waToken, campaignData, api, authToken) => {
    // const customRateLimit = rateLimit > 0 ? rateLimit : 20; // messages per second
    const customRateLimit = 20; // messages per second
    const interval = 1000 / customRateLimit; // milliseconds
    const reportData = [];
    const totalMessages = messagePayloads.length;
    let sentMessages = 0;
    const sendBatch = async (batch) => {
      await Promise.all(batch.map(async (payload) => {
        const mobileNumber = payload.to; // Store the mobile number for reporting

        try {
          const res = await axios.post(messagesApi, payload, {
            headers: { Authorization: `Bearer ${waToken}` },
          });

          if (res.data.messages[0].message_status === 'accepted' || res.data.messages[0]?.id) {
            // Collect the report data
            reportData.push({
              campaign_id: campaignData.id,
              message_id: res.data.messages[0].id,
              mobile_number: mobileNumber,
            });
          }
        } catch (error) {
          console.error('Error sending message:', error);
          // Optionally, handle individual error reporting here
        }
        sentMessages++;
        const currentProgress = (sentMessages / totalMessages) * 100;
        setProgress(currentProgress);
      }));
    };

    const processMessages = async () => {
      for (let i = 0; i < messagePayloads.length; i += customRateLimit) {
        const batch = messagePayloads.slice(i, i + customRateLimit);
        await sendBatch(batch);

        // Wait for the next interval before sending the next batch
        await new Promise(resolve => setTimeout(resolve, interval));
      }

      // After all messages are processed, store the campaign reports
      setIsSending(false); // Update sending status
      await storeCampaignReports(reportData, campaignData, api, authToken);
    };

    setIsSending(true); // Start sending process
    processMessages();
  };



  const storeCampaignReports = async (reportData, campaignData, api, authToken) => {
    try {
      const response = await axios.post(`${api}create-campaign-report`, {
        campaign_id: campaignData.id,
        reports: reportData,
      }, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (response.data.status) {
        // successFunction(true);
        // console.log('Campaign reports stored successfully:', response.data.message);
      } else {
        console.error('Failed to store campaign reports:', response.data.message);
      }
    } catch (error) {
      console.error('Error storing campaign reports:', error);
    }
  };


  const [showProcessingModal, setShowProcessingModal] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isSending) {
        e.preventDefault();
        e.returnValue = ''; // For most browsers
        return ''; // For older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSending]);

  useEffect(() => {
    const handlePopstate = (e) => {
      if (isSending) {
        e.preventDefault();
        setShowProcessingModal(true);
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [isSending]);

  const [groupsKey, setGroupsKey] = useState(0);

  const resetCampaign = () => {
    setProgress()
    setIsModalVisible(false)
    setSingleLineNumber([])
    setNumber('')
    setGroupsKey(prevKey => prevKey + 1);
  }

  const conicColors = {
    '0%': '#16702F',
    // '50%': '#ffe58f',
    '100%': '#34B55A',
  };

  const listData = [
    'Select your messaging category and template',
    'System will generate Alert for number of columns required in Excel',
    'The first column should always have mobile numbers',
    'Create Excel file with data only (i.e. without headers)',
    'The number of columns in excel other than mobile number should match the total number of variables in template',
    'Message on Blocked Mobile Numbers will not be sent',
    'Avoid using Special Characters in the variable contents',
    'Sample message preview for first row of excel will be available after submitting file'
  ];


  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };
  const getErrorMessage = () => {
    if (typeof error === 'string') {
      return error;
    } else if (isJSON(JSON.stringify(error))) {
      return JSON.stringify(error);
    }
    return 'An unexpected error occurred';
  };

  // User status and balance validation function
  const validateUserAndBalance = async (numbersLength) => {
    const isEnoughBalance = calculateRequredBalance(numbersLength, toLower(templateCategory));
    if (isEnoughBalance === false) {
      showModal()
      message.error('Not enough balance in your account');
      // setError('Not enough balance in your account');
      // scrollToTop();
      return false;
    }
    return true;
  };

  // Main handler function
  const HandleApiTest = async () => {
    if (!requestType || (requestType === 'template' && templateName === '')) {
      setError('You have to select a template first');
      return;
    } else {
      const filteredNumbers = filterNumbers(numbers, blockNumbers);
      if (!await validateUserAndBalance(filteredNumbers.length)) {
        console.log(validateUserAndBalance)
        showModal()
        return; // Validation failed, error already set
      }
      if (name && filteredNumbers.length > 0 && requestType && (templateName || customMessage)) {
        try {
          setLoading(true);
          setIsModalVisible(true);
          const UserResponse = await axios.get(`${api}chek-user/${UserData.id}`, {
            headers: { 'Authorization': `Bearer ${authToken}` },
          });
          setUerStatus(UserResponse.data.status);

          if (UserResponse.data.status === false) {
            showModal();
            return false;
          }

          // Create campaign
          const campaignData = await axios.post(`${api}create-campaign`, {
            name,
            user_id: UserData.id,
            templateName: templateName ? templateName : `Custom Message : ${customMessage}`,
          }, {
            headers: { Authorization: `Bearer ${authToken}` },
          }).then(response => response.data.campaign);

          // Generate message payloads
          const messagePayloads = importedData.map(item => generatePayload(
            item?.number, requestType, templateName, templateLanguage, template, customMessage, item?.value, dynamicButtonUrl
          ));

          // Send messages with rate limiting and reporting
          await sendMessageWithRateLimit(messagePayloads, messagesApi, waToken, campaignData, api, authToken);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      } else {
        setError('One or more fields required');
      }
    }
  };

  return (
    <>
      {/* for belance alert  */}
      <Modal
        title="Insufficient Credits"
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <Result
          title="Insufficient credits to run this campaign"
          extra={
            <Link to={`/users/manage/${UserData.id}/billing`}>
              <Button type="primary" key="console">
                Add Credits
              </Button>
            </Link>
          }
        />
      </Modal>
      {/* schedule model  */}
      <Modal
        title="Schedule Campaign"
        open={openScheduleModel}
        onCancel={handleCancelScheduleModel}
        footer={null}
      >
        <Result
          status="success"
          title="Select date and Time"
          extra={
            <>
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                onChange={(value) => HandleScheduleTime(value)} />
              <Link to={``}>
                <Button type="primary" key="console" onClick={() => HandleSchedule()}>
                  Schedule Campaign
                </Button>
              </Link>
            </>
          }
        />
      </Modal>
      {/* model for media file  */}
      <Modal
        title="Select Media"
        open={openMediaModel}
        onCancel={handleCancelMediaModel}
        footer={null}
        width={1200}
      >
        <MediModel HandleUploadImage={HandleUploadImage} />
      </Modal>

      {/* progress model */}
      <Modal
        title={
          <div style={{ textAlign: 'center', width: '100%' }}>
            {progress === 100 ? "Campaign Execution Completed" : "Campaign Execution Under Process"}
          </div>
        }
        open={isModalVisible}
        footer={null}
        closable={false}
      >
        <div className="d-flex justify-content-center align-items-center flex-column mt-3">
          <Progress type="circle" percent={Math.round(progress)} strokeColor={conicColors} />
          {progress === 100 && (
            <div className="d-flex justify-content-center mt-3">
              <Button className="mr-2" onClick={() => resetCampaign()}>New Campaign</Button>
              <Button onClick={() => navigate('/campaign-history')}>View Report</Button>
            </div>
          )}
        </div>
      </Modal>

      <Row gutter={12}>
        {Permisson?.includes('View Campaign') &&
          <>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card title="Excel Campaign">
                <Col span={24}>
                {error &&
                  <Col span={24}>
                    <motion.div
                      initial={{ opacity: 0, marginBottom: 0 }}
                      animate={{
                        opacity: error ? 1 : 0,
                        marginBottom: error ? 20 : 0
                      }}>
                      <Alert type="error" showIcon message={getErrorMessage()}></Alert>
                    </motion.div>
                  </Col>
                }
                  <Form
                    name="basicInformation"
                    layout="vertical"
                    initialValues={{ size: componentSize }}
                    size={componentSize}
                  >
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Campaign Name"
                          name="Campaign-Name"
                          initialValue={name}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter campaign name!',
                            },
                          ]}
                        >
                          <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Item>
                        {/* {numbers?.length > 0 &&
                          <>
                            <Form.Item label="Mobile Numbers from Excel">
                              <Input.TextArea
                                rows={numbers?.length >= 10 ? 10 : 5}
                                type="text"
                                value={singleLineNumber}
                                className='cursor-not-allowed'
                              >
                              </Input.TextArea>
                              <p className='float-right'>
                                Total numbers: {
                                  numbers.length +
                                  number
                                    .split(',')
                                    .map(item => item.trim())
                                    .flatMap(item => item.split('\n').map(subItem => subItem.trim()))
                                    .filter(item => item !== '' && (item.length === 10 || item.length === 12)).length
                                }
                              </p>
                            </Form.Item>
                          </>
                        } */}
                        {/* <Form.Item
                          label="Message Type"
                          name="radio"
                          rules={[{ required: true, message: "Please select an option!" }]}
                        >
                          <Radio.Group onChange={(e) => onChange(e)}
                            value={requestType}

                          >
                            <Radio value='Custom'>Custom</Radio>
                            <Radio value='template'>Template</Radio>
                          </Radio.Group>
                        </Form.Item> */}
                        {requestType === 'Custom' ?
                          <Form.Item
                            label="Custom Text"
                            name='Custom-Text'
                            rules={[
                              {
                                required: true,
                                message: 'This field is required!',
                              },
                            ]}>
                            <Select className='mb-2' defaultValue="en" style={{ width: 120 }} onChange={(value) => setCustomLanguage(value)}>
                              <Option value="en">English</Option>
                              <Option value="gu">Gujarati</Option>
                              <Option value="hi">Hindi</Option>
                            </Select>
                            <ReactTransliterate
                              renderComponent={(props) =>
                                <textarea
                                  {...props}
                                  rows={4}
                                  className='p-2'
                                  style={styles.textarea}
                                />
                              }
                              value={customMessage}
                              onChangeText={(text) => {
                                setCustomMessage(text);
                              }}
                              lang={customLanguage}
                            />
                            {/* <Input.TextArea value={customMessage} type="text" rows={4} onChange={(e) => setCustomMessage(e.target.value)} /> */}

                          </Form.Item>
                          :
                          requestType === 'template' ?
                            <>
                              <Form.Item
                                label="Template Category"
                                name='Template_category'
                                initialValue={templateCategory}
                              >
                                <Radio.Group onChange={(e) => setTemplateCategory(e.target.value)}>
                                  <Radio value={'MARKETING'}>Marketing</Radio>
                                  <Radio value={'UTILITY'}>Utility</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                label="Templates"
                                name='Templates'
                              >
                                <Select
                                  size={'default'}
                                  // initialValues={{ key: "choose..." }}
                                  style={{ width: '100%' }}
                                  onChange={(value) => HandleTemplate(value)}
                                  showSearch
                                >
                                  <Option value="choose...">select</Option>
                                  {
                                    templateList?.length > 0 ?
                                      templateList?.map((item, index) =>
                                        <Option value={item.name} key={index}>{item.name}</Option>
                                      )
                                      :
                                      <Option value="">You Don't Have Any Approved Templates</Option>

                                  }
                                </Select>
                              </Form.Item>
                              {
                                template?.header && template?.header?.format !== 'TEXT' &&
                                <>

                                  {mediaType === 'Media' ?
                                    <Form.Item
                                      label="Select media"
                                      name='media-link'
                                    >
                                      {!mediaLink ?
                                        <Button type='' style={buttonStyle} onClick={showMediaModel}><CloudUploadOutlined />Choose File</Button>
                                        :
                                        <>
                                          <Input type="text" disabled value={mediaName} className='mb-3' />
                                          <Button type='' style={buttonStyle} onClick={showMediaModel}><CloudUploadOutlined />Choose Another File</Button>
                                          <p className='d-none'>{mediaName}</p>
                                        </>
                                      }
                                    </Form.Item>
                                    :
                                    <Form.Item
                                      label="Your media file link"
                                      name='media-link'
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please enter campaign name!',
                                        },
                                      ]}
                                    >
                                      <Input type="text" onChange={(e) => setMediaLink(e.target.value)} className='mb-3' />
                                    </Form.Item>
                                  }
                                </>
                              }
                              <div>
                                {templateInputs.length > 0 && (
                                  <Form.Item label={`${templateInputs.length} dynamic values detected`}>
                                    <Text code>
                                      Excel file Must have One Number and {templateInputs.length} variable values column
                                    </Text>
                                  </Form.Item>
                                )}
                              </div>
                            </>

                            : ''
                        }
                        {templateName &&
                          <Form.Item
                            label="Choose Excel File"
                            name=""
                            rules={[
                              {
                                required: true,
                                message: 'Please Choose File!',
                              },
                            ]}
                          >
                            <span className='d-flex mr-2'>
                              <Upload {...props}
                                fileList={fileList}
                                accept=".xlsx"
                              >
                                <Button className='mr-2' disabled={!disableUpload} icon={<UploadOutlined />}>Click to Select</Button>
                              </Upload>
                              <Button type="primary" onClick={HandleImport} disabled={disableUpload}>Upload</Button>
                            </span>
                          </Form.Item>
                        }
                        <div>
                          {templateUrlInputs.length > 0 && (
                            <Form.Item label={`${templateUrlInputs.length} Url Button detected`}>
                              {templateUrlInputs.map((input, index) => (
                                <div key={index} className='mb-2'>{input}</div>
                              ))}
                            </Form.Item>
                          )}
                          <Form.Item
                            label=" "
                            name=''
                          >
                            <Flex alignItems="center" gap={SPACER[2]}>
                              {Permisson?.includes('Create Campaign') &&
                                <Button type="primary" htmlType="submit" onClick={(e) => HandleApiTest(e)} disabled={!disableUpload} loading={loading}>Submit</Button>
                              }
                              {/* <Button type="primary" htmlType="submit" onClick={(e) => showScheduleModel(e)} loading={loading}>Schedule Campaign</Button> */}
                            </Flex>
                          </Form.Item>
                          {/* <Progress percent={progress} status="active" />
                       <p>{progress}</p> */}
                        </div>

                      </Col>
                    </Row>
                  </Form>
                </Col>

              </Card>
            </Col>
            {/* mobile preview  */}
            <Col xs={24} sm={24} md={24} lg={6}>
              <Card title="Campaign Preview">
                <Flex gap={5} alignItems='center' className='mb-3'>
                  <FontAwesomeIcon icon={faPhone} />
                  <span>: {importedData[0]?.number}</span>
                </Flex>

                <NewMobilePreview
                  requestType={requestType}
                  template={template}
                  mediaLink={mediaLink}
                  thumbnail={thumbnail}
                  newMesaage={newMesaage}
                  urlButtons={urlButtons}
                  customMessage={customMessage}
                />
              </Card>
            </Col>
          </>
        }
        {Permisson?.includes('View Group') &&
          <Col xs={24} sm={24} md={24} lg={6}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Card title={`Excel Campaign Guidelines`}>
                  <Row>
                    <Col span={24}>
                      <List
                        itemLayout="horizontal"
                        dataSource={listData}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={<RightOutlined style={{ color: '#00a5f4' }} />}
                              title={item}
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>

            </Row>
          </Col>
        }
      </Row >
    </>
  )
}

export default ExcelCampaign
